import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";
import { notifySuccess } from "./snackbarSlice";
import { formatNoResponseError } from "../storeUtils";

const initialOrder = {
  order_number: "",
  order_comment: "",
  product: "",
  quantity: 1,
  due_date: "1970-01-01T00:00:00",
  //status: "configuration", // 'configuration' | 'backlog' | 'on_hold' | 'started' | 'in_progress' | 'blocker' | 'finished' | 'archived'
};

const initialState = {
  orders: [],
  activeOrder: initialOrder,
  orderDialog: {
    isOpen: false,
    mode: null, //  null | 'add' | 'edit'
    activeTab: "general",
  },
  orderDetailDialog: {
    isOpen: false,
  },
  downloading: {
    status: "idle", //  'idle' | 'loading' | 'succeeded' | 'failed'
    error: { status: null, status_text: null, detail: null },
  },
  uploading: {
    status: "idle", //  'idle' | 'loading' | 'succeeded' | 'failed'
    error: { status: null, status_text: null, detail: null },
  },
  uploadingDnD: {
    status: "idle", //  'idle' | 'loading' | 'succeeded' | 'failed'
    index: { destination: 0, source: 0 },
    error: { status: null, status_text: null, detail: null },
  },
  archiving: {
    dialog: false,
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: { status: null, status_text: null, detail: null },
  },
  deleting: {
    dialog: false,
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: { status: null, status_text: null, detail: null },
  },
};

export const getAllOrders = createAsyncThunk(
  "orders/getOrders",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/orders/");
      return [...response.data];
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const createOrder = createAsyncThunk(
  "orders/createOrder",
  async (order, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/orders/create`, order);
      dispatch(notifySuccess());
      return response.data;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const updateOrder = createAsyncThunk(
  "orders/updateOrder",
  async (order, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/orders/${order.order_id}`,
        order
      );
      dispatch(notifySuccess());
      return response.data;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const updateOrderPriority = createAsyncThunk(
  "orders/updateOrdersPriority",
  async (dndResult, { dispatch, rejectWithValue }) => {
    try {
      await axiosInstance.post(`/orders/update-priority`, dndResult);
      dispatch(notifySuccess());
      return dndResult;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const deleteOrder = createAsyncThunk(
  "orders/deleteOrder",
  async (order, { rejectWithValue }) => {
    try {
      await axiosInstance.delete(`/orders/${order.order_id}`);
      return order.order_id;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    /* --- Order ------------------------------------------- */
    setActiveOrder: (state, action) => {
      state.activeOrder = state.orders.find(
        (order) => order.order_id === action.payload.order_id
      );
    },
    resetActiveOrder: (state) => {
      state.activeOrder = initialOrder;
    },

    /* --- Order dialog -------------------------------------*/
    startAddingOrder: (state) => {
      state.orderDialog.mode = "add";
      state.orderDialog.activeTab = "general";
      state.orderDialog.isOpen = true;
    },
    startEditingOrder: (state) => {
      state.orderDialog.mode = "edit";
      state.orderDialog.activeTab = "general";
      state.orderDialog.isOpen = true;
    },
    closeOrderDialog: (state) => {
      state.orderDialog.isOpen = false;
      state.orderDialog.activeTab = "general";
      state.orderDialog.mode = null;
      state.activeOrder = initialOrder;
    },
    setOrderActiveTab: (state, action) => {
      state.orderDialog.activeTab = action.payload;
    },
    /* --- OrderDetail dialog -------------------------------*/
    openOrderDetailDialog: (state) => {
      state.orderDetailDialog.isOpen = true;
    },
    closeOrderDetailDialog: (state) => {
      state.orderDetailDialog.isOpen = false;
      state.activeOrder = initialOrder;
    },
    /* --- Information dialogs ---------------------------- */
    startArchivingOrder: (state) => {
      state.archiving.status = "idle";
      state.archiving.dialog = true;
    },
    startDeletingOrder: (state) => {
      state.deleting.status = "idle";
      state.deleting.dialog = true;
    },
    closeArchivingDialog: (state) => {
      state.archiving.dialog = false;
    },
    closeDeletingDialog: (state) => {
      state.deleting.dialog = false;
    },
    closeLoadingErrorDialog: (state) => {
      state.uploading.status = "idle";
    },
  },
  extraReducers(builder) {
    builder
      /* --- READ ------------------------------------------- */
      .addCase(getAllOrders.pending, (state) => {
        state.downloading.status = "loading";
      })
      .addCase(getAllOrders.fulfilled, (state, action) => {
        state.downloading.status = "succeeded";
        state.orders = action.payload;
      })
      .addCase(getAllOrders.rejected, (state, action) => {
        state.downloading.status = "failed";
        state.downloading.error.status = action.payload.response.status;
        state.downloading.error.status_text =
          action.payload.response.statusText;
        state.downloading.error.detail =
          action.payload.response.data.detail || action.payload.response.data;
      })
      /* --- CREATE ----------------------------------------- */
      .addCase(createOrder.pending, (state) => {
        state.uploading.status = "loading";
      })
      .addCase(createOrder.fulfilled, (state, action) => {
        state.activeOrder = action.payload;
        state.orders.push(action.payload);
        state.uploading.status = "succeeded";
        state.orderDialog.mode = "edit";
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.uploading.status = "failed";
        state.uploading.error.status = action.payload.response.status;
        state.uploading.error.status_text = action.payload.response.statusText;
        state.uploading.error.detail =
          action.payload.response.data.detail || action.payload.response.data;
      })
      /* --- UPDATE ----------------------------------------- */
      .addCase(updateOrder.pending, (state) => {
        state.uploading.status = "loading";
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        state.activeOrder = action.payload;
        const order_index = state.orders.findIndex(
          (order) => order.order_id === action.payload.order_id
        );
        if (action.payload.status === "archived") {
          state.orders.splice(order_index, 1);
        } else {
          state.orders[order_index] = action.payload;
        }
        state.uploading.status = "succeeded";
      })
      .addCase(updateOrder.rejected, (state, action) => {
        state.uploading.status = "failed";
        state.uploading.error.status = action.payload.response.status;
        state.uploading.error.status_text = action.payload.response.statusText;
        state.uploading.error.detail =
          action.payload.response.data.detail || action.payload.response.data;
      })
      /* --- UPDATE PRIORITY -------------------------------- */
      .addCase(updateOrderPriority.pending, (state, { meta }) => {
        state.uploadingDnD.index.destination = meta.arg.destination.index;
        state.uploadingDnD.index.source = meta.arg.source.index;
        state.uploadingDnD.status = "loading";
      })
      .addCase(updateOrderPriority.fulfilled, (state, action) => {
        const result = action.payload;
        const backlog = state.orders;
        const noOfFiltered = state.orders.filter(
          (order) => order.status === "finished"
        ).length; // add to splice pisition (backlog has 'finished' orders also)
        const [reorderedItem] = backlog.splice(
          result.source.index + noOfFiltered,
          1
        );
        backlog.splice(
          result.destination.index + noOfFiltered,
          0,
          reorderedItem
        );
        state.uploadingDnD.status = "succeeded";
      })
      .addCase(updateOrderPriority.rejected, (state, action) => {
        state.uploadingDnD.status = "failed";
        state.uploadingDnD.error.status = action.payload.response.status;
        state.uploadingDnD.error.status_text =
          action.payload.response.statusText;
        state.uploadingDnD.error.detail =
          action.payload.response.data.detail || action.payload.response.data;
      })
      /* --- DELETE ----------------------------------------- */
      .addCase(deleteOrder.pending, (state) => {
        state.deleting.status = "loading";
      })
      .addCase(deleteOrder.fulfilled, (state, action) => {
        const order_index = state.orders.findIndex(
          (order) => order.order_id === action.payload
        );
        state.orders.splice(order_index, 1);
        state.activeOrder = initialOrder;
        state.deleting.status = "succeeded";
      })
      .addCase(deleteOrder.rejected, (state, action) => {
        state.deleting.status = "failed";
        state.deleting.error.status = action.payload.response.status;
        state.deleting.error.status_text = action.payload.response.statusText;
        state.deleting.error.detail =
          action.payload.response.data.detail || action.payload.response.data;
      });
  },
});

/* --- Orders ------------------------------------------------------------------- */
export const getActiveOrder = (state) => state.orders.activeOrder;
export const selectAllOrders = (state) => state.orders.orders;

/* --- Order Dialog ------------------------------------------------------------- */
export const getOrderDialogState = (state) => state.orders.orderDialog;

/* --- OrderDetail Dialog ------------------------------------------------------- */
export const getOrderDetailDialogState = (state) =>
  state.orders.orderDetailDialog;

/* --- Order Functions ---------------------------------------------------------- */
export const getOrdersDownloadState = (state) => state.orders.downloading;
export const getOrderUploadState = (state) => state.orders.uploading;
export const getOrderDNDState = (state) => state.orders.uploadingDnD;
export const getOrderArchiveState = (state) => state.orders.archiving;
export const getOrderDeleteState = (state) => state.orders.deleting;

/* --- REDUCERS --------------------------------------- */
export const {
  setActiveOrder,
  resetActiveOrder,
  startAddingOrder,
  startEditingOrder,
  closeOrderDialog,
  setOrderActiveTab,
  openOrderDetailDialog,
  closeOrderDetailDialog,
  startArchivingOrder,
  startDeletingOrder,
  closeArchivingDialog,
  closeDeletingDialog,
  closeLoadingErrorDialog,
} = ordersSlice.actions;

export default ordersSlice.reducer;
