import { useSelector } from 'react-redux';
import { getTimezone } from '../../../store/slices/loginSlice';
import { Divider, useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import { useTranslation } from 'react-i18next';
import TooltipContainer from '../TooltipContainer';
import TooltipElement from '../TooltipElement';
import moment from 'moment-timezone';

function TimelineTooltip(props) {

    const { data } = props

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { t } = useTranslation();

    const timezone = useSelector(getTimezone);

    const formatDuration = (duration) => {
        return Math.floor(moment.duration(duration, "seconds").asHours())
            + moment.utc(moment.duration(duration, "seconds").asMilliseconds()).format(":mm:ss")
    };


    const getLabel = (action) => {

        if (action === "stop") {
            return t("charts.legend.stop");
        } else if (action === "process") {
            return t("charts.legend.process");
        } else if (action === "setup") {
            return t("charts.legend.setup");
        } else if (action === "scheduled") {
            return t("charts.legend.scheduled");
        } else if (action === "blocker") {
            return t("charts.legend.blocker");
        } else {
            return "";
        }
    };


    return (
        <TooltipContainer
            label={getLabel(data.action)}
        >
            <Divider color={colors.primary[400]} sx={{ m: '4px 0px 10px 0px' }} />
            <TooltipElement
                label={t("charts.legend.start")}
                value={moment.utc(data.start).tz(timezone).format('DD-MM, HH:mm:ss')}
                unit={""}
                color={colors.grey[100]}
            />
            <TooltipElement
                label={t("charts.legend.end")}
                value={moment.utc(data.end).tz(timezone).format('DD-MM, HH:mm:ss')}
                unit={""}
                color={colors.grey[100]}
            />
            <Divider color={colors.primary[400]} sx={{ my: '10px' }} />
            <TooltipElement
                label={t("charts.legend.duration")}
                value={formatDuration(data.duration)}
                unit={t("charts.unit.hours")}
                color={colors.grey[100]}
            />
        </TooltipContainer>
    );
};

export default TimelineTooltip;