import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";
import { formatNoResponseError } from "../storeUtils";

const initialState = {
  actionTimestamp: {
    uploading: {
      status: "idle", //  'idle' | 'loading' | 'succeeded' | 'failed'
      error: { status: null, status_text: null, detail: null },
    },
  },
};

export const createActionTimestamp = createAsyncThunk(
  "timestamps/createTimestamp",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/devices/action-timestamp/${data.place_id}`,
        data
      );
      return response.data;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const timestampsSlice = createSlice({
  name: "timestamps",
  initialState,
  reducers: {
    /* --- ACTION TIMESTAMP ------------------------------------ */
    closeTimestampLoadingErrorDialog: (state) => {
      state.actionTimestamp.uploading.status = "idle";
    },
  },
  extraReducers(builder) {
    builder
      /* --- ACTION TIMESTAMP ---------------------------------- */
      .addCase(createActionTimestamp.pending, (state) => {
        state.actionTimestamp.uploading.status = "succeeded";
      })
      .addCase(createActionTimestamp.fulfilled, (state) => {
        state.actionTimestamp.uploading.status = "succeeded";
      })
      .addCase(createActionTimestamp.rejected, (state, action) => {
        const d = state.actionTimestamp.uploading;
        const r = action.payload.response;
        d.status = "failed";
        d.error.status = r.status;
        d.error.status_text = r.statusText;
        d.error.detail = r.data.detail || r.data;
      });
  },
});

/* --- action timestamp ------------------------------------------------------- */
export const getActionTimestampUploadState = (state) =>
  state.timestamps.actionTimestamp.uploading;

/* --- REDUCERS --------------------------------------- */
export const { closeTimestampLoadingErrorDialog } = timestampsSlice.actions;

export default timestampsSlice.reducer;
