import { useSelector, useDispatch } from 'react-redux';
import {
    deleteTechnology,
    startAddingTechnology,
    closeTechnologyDialog,
    setTechnologyActiveTab,
    closeDeletingDialog,
    getActiveTechnology,
    getTechnologyDialogState,
    getTechnologyDeleteState,
} from '../../store/slices/technologiesSlice';
import { useTranslation } from 'react-i18next';
import settings from '../../config.json';
import { tokens } from '../../theme';
import { Box, Dialog, Tab, useTheme } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import useMediaQuery from '../../hooks/useMediaQuery';
import usePermission from '../../hooks/usePermission';
import TableHeader from '../../components/table/TableHeader';
import GridTechnologies from './GridTechnologies';
import FormTechnology from './FormTechnology';
import TechnologyTab from './TechnologyTab';
import ConfirmActionDialog from '../../components/dialogs/ConfirmActionDialog';
import DragablePaperComponent from '../../components/dialogs/DragablePaperComponent';
import AddToListButton from '../../components/buttons/AddToListButton';
import TabsDialogContainer from '../../components/dialogs/TabsDialogContainer';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import TimelineIcon from '@mui/icons-material/Timeline';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import AddNextButtonTabHeader from '../../components/buttons/AddNextButtonTabHeader';
import SnackbarBasic from '../../components/notifications/SnackbarBasic';


function PageTechnologies() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const isAboveMobileSize = useMediaQuery(settings.NON_MOBILE_MEDIA_QUERY);
    const writeAccess = usePermission(['technology_write'])

    const activeTechnology = useSelector(getActiveTechnology);
    const technologyDialogState = useSelector(getTechnologyDialogState);
    const deleteState = useSelector(getTechnologyDeleteState);


    const changeTab = (e, clickedTab) => {
        dispatch(setTechnologyActiveTab(clickedTab));
    };

    const handleAddNewClick = () => {
        dispatch(startAddingTechnology());
    };

    const handleCloseTechnologyDialog = () => {
        dispatch(closeTechnologyDialog());
    };

    const handleDelete = () => {
        dispatch(deleteTechnology(activeTechnology));
    };

    const handleCloseDeletingDialog = () => {
        dispatch(closeDeletingDialog());
    };


    return (
        <>

            {/* PAGE */}
            <Box display='flex' flexDirection='column'>
                <TableHeader
                    title={t('page.technology')}
                    icon={<SummarizeOutlinedIcon sx={{ color: colors.assigned.tableHeader, fontSize: '32px' }} />}
                >
                    <AddToListButton
                        text={t('button.add_new_technology')}
                        onClick={handleAddNewClick}
                        permission={writeAccess}
                    />
                </TableHeader>
                <GridTechnologies />
            </Box>
            <SnackbarBasic />

            {/* DIALOG */}
            <Dialog
                open={technologyDialogState.isOpen}
                PaperComponent={DragablePaperComponent}
                PaperProps={{
                    sx: {
                        backgroundColor: colors.primary[500],
                        minWidth: { sm: settings.MIN_WIDTH_OF_SM_WIDTH_TAB, md: '700px' },
                        width: '95vw', // works only for mobile when minWidth is defined
                        height: isAboveMobileSize ? '527px' : '527px', // set height order: --- 2/3 ---
                        m: "0px",
                        overflow: 'hidden'
                    }
                }}
            >
                <Box
                    display='flex'
                    flexDirection='column'
                    mx={isAboveMobileSize ? '20px' : '5px'}
                >
                    <TabContext value={technologyDialogState.activeTab} >
                        <TabsDialogContainer
                            onClose={handleCloseTechnologyDialog}
                        >
                            <Box
                                display='flex'
                                width='100%'
                                justifyContent='space-between'
                                alignItems='center'
                            >
                                <TabList
                                    onChange={changeTab}
                                    textColor='secondary'
                                    indicatorColor='secondary'
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    sx={{ 
                                        minWidth: { sm: '380px', md: '500px' },
                                        width: '275px' // works only for mobile when minWidth is defined
                                    }}
                                >
                                    <Tab
                                        label={isAboveMobileSize ? t('tab.general') : null}
                                        icon={<DescriptionOutlinedIcon />}
                                        iconPosition="start"
                                        value="general"
                                        wrapped
                                    />
                                    <Tab
                                        label={isAboveMobileSize ? t('tab.technology') : null}
                                        icon={<TimelineIcon />}
                                        iconPosition="start"
                                        value="technology"
                                        wrapped
                                        disabled={technologyDialogState.mode === 'add'}
                                    />
                                    <Tab
                                        label={isAboveMobileSize ? t('tab.attachments') : null}
                                        icon={<AttachFileIcon />}
                                        iconPosition="start"
                                        value="attachments"
                                        wrapped
                                        disabled={true}//{technologyDialogState.mode === 'add'}
                                    />
                                </TabList>
                                {isAboveMobileSize ?
                                    <AddNextButtonTabHeader
                                        text={'Add Next'}
                                        onClick={handleAddNewClick}
                                    />
                                    :
                                    null
                                }
                            </Box>
                        </TabsDialogContainer>
                        <Box
                            height={isAboveMobileSize ? '460px' : '460px'} // set height order: --- 1/3 ---
                            position='relative' // absolute position of the button group in tabs
                            sx={{
                                '& .MuiTabPanel-root': {
                                    px: '5px',
                                },
                            }}
                        >
                            <TabPanel value="general">
                                <FormTechnology />
                            </TabPanel>
                            <TabPanel value="attachments">
                                {/* <AttachmentsTab /> */}
                            </TabPanel>
                            <TabPanel value="technology">
                                <TechnologyTab />
                            </TabPanel>
                        </Box>
                    </TabContext>
                </Box>
            </Dialog>

            {/* DELETE DIALOG */}
            <ConfirmActionDialog
                open={deleteState.dialog}
                onConfirm={handleDelete}
                onCancel={handleCloseDeletingDialog}
                confirmButtonLabel={t('button.delete')}
                message={t('notification.confirm_delete')}
                icon={<DeleteForeverOutlinedIcon sx={{ color: colors.redAccent[500], fontSize: '140px' }} />}
                status={deleteState.status}
                error={deleteState.error}
            />
        </>
    );
};

export default PageTechnologies;