import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { default as Auth } from "./components/auth/Authenticated";
import { default as Pub } from "./components/auth/PublicRoute";
import Homepage from "./pages/homepage/Homepage";
import PageArchive from "./pages/archive/PageArchive";
import PageDashboard from "./pages/dashboards/PageDashboard";
import PageImpersonate from "./pages/impersonate/PageImpersonate";
import PageLogin from "./pages/login/PageLogin";
import PageMaterials from "./pages/materials/PageMaterials";
import PageOrders from "./pages/orders/PageOrders";
import PagePlaces from "./pages/places/PagePlaces";
import PagePlaceAnalytics from "./pages/places/PagePlaceAnalytics";
import PageProducts from "./pages/products/PageProducts";
import PageProductAnalytics from "./pages/products/PageProductAnalytics";
import PageRegister from "./pages/register/PageRegister";
import PageSettings from "./pages/settings/PageSettings";
import PageTechnologies from "./pages/technologies/PageTechnologies";
import PageUsers from "./pages/users/PageUsers";
import SideNav from "./components/bars/SideNav";
import Navbar from "./components/bars/Navbar";
import Page404 from "./pages/static/Page404";
import CircularProgressBasic from "./components/progress/CircularProgressBasic";


function App() {
  const [theme, colorMode] = useMode();

  return (
    <Suspense fallback={<CircularProgressBasic />}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">
            <main className="content">
              <Routes>
                <Route element={<SideNav />}>
                  <Route element={<Navbar />}>
                    <Route path="/archive" exact element={<Auth><PageArchive /></Auth>} />
                    <Route path="/dashboard" exact element={<Auth><PageDashboard /></Auth>} />
                    <Route path="/impersonate" exact element={<Auth><PageImpersonate /></Auth>} />
                    <Route path="/materials" exact element={<Auth><PageMaterials /></Auth>} />
                    <Route path="/orders" exact element={<Auth><PageOrders /></Auth>} />
                    <Route path="/places" exact element={<Auth><PagePlaces /></Auth>} />
                    <Route path="/places/:id/analytics" exact element={<Auth><PagePlaceAnalytics /></Auth>} />
                    <Route path="/products" exact element={<Auth><PageProducts /></Auth>} />
                    <Route path="/products/:id/analytics" exact element={<Auth><PageProductAnalytics /></Auth>} />
                    <Route path="/settings" exact element={<Auth><PageSettings /></Auth>} />
                    <Route path="/technology" exact element={<Auth><PageTechnologies /></Auth>} />
                    <Route path="/users" exact element={<Auth><PageUsers /></Auth>} />
                  </Route>
                </Route>
                <Route path="/" exact element={<Homepage />} />
                <Route path="/login" exact element={<Pub><PageLogin /></Pub>} />
                <Route path="/register" exact element={<Pub><PageRegister /></Pub>} />
                <Route path="/404" exact element={<Page404 />} />
                <Route path="*" exact element={<Navigate to="/404" replace />} />
              </Routes>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </Suspense>
  );
}

export default App;
