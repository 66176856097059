import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";
import { notifySuccess } from "./snackbarSlice";
import { formatNoResponseError } from "../storeUtils";

const initialProduct = {
  product_name: "",
  product_number: "",
  drawing_number: "",
  description: "",
  technology: "",
};

const initialState = {
  products: [],
  product: null,
  activeProduct: initialProduct,
  productDialog: {
    isOpen: false,
    mode: null, //  null | 'add' | 'edit'
    activeTab: "general",
  },
  downloading: {
    status: "idle", //  'idle' | 'loading' | 'succeeded' | 'failed'
    error: { status: null, status_text: null, detail: null },
  },
  uploading: {
    status: "idle", //  'idle' | 'loading' | 'succeeded' | 'failed'
    error: { status: null, status_text: null, detail: null },
  },
  deleting: {
    dialog: false,
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: { status: null, status_text: null, detail: null },
  },
};

export const fetchAllProducts = createAsyncThunk(
  "products/fetchAllProducts",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/products/");
      return [...response.data];
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const fetchProduct = createAsyncThunk(
  "products/fetchProduct",
  async (product_id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/products/${product_id}`);
      return response.data;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const createProduct = createAsyncThunk(
  "products/createProduct",
  async (product, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/products/create`, product);
      dispatch(notifySuccess());
      return response.data;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const updateProduct = createAsyncThunk(
  "products/updateProduct",
  async (product, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/products/${product.product_id}`,
        product
      );
      dispatch(notifySuccess());
      return response.data;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "products/deleteProduct",
  async (product, { rejectWithValue }) => {
    try {
      await axiosInstance.delete(`/products/${product.product_id}`);
      return product.product_id;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    /* --- Product ------------------------------------------- */
    setActiveProduct: (state, action) => {
      state.activeProduct = state.products.find(
        (product) => product.product_id === action.payload.product_id
      );
      state.product = state.products.find(
        (product) => product.product_id === action.payload.product_id
      );
    },
    resetActiveProduct: (state) => {
      state.activeProduct = initialProduct;
    },
    /* --- Product dialog -------------------------------------*/
    startAddingProduct: (state) => {
      state.productDialog.mode = "add";
      state.productDialog.activeTab = "general";
      state.productDialog.isOpen = true;
    },
    startEditingProduct: (state) => {
      state.productDialog.mode = "edit";
      state.productDialog.activeTab = "general";
      state.productDialog.isOpen = true;
    },
    closeProductDialog: (state) => {
      state.productDialog.isOpen = false;
      state.productDialog.activeTab = "general";
      state.productDialog.mode = null;
      state.activeProduct = initialProduct;
    },
    setProductActiveTab: (state, action) => {
      state.productDialog.activeTab = action.payload;
    },
    /* --- Information dialogs ---------------------------- */
    startDeletingProduct: (state) => {
      state.deleting.status = "idle";
      state.deleting.dialog = true;
    },
    closeDeletingDialog: (state) => {
      state.deleting.dialog = false;
    },
    closeLoadingErrorDialog: (state) => {
      state.uploading.status = "idle";
    },
  },
  extraReducers(builder) {
    builder
      /* --- FETCH ALL -------------------------------------- */
      .addCase(fetchAllProducts.pending, (state) => {
        state.downloading.status = "loading";
      })
      .addCase(fetchAllProducts.fulfilled, (state, action) => {
        state.downloading.status = "succeeded";
        state.products = action.payload;
      })
      .addCase(fetchAllProducts.rejected, (state, action) => {
        state.downloading.status = "failed";
        state.downloading.error.status = action.payload.response.status;
        state.downloading.error.status_text =
          action.payload.response.statusText;
        state.downloading.error.detail =
          action.payload.response.data.detail || action.payload.response.data;
      })
      /* --- FETCH SINGLE ----------------------------------- */
      .addCase(fetchProduct.pending, (state) => {
        state.downloading.status = "loading";
      })
      .addCase(fetchProduct.fulfilled, (state, action) => {
        state.downloading.status = "succeeded";
        state.product = action.payload;
      })
      .addCase(fetchProduct.rejected, (state, action) => {
        state.downloading.status = "failed";
        state.downloading.error.status = action.payload.response.status;
        state.downloading.error.status_text =
          action.payload.response.statusText;
        state.downloading.error.detail =
          action.payload.response.data.detail || action.payload.response.data;
      })
      /* --- CREATE ----------------------------------------- */
      .addCase(createProduct.pending, (state) => {
        state.uploading.status = "loading";
      })
      .addCase(createProduct.fulfilled, (state, action) => {
        state.activeProduct = action.payload;
        state.products.push(action.payload);
        state.uploading.status = "succeeded";
        state.productDialog.mode = "edit";
      })
      .addCase(createProduct.rejected, (state, action) => {
        state.uploading.status = "failed";
        state.uploading.error.status = action.payload.response.status;
        state.uploading.error.status_text = action.payload.response.statusText;
        state.uploading.error.detail =
          action.payload.response.data.detail || action.payload.response.data;
      })
      /* --- UPDATE ----------------------------------------- */
      .addCase(updateProduct.pending, (state) => {
        state.uploading.status = "loading";
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.activeProduct = action.payload;
        const product_index = state.products.findIndex(
          (product) => product.product_id === action.payload.product_id
        );
        state.products[product_index] = action.payload;
        state.uploading.status = "succeeded";
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.uploading.status = "failed";
        state.uploading.error.status = action.payload.response.status;
        state.uploading.error.status_text = action.payload.response.statusText;
        state.uploading.error.detail =
          action.payload.response.data.detail || action.payload.response.data;
      })
      /* --- DELETE ----------------------------------------- */
      .addCase(deleteProduct.pending, (state) => {
        state.deleting.status = "loading";
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        const product_index = state.products.findIndex(
          (product) => product.product_id === action.payload
        );
        state.products.splice(product_index, 1);
        state.activeProduct = initialProduct;
        state.deleting.status = "succeeded";
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.deleting.status = "failed";
        state.deleting.error.status = action.payload.response.status;
        state.deleting.error.status_text = action.payload.response.statusText;
        state.deleting.error.detail =
          action.payload.response.data.detail || action.payload.response.data;
      });
  },
});

/* --- Products ------------------------------------------------------------------- */
export const getActiveProduct = (state) => state.products.activeProduct;
export const getProduct = (state) => state.products.product;
export const selectAllProducts = (state) => state.products.products;

/* --- Product Dialog ------------------------------------------------------------- */
export const getProductDialogState = (state) => state.products.productDialog;

/* --- Product Functions ---------------------------------------------------------- */
export const getProductsDownloadState = (state) => state.products.downloading;
export const getProductUploadState = (state) => state.products.uploading;
export const getProductDeleteState = (state) => state.products.deleting;

/* --- REDUCERS --------------------------------------- */
export const {
  setActiveProduct,
  resetActiveProduct,
  startAddingProduct,
  startEditingProduct,
  closeProductDialog,
  setProductActiveTab,
  startDeletingProduct,
  closeDeletingDialog,
  closeLoadingErrorDialog,
} = productsSlice.actions;

export default productsSlice.reducer;
