import { useSelector } from 'react-redux';
import { getLoggedUser } from '../../../store/slices/loginSlice';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Button } from '@mui/material';


function TileUser() {

    const { t } = useTranslation();

    const loggedUser = useSelector(getLoggedUser);

    return (
        <Box
            width='100%'
            height='100%'
            display='grid'
            gridTemplateColumns='3fr 1fr 100px '
            gap='10px'
            alignItems='center'
        >
            <Box
                textAlign='right'
            >
                {loggedUser.first_name + " " + loggedUser.last_name}
            </Box>
            <Box
                display='flex'
                justifyContent='center'
            >
                <Avatar />
            </Box>
            <Button
                variant='outlined'
                color="info"
                sx={{
                    height: '100%',
                    width: '100%'
                }}
            >
                {t('button.logout')}
            </Button>

        </Box>
    );
};

export default TileUser;