import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { tokens } from '../../theme';


function TileDB(props) {

    const { title, value, icon, loading } = props;

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const color1 = colors.primary[400];
    const color2 = colors.primary[400];

    return (
        <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            //backgroundColor={colors.primary[400]}
            gap='10px'
            pb='10px'
            sx={{ background: `linear-gradient(to right, ${color1}70, ${color2}10)` }}
        >
            <Typography
                variant='subtitle1'
                align='center'
            >
                {title}
            </Typography>
            <Box
                width='100%'
                display='flex'
                px='20px'
            >
                <Box
                    width='50%'
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                >
                    {icon}
                </Box>
                <Box
                    width='50%'
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                >
                    {loading ? (
                        <CircularProgress color='secondary' />
                    ) : (
                        <Typography
                            variant='h1'
                            color='secondary'
                        >
                            {value}
                        </Typography>
                    )}
                </Box>
            </Box>

        </Box>
    );
};

export default TileDB;