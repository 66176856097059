import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';


function ProcessChip() {

    const { t } = useTranslation();

    return (
        <Chip
            icon={<PlayArrowOutlinedIcon />}
            label={t('chip.process')}
            variant="outlined"
            color="success"
            size="medium"
            sx={{ width: '100%' }}
        />
    );
};

export default ProcessChip;