export const stepIndexForPlace = (place, order) => {
  const stepsArray = order.steps;
  const indexes = [];
  stepsArray.forEach((step, index) => {
    if (step.place.place_id === place.place_id && step.status === "backlog") {
      indexes.push(index);
    }
  });
  return indexes.length ? indexes[0] + 1 : 1;
};

export const noOfFinishedSteps = (order) => {
  const stepsArray = order.steps;
  const finishedSteps = stepsArray.filter((step) => step.status === "finished");
  return finishedSteps.length;
};

export const progressPercentage = (order) => {
  const stepsTotal = order.steps.length;
  const finishedSteps = noOfFinishedSteps(order);
  if (stepsTotal === 0) {
    return null;
  } else {
    return Math.round((finishedSteps / stepsTotal) * 100);
  }
};

export const isReadyForStep = (place, order) => {
  if (stepIndexForPlace(place, order) - 1 === noOfFinishedSteps(order)) {
    return true;
  } else {
    return false;
  }
};

export const isOrderInProgressHere = (place, order) => {
  const orderID = order.order_id || "x";
  const placeOrderID = place.order?.order_id || "y";
  if (orderID === placeOrderID) {
    return true;
  } else {
    return false;
  }
};
