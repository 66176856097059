import { useDispatch, useSelector } from 'react-redux';
import { setActiveView, getActiveView } from '../../../store/slices/impersonateSlice';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import ButtonNavigation from '../buttons/ButtonNavigation';
import FormatListNumberedRoundedIcon from '@mui/icons-material/FormatListNumberedRounded';
import ContentPasteRoundedIcon from '@mui/icons-material/ContentPasteRounded';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';


function TileNavigation() {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const actieView = useSelector(getActiveView)

    const handleSetView = (view) => {
        dispatch(setActiveView(view))
    }

    const buttons = [
        {
            label: t('button.device.backlog'),
            view: 'backlog',
            variant: 'outlined',
            icon: <FormatListNumberedRoundedIcon sx={{ fontSize: '40px' }} />,
        },
        {
            label: t('button.device.order'),
            view: 'order',
            variant: 'outlined',
            icon: <ContentPasteRoundedIcon sx={{ fontSize: '40px' }} />,
        },
        {
            label: t('button.device.place'),
            view: 'place',
            variant: 'outlined',
            icon: <PrecisionManufacturingOutlinedIcon sx={{ fontSize: '40px' }} />,
        },
        {
            label: t('button.device.team'),
            view: 'team',
            variant: 'outlined',
            icon: <GroupsOutlinedIcon sx={{ fontSize: '40px' }} />,
        }
    ];


    return (
        <Box
            width='100%'
            height='100%'
            display='grid'
            gridTemplateRows='1fr 1fr 1fr 1fr'
            gap='10px'
        >
            {buttons.map((button, id) =>
                <Box key={id} >
                    <ButtonNavigation
                        label={button.label}
                        view={button.view}
                        variant={button.variant}
                        icon={button.icon}
                        activeView={actieView}
                        setActiveView={handleSetView}
                    />
                </Box>
            )}
        </Box>
    );
};

export default TileNavigation;