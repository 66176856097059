import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    updateOrder,
    setActiveOrder,
    closeLoadingErrorDialog,
    getActiveOrder,
    getOrderUploadState,
} from '../../store/slices/ordersSlice';
import { useTranslation } from 'react-i18next';
import usePermission from '../../hooks/usePermission';
import { Box, Button, Menu, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { tokens } from '../../theme';
import ErrorLoadingDataDialog from '../../components/dialogs/ErrorLoadingDataDialog';


function MenuStatus(props) {

    const { order } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const writeAccess = usePermission(['orders_write']);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [anchor, setAnchor] = useState(null);
    const open = Boolean(anchor);

    const activeOrder = useSelector(getActiveOrder);
    const uploadState = useSelector(getOrderUploadState);


    const buttons = [
        {
            label: t('status.configuration'),
            status: 'configuration',
            active_in: ['backlog'],
            variant: 'outlined',
            color: 'warning',
        },
        {
            label: t('status.backlog'),
            status: 'backlog',
            active_in: ['configuration'],
            variant: 'outlined',
            color: 'info',
        },
        {
            label: t('status.started'),
            status: 'started',
            active_in: ['on_hold'],
            variant: 'outlined',
            color: 'success',
        },
        {
            label: t('status.in_progress'),
            status: 'in_progress',
            active_in: [],
            variant: 'contained',
            color: 'success',
        },
        {
            label: t('status.on_hold'),
            status: 'on_hold',
            active_in: ['started'],
            variant: 'contained',
            color: 'warning',
        },
        {
            label: t('status.blocker'),
            status: 'blocker',
            active_in: [],
            variant: 'contained',
            color: 'error',
        },
        {
            label: t('status.finished'),
            status: 'finished',
            active_in: [],
            variant: 'outlined',
            color: 'info',
        }
    ];


    const handleStatusClick = (e) => {
        dispatch(setActiveOrder(order));
        setAnchor(e.currentTarget);
    };

    const handleNewStatusClick = (newStatus) => {

        const data = {
            order_id: order.order_id,
            status: newStatus
        };

        dispatch(updateOrder(data));
        setAnchor(null);
    };


    return (
        <>
            {buttons.filter(button => button.status === order?.status).map((button, id) =>
                <LoadingButton
                    fullWidth
                    onClick={handleStatusClick}
                    key={id}
                    variant={button.variant}
                    color={button.color}
                    loading={uploadState.status === 'loading' && order.order_id === activeOrder.order_id}
                    disabled={uploadState.status === 'loading'}
                >
                    {button.label}
                </LoadingButton>
            )}

            {writeAccess ? (
                order.status !== 'in_progress' || 'blocker' || 'finished' ? (
                    <Menu
                        anchorEl={anchor}
                        open={open}
                        onClose={() => setAnchor(null)}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'right',
                        }}
                        sx={{
                            '& .MuiList-root': {
                                backgroundColor: colors.primary[500],
                                padding: '0px'
                            },
                        }}
                    >
                        <Box display='flex' flexDirection='row-reverse' gap='10px' p='10px'>
                            {buttons.filter(button =>
                                button.active_in.includes(order?.status)
                            ).map((button, id) =>
                                <Button
                                    key={id}
                                    variant={button.variant}
                                    color={button.color}
                                    onClick={() => handleNewStatusClick(button.status)}
                                >
                                    {button.label}
                                </Button>
                            )}
                        </Box>
                    </Menu>
                ) : (null)
            ) : (null)}

            <ErrorLoadingDataDialog
                open={uploadState.status === 'failed'}
                error={uploadState.error}
                onClose={() => dispatch(closeLoadingErrorDialog())}
            />
        </>
    );
};

export default MenuStatus;