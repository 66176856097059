import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";
import { formatNoResponseError } from "../storeUtils";

const initialState = {
  loading: {
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: { status: null, detail: null },
  },
};

export const registerAccount = createAsyncThunk(
  "register/registerAccount",
  async (account, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`/register`, account);
      return response.data;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    closeRegisterSuccessDialog: (state) => {
      state.loading.status = "idle";
    },
    setRegisterErrorNotification: (state, action) => {
      state.loading.status = "failed";
      state.loading.error.detail = action.payload;
    },
    closeRegisterErrorNotification: (state) => {
      state.loading.status = "idle";
      state.loading.error.status = null;
      state.loading.error.detail = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(registerAccount.pending, (state) => {
        state.loading.status = "loading";
      })
      .addCase(registerAccount.fulfilled, (state) => {
        state.loading.status = "succeeded";
      })
      .addCase(registerAccount.rejected, (state, action) => {
        state.loading.status = "failed";
        state.loading.error.status = action.payload.response.status;
        state.loading.error.detail = action.payload.response.data.detail;
      });
  },
});

export const getRegisterLoadingState = (state) => state.register.loading;

export const {
  closeRegisterSuccessDialog,
  setRegisterErrorNotification,
  closeRegisterErrorNotification,
} = registerSlice.actions;

export default registerSlice.reducer;
