import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'
import settings from '../../config.json';
import { Box, Button, Grid, Typography } from '@mui/material';
import useMediaQuery from '../../hooks/useMediaQuery';
import logo from '../../assets/logos/midnightcode_logo.svg';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LanguageSwitch from '../../components/switches/LanguageSwitch';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import SchemaOutlinedIcon from '@mui/icons-material/SchemaOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import TimelineIcon from '@mui/icons-material/Timeline';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
import chart_screenshot from '../../assets/screenshots/chart.png';
import dashboard_screenshot from '../../assets/screenshots/dashboard.png';
import device_screenshot from '../../assets/screenshots/device.png';
import orders_screenshot from '../../assets/screenshots/orders.png';
import places_screenshot from '../../assets/screenshots/places.png';
import timeline_screenshot from '../../assets/screenshots/timeline.png';


function Homepage() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const isAboveMobileSize = useMediaQuery(settings.NON_MOBILE_MEDIA_QUERY);


    const iconStyle = { fontSize: '24px', color: '#DBF288' };

    const purposes = [
        {
            label: t('homepage.purpose_1'),
            icon: <DisplaySettingsIcon sx={iconStyle} />
        },
        {
            label: t('homepage.purpose_2'),
            icon: <TodayOutlinedIcon sx={iconStyle} />
        },
        {
            label: t('homepage.purpose_3'),
            icon: <TaskOutlinedIcon sx={iconStyle} />
        },
        {
            label: t('homepage.purpose_4'),
            icon: <WarehouseOutlinedIcon sx={iconStyle} />
        },
        {
            label: t('homepage.purpose_5'),
            icon: <BarChartOutlinedIcon sx={iconStyle} />
        },
        {
            label: t('homepage.purpose_6'),
            icon: <ChecklistOutlinedIcon sx={iconStyle} />
        },
        {
            label: t('homepage.purpose_7'),
            icon: <VerifiedOutlinedIcon sx={iconStyle} />
        },
        {
            label: t('homepage.purpose_8'),
            icon: <TimelineIcon sx={iconStyle} />
        },
        {
            label: t('homepage.purpose_9'),
            icon: <SchemaOutlinedIcon sx={iconStyle} />
        },
    ];


    return (
        <Box
            display='flex'
            flexDirection='column'
            height='100%'
            alignItems='center'
            justifyContent='space-between'
            py={{ xs: '10px', lg: '40px' }}
            backgroundColor='#030200'
            sx={{
                overflowY: "auto"
            }}
            gap={isAboveMobileSize ? null : '150px'}
        >

            {/* NAVBAR */}
            <Box
                display='flex'
                width={{ xs: '90%', md: '80%', lg: '70%' }}
                height='50px'
                justifyContent={isAboveMobileSize ? 'flex-end' : 'center'}
                gap='50px'
            >
                <LanguageSwitch />
                <Button
                    variant='contained'
                    size='large'
                    onClick={() => { navigate('/login') }}
                    sx={{
                        width: '140px',
                        color: '#ffffff',
                        backgroundColor: '#1CA698',
                        '&:hover': {
                            color: '#ffffff',
                            backgroundColor: '#24D4C2'
                        }
                    }}
                >
                    {t('button.login')}
                </Button>
            </Box>

            {/* PAGE */}
            <Box
                width={{ xs: '90%', md: '80%', lg: '60%' }}
                display='flex'
                flexDirection='column'
                alignItems='center'
                gap={{ xs: '50px', md: '30px', lg: '50px' }}
            >
                <Grid
                    container
                    display='flex'
                    alignItems='center'
                    gap={isAboveMobileSize ? null : '100px'}
                >
                    <Grid
                        item
                        xs={12} md={6}
                    >
                        <Typography
                            color='#1CA698'
                            fontSize={{ xs: '60px', sm: '100px' }}
                            ml={isAboveMobileSize ? null : '40px'}
                        >
                            {t('homepage.header_1')}
                        </Typography>
                        <Typography
                            variant="h6"
                            py='30px'
                        >
                            {t('homepage.text_1')}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12} md={6}
                    >
                        {purposes.map((purpose, id) =>
                            <Box
                                key={id}
                                display='flex'
                                alignItems='center'
                                gap='16px'
                                p='6px'
                                ml={isAboveMobileSize ? '100px' : null}
                            >
                                {purpose.icon}
                                <Typography
                                    fontSize={{ xs: '18px', sm: '18px' }}
                                >
                                    {purpose.label}
                                </Typography>
                            </Box>
                        )}
                    </Grid>
                </Grid>
                <Grid
                    container
                    width={{ xs: '100%', md: '90vw', lg: '100vw' }}
                    display='flex'
                    justifyContent='center'
                    sx={{
                        opacity: 0.7,
                        background: `linear-gradient(to right, #030200, #ffffff20, #030200)`
                    }}
                    py='30px'
                    px={{ xs: '10px', sm: '120px', md: '120px', lg: '120px' }}
                    my={{ xs: '50px', md: '0px', lg: '0px' }}
                >
                    <Grid
                        item
                        xs={12} md={4} lg={2}
                        component="img"
                        src={orders_screenshot}
                        p={{ xs: '20px', md: '10px' }}
                    />
                    <Grid
                        item
                        xs={12} md={4} lg={2}
                        component="img"
                        src={chart_screenshot}
                        p={{ xs: '20px', md: '10px' }}
                    />
                    <Grid
                        item
                        xs={12} md={4} lg={2}
                        component="img"
                        src={dashboard_screenshot}
                        p={{ xs: '20px', md: '10px' }}
                    />
                    <Grid
                        item
                        xs={12} md={4} lg={2}
                        component="img"
                        src={places_screenshot}
                        p={{ xs: '20px', md: '10px' }}
                    />
                    <Grid
                        item
                        xs={12} md={4} lg={2}
                        component="img"
                        src={timeline_screenshot}
                        p={{ xs: '20px', md: '10px' }}
                    />
                    <Grid
                        item
                        xs={12} md={4} lg={2}
                        component="img"
                        src={device_screenshot}
                        p={{ xs: '20px', md: '10px' }}
                    />
                </Grid>
            </Box>

            {/* FOOTER */}
            <Box
                display='flex'
                width={{ xs: '90%', md: '80%', lg: '70%' }}
                justifyContent='space-between'
                alignItems='center'
                flexDirection={{ xs: 'column', sm: 'row' }}
                gap='20px'
            >
                <img alt='logo' src={logo} height='20px' />
                <Box>
                    <Typography variant="h5" mb='10px' color='#DBF288'>
                        {t('homepage.contact')}
                    </Typography>
                    <Box display='flex' alignItems='center' gap='10px'>
                        <MailOutlineIcon sx={{ fontSize: '24px' }} />
                        <Typography variant="h6">
                            contact@midnightcode.pl
                        </Typography>
                    </Box>
                </Box>
            </Box>


        </Box>
    );
};

export default Homepage;