import { useTranslation } from 'react-i18next';
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from '../../theme';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';


function NoMatchesNotification() {

    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            height='100%'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
        >
            <MoreHorizIcon sx={{ color: colors.primary[400], fontSize: '100px' }} />
            <Typography variant='h5' color={colors.primary[400]}>
                {t('notification.no_matches')}
            </Typography>
        </Box>
    );
};

export default NoMatchesNotification;