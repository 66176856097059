import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';

function ButtonMenu(props) {

    const { label, variant, color, icon, setAnchor, loading, disabled } = props;

    return (
        <LoadingButton
            variant={variant}
            color={color}
            sx={{ height: '100%', width: '100%', p: 0 }}
            onClick={(e) => setAnchor(e.currentTarget)}
            loading={loading}
            disabled={disabled}
        >
            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                {icon}
                {label}
            </Box>
        </LoadingButton>
    );
};

export default ButtonMenu;