import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";
import { formatNoResponseError } from "../storeUtils";

const initialState = {
  timeline: {
    data: [],
    downloading: {
      status: "idle", //  'idle' | 'loading' | 'succeeded' | 'failed'
      error: { status: null, status_text: null, detail: null },
    },
  },
};

export const getPlacesTimeline = createAsyncThunk(
  "placeAnalytics/Timeline",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/analytics/places/timeline`,
        data
      );
      return response.data;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const placesAnalyticsSlice = createSlice({
  name: "placeAnalytics",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* --- TIMELINE ------------------------------------------ */
      .addCase(getPlacesTimeline.pending, (state) => {
        state.timeline.downloading.status = "loading";
      })
      .addCase(getPlacesTimeline.fulfilled, (state, action) => {
        state.timeline.downloading.status = "succeeded";
        state.timeline.data = action.payload;
      })
      .addCase(getPlacesTimeline.rejected, (state, action) => {
        const d = state.timeline.downloading;
        const r = action.payload.response;
        d.status = "failed";
        d.error.status = r.status;
        d.error.status_text = r.statusText;
        d.error.detail = r.data.detail || r.data;
      });
  },
});

/* --- timeline ------------------------------------------------------- */
export const selectTimelineData = (state) =>
  state.placesAnalytics.timeline.data;
export const getTimelineDownloadState = (state) =>
  state.placesAnalytics.timeline.downloading;

/* --- REDUCERS --------------------------------------- */
//export const {} = analyticsSlice.actions;

export default placesAnalyticsSlice.reducer;
