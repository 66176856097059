import { Box, Typography } from '@mui/material';


function TooltipElement(props) {

    const { label, value, unit, color } = props

    return (
        <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
            gap='15px'
        >
            <Typography
                variant='subtitle2'
                color={color}
            >
                {label}:
            </Typography>
            <Box
                display='flex'
                alignItems='center'
                gap='10px'
            >
                <Typography
                    variant='h5'
                    color={color}
                >
                    {value}
                </Typography>
                <Typography
                    width='35px'
                    variant='subtitle2'
                    color={color}
                >
                    {unit}
                </Typography>
            </Box>
        </Box>
    );
};

export default TooltipElement;