import { useState, useEffect } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import { useTranslation } from 'react-i18next';


function UtilizationDisplay(props) {

    const { data } = props;

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { t } = useTranslation();

    const [utilization, setUtilization] = useState("---");

    useEffect(() => {

        const calculateUtilization = (data) => {

            if (data.length) {

                let runTime = 0;
                let availableTime = 0;

                for (let i = 0, len = data.length; i < len; i++) {

                    if (data[i].action === 'process') {
                        runTime = runTime + data[i].duration_timeline;
                    };
                    availableTime = availableTime + data[i].duration_timeline;
                };

                const utilization = (runTime / availableTime * 100).toFixed(0);
                return `${utilization}%`;
            };
            return "---";
        }

        setUtilization(calculateUtilization(data));

    }, [data]);


    return (
        <Box
            height='100%'
            display='flex'
            flexDirection='column'
            alignItems='center'
            gap='10px'
        >
            <Typography
                variant='h2'
                textAlign='center'
                color={colors.primary[300]}
            >
                {utilization}
            </Typography>
            <Typography
                variant='subtitle2'
                textAlign='center'
                color={colors.primary[300]}
                noWrap
                sx={{ fontSize: '11px' }}
            >
                {`${t("charts.legend.work_time")} / 24h`}
            </Typography>
        </Box>
    );
};

export default UtilizationDisplay;