import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    fetchDBSummary,
    getDBSummary,
    getDBSummaryDownloadState
} from '../../store/slices/analyticsSlice';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import TileDB from './TileDB';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import DeskRoundedIcon from '@mui/icons-material/DeskRounded';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';


function GroupDB() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const dispatch = useDispatch();
    const isMounted = useRef(false);
    const { t } = useTranslation();

    const data = useSelector(getDBSummary);
    const downloadState = useSelector(getDBSummaryDownloadState);
    const loading = downloadState.status;

    /* --------- GET ---------------------------------------------------------------------*/

    useEffect(() => {
        if (isMounted.current) return;
        /* eslint-disable */
        dispatch(fetchDBSummary(
            {
                orders: true,
                archived_orders: true,
                places: true,
                products: true,
                materials: true,
                technologies: true,
                users: true
            }
        ));

        isMounted.current = true;
    }, []);

    /* -----------------------------------------------------------------------------------*/

    const dbIconStyle = {
        color: colors.primary[700],
        fontSize: '40px'
    };

    return (
        <Box
            height='100%'
            display='flex'
            flexDirection='column'
            p='10px'
        >
            <Typography
                variant='h6'
                //align='center'
                color={colors.primary[300]}
                mt='-8px'
            >
                {t('header.db_summary')}
            </Typography>
            <Box
                height='100%'
                display='grid'
                gridTemplateRows='1fr 1fr 1fr 1fr 1fr 1fr 1fr'
                gap='10px'
            >
                <TileDB
                    title={t('label.orders')}
                    value={data.orders}
                    icon={<FormatListNumberedOutlinedIcon sx={dbIconStyle} />}
                    loading={loading === 'loading'}
                />
                <TileDB
                    title={t('label.orders_archived')}
                    value={data.archived_orders}
                    icon={<Inventory2OutlinedIcon sx={dbIconStyle} />}
                    loading={loading === 'loading'}
                />
                <TileDB
                    title={t('label.places')}
                    value={data.places}
                    icon={<DeskRoundedIcon sx={dbIconStyle} />}
                    loading={loading === 'loading'}
                />
                <TileDB
                    title={t('label.products')}
                    value={data.products}
                    icon={<CategoryOutlinedIcon sx={dbIconStyle} />}
                    loading={loading === 'loading'}
                />
                <TileDB
                    title={t('label.materials')}
                    value={data.materials}
                    icon={<LibraryAddOutlinedIcon sx={dbIconStyle} />}
                    loading={loading === 'loading'}
                />
                <TileDB
                    title={t('label.technologies')}
                    value={data.technologies}
                    icon={<SummarizeOutlinedIcon sx={dbIconStyle} />}
                    loading={loading === 'loading'}
                />
                <TileDB
                    title={t('label.users')}
                    value={data.users}
                    icon={<PeopleOutlinedIcon sx={dbIconStyle} />}
                    loading={loading === 'loading'}
                />
            </Box>
        </Box>
    );
};

export default GroupDB;