import { Box } from '@mui/material';
import MenuAction from '../buttons/MenuAction';
import MenuSupport from '../buttons/MenuSupport';

function TileActions() {

    return (
        <Box
            width='100%'
            height='100%'
            display='grid'
            gridTemplateRows='1fr 1fr 1fr 1fr'
            gap='10px'
        >
            <MenuAction />
            <MenuSupport />
        </Box>
    );
};

export default TileActions;