import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getLoggedUser } from "../store/slices/loginSlice";


function usePermission(required_permissions) {

  const user = useSelector(getLoggedUser);
  const permissions = user.permissions || []
  const [access, setAccess] = useState(undefined)

  useEffect(() => {
    if (user) {
      if (isDisabled(user)) {
        setAccess(false);
        /* eslint-disable */
      } else if (isAdmin(permissions)) {
        setAccess(true);
      } else if (hasPermission(permissions, required_permissions)) {
        setAccess(true);
      } else {
        setAccess(false);
      };
    };
  }, [user, required_permissions]);

  return access;
};


function isDisabled(user) {
  return user.disabled;
};


function isAdmin(permissions) {
  return permissions.includes("admin");
};


function hasPermission(permissions, required_permissions) {
  return required_permissions.some(permission => permissions.includes(permission));
};

export default usePermission;