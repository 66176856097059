import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";
import { formatNoResponseError } from "../storeUtils";

const initialState = {
  actionTile: {
    actionMenu: {
      uploading: {
        status: "idle", //  'idle' | 'loading' | 'succeeded' | 'failed'
        error: { status: null, status_text: null, detail: null },
      },
    },
    supportMenu: {
      uploading: {
        status: "idle", //  'idle' | 'loading' | 'succeeded' | 'failed'
        error: { status: null, status_text: null, detail: null },
      },
    },
  },
  mainTile: {
    backlogView: {
      backlog: [],
      downloading: {
        status: "idle", //  'idle' | 'loading' | 'succeeded' | 'failed'
        error: { status: null, status_text: null, detail: null },
      },
      uploading: {
        status: "idle", //  'idle' | 'loading' | 'succeeded' | 'failed'
        error: { status: null, status_text: null, detail: null },
      },
    },
    orderView: {
      uploading: {
        status: "idle", //  'idle' | 'loading' | 'succeeded' | 'failed'
        error: { status: null, status_text: null, detail: null },
      },
    },
  },
  navigationTile: {
    activeView: "backlog",
  },
  placeTile: {
    activePlace: {},
    places: [],
    downloading: {
      status: "idle", //  'idle' | 'loading' | 'succeeded' | 'failed'
      error: { status: null, status_text: null, detail: null },
    },
  },
};

export const getPlaceOrders = createAsyncThunk(
  "impersonate/getPlaceOrders",
  async (place_id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/devices/orders/${place_id}`);
      return [...response.data];
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const fetchAllPlaces = createAsyncThunk(
  "impersonate/fetchAllPlaces",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/devices/places/");
      return [...response.data];
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const getPlace = createAsyncThunk(
  "impersonate/getPlace",
  async (place_id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/devices/places/${place_id}`);
      return response.data;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const updatePlaceAction = createAsyncThunk(
  "impersonate/updatePlaceAction",
  async (place, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/devices/places/${place.place_id}`,
        place
      );
      return response.data;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const updatePlaceSupport = createAsyncThunk(
  "impersonate/updatePlaceSupport",
  async (place, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/devices/places/${place.place_id}`,
        place
      );
      return response.data;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const updatePlaceOrder = createAsyncThunk(
  "impersonate/updatePlaceOrder",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/devices/places/${data.place_id}/order`,
        data
      );
      return response.data;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const updateOrder = createAsyncThunk(
  "impersonate/updateOrder",
  async (order, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/devices/orders/${order.order_id}`,
        order
      );
      return response.data;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const impersonateSlice = createSlice({
  name: "impersonate",
  initialState,
  reducers: {
    /* --- Tile Navigation ------------------------------------------ */
    setActiveView: (state, action) => {
      state.navigationTile.activeView = action.payload;
    },
    /* --- Tile Action ---------------------------------------------- */
    closeLoadingErrorDialog: (state) => {
      state.actionTile.actionMenu.uploading.status = "idle";
      state.actionTile.supportMenu.uploading.status = "idle";
    },
  },
  extraReducers(builder) {
    builder
      /* --- READ ORDERS ------------------------------------ */
      .addCase(getPlaceOrders.pending, (state) => {
        state.mainTile.backlogView.downloading.status = "loading";
      })
      .addCase(getPlaceOrders.fulfilled, (state, action) => {
        state.mainTile.backlogView.downloading.status = "succeeded";
        state.mainTile.backlogView.backlog = action.payload;
      })
      .addCase(getPlaceOrders.rejected, (state, action) => {
        const d = state.mainTile.backlogView.downloading;
        const r = action.payload.response;
        d.status = "failed";
        d.error.status = r.status;
        d.error.status_text = r.statusText;
        d.error.detail = r.data.detail || r.data;
      })
      /* --- READ PLACES ------------------------------------ */
      .addCase(fetchAllPlaces.pending, (state) => {
        state.placeTile.downloading.status = "loading";
      })
      .addCase(fetchAllPlaces.fulfilled, (state, action) => {
        state.placeTile.downloading.status = "succeeded";
        state.placeTile.places = action.payload;
      })
      .addCase(fetchAllPlaces.rejected, (state, action) => {
        const d = state.placeTile.downloading;
        const r = action.payload.response;
        d.status = "failed";
        d.error.status = r.status;
        d.error.status_text = r.statusText;
        d.error.detail = r.data.detail || r.data;
      })
      /* --- READ PLACE ------------------------------------- */
      .addCase(getPlace.pending, (state) => {
        state.placeTile.downloading.status = "loading";
      })
      .addCase(getPlace.fulfilled, (state, action) => {
        state.placeTile.downloading.status = "succeeded";
        state.placeTile.activePlace = action.payload;
      })
      .addCase(getPlace.rejected, (state, action) => {
        const d = state.placeTile.downloading;
        const r = action.payload.response;
        d.status = "failed";
        d.error.status = r.status;
        d.error.status_text = r.statusText;
        d.error.detail = r.data.detail || r.data;
      })
      /* --- UPDATE PLACE ACTION ----------------------------- */
      .addCase(updatePlaceAction.pending, (state) => {
        state.actionTile.actionMenu.uploading.status = "loading";
      })
      .addCase(updatePlaceAction.fulfilled, (state, action) => {
        state.placeTile.activePlace = action.payload;
        state.actionTile.actionMenu.uploading.status = "succeeded";
      })
      .addCase(updatePlaceAction.rejected, (state, action) => {
        const u = state.actionTile.actionMenu.uploading;
        const r = action.payload.response;
        u.status = "failed";
        u.error.status = r.status;
        u.error.status_text = r.statusText;
        u.error.detail = r.data.detail || r.data;
      })
      /* --- UPDATE PLACE SUPPORT ---------------------------- */
      .addCase(updatePlaceSupport.pending, (state) => {
        state.actionTile.supportMenu.uploading.status = "loading";
      })
      .addCase(updatePlaceSupport.fulfilled, (state, action) => {
        state.placeTile.activePlace = action.payload;
        state.actionTile.supportMenu.uploading.status = "succeeded";
      })
      .addCase(updatePlaceSupport.rejected, (state, action) => {
        const u = state.actionTile.supportMenu.uploading;
        const r = action.payload.response;
        u.status = "failed";
        u.error.status = r.status;
        u.error.status_text = r.statusText;
        u.error.detail = r.data.detail || r.data;
      })
      /* --- UPDATE PLACE ORDER ------------------------------ */
      .addCase(updatePlaceOrder.pending, (state) => {
        state.mainTile.backlogView.uploading.status = "loading";
      })
      .addCase(updatePlaceOrder.fulfilled, (state, action) => {
        state.placeTile.activePlace = action.payload;
        state.mainTile.backlogView.uploading.status = "succeeded";
        if (action.payload.order === null) {
          state.navigationTile.activeView = "backlog";
        } else {
          state.navigationTile.activeView = "order";
        }
      })
      .addCase(updatePlaceOrder.rejected, (state, action) => {
        const u = state.mainTile.backlogView.uploading;
        const r = action.payload.response;
        u.status = "failed";
        u.error.status = r.status;
        u.error.status_text = r.statusText;
        u.error.detail = r.data.detail || r.data;
      })
      /* --- UPDATE ORDER ------------------------------------ */
      .addCase(updateOrder.pending, (state) => {
        state.mainTile.orderView.uploading.status = "loading";
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        state.placeTile.activePlace.order = action.payload;
        state.mainTile.orderView.uploading.status = "succeeded";
      })
      .addCase(updateOrder.rejected, (state, action) => {
        const u = state.mainTile.orderView.uploading;
        const r = action.payload.response;
        u.status = "failed";
        u.error.status = r.status;
        u.error.status_text = r.statusText;
        u.error.detail = r.data.detail || r.data;
      });
  },
});

/* --- Place tile --------------------------------------------------------------------- */
export const selectAllPlaces = (state) => state.impersonate.placeTile.places;
export const getActivePlace = (state) =>
  state.impersonate.placeTile.activePlace;
export const getPlaceTileDownloadState = (state) =>
  state.impersonate.placeTile.downloading;

/* --- Main tile ---------------------------------------------------------------------- */
export const getBacklog = (state) =>
  state.impersonate.mainTile.backlogView.backlog;
export const getBacklogDownloadState = (state) =>
  state.impersonate.mainTile.backlogView.downloading;
export const getOrderUploadState = (state) =>
  state.impersonate.mainTile.orderView.uploading;

/* --- Navigation tile ---------------------------------------------------------------- */
export const getActiveView = (state) =>
  state.impersonate.navigationTile.activeView;

/* --- Action tile -------------------------------------------------------------------- */
export const getActionUploadState = (state) =>
  state.impersonate.actionTile.actionMenu.uploading;
export const getSupportUploadState = (state) =>
  state.impersonate.actionTile.supportMenu.uploading;

/* --- REDUCERS --------------------------------------- */
export const { setActiveView, closeLoadingErrorDialog } =
  impersonateSlice.actions;

export default impersonateSlice.reducer;
