import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeRegisterSuccessDialog, getRegisterLoadingState } from '../../store/slices/registerSlice';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';


function RegisterVerificationDialog() {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const loadState = useSelector(getRegisterLoadingState);

    const handleClose = () => {
        dispatch(closeRegisterSuccessDialog());
        navigate('/login');
    };

    return (
        <Dialog
            open={loadState.status === 'succeeded'}
            onClose={handleClose}
        >
            <DialogTitle>
                {t('header.success')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('text.registration_needs_verification')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    type='submit'
                    variant="contained"
                    onClick={handleClose}
                >
                    {t('button.close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RegisterVerificationDialog;