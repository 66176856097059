export const timezones = [
  {
    id: 1,
    value: "Etc/GMT+12",
    offset: "[-12.00]",
    label: "Baker Island Time",
  },
  {
    id: 2,
    value: "Pacific/Niue",
    offset: "[-11.00]",
    label: "Niue Time, Samoa Standard Time",
  },
  {
    id: 3,
    value: "Pacific/Tahiti",
    offset: "[-10.00]",
    label: "Hawaii-Aleutian Standard Time, Cook Island Time",
  },
  {
    id: 4,
    value: "Pacific/Marquesas",
    offset: "[-09.30]",
    label: "Marquesas Islands Time",
  },
  {
    id: 5,
    value: "Pacific/Gambier",
    offset: "[-09.00]",
    label: "Alaska Standard Time, Gambier Island Time",
  },
  {
    id: 6,
    value: "US/Pacific",
    offset: "[-08.00]",
    label: "Pacific Standard Time",
  },
  {
    id: 7,
    value: "Canada/Mountain",
    offset: "[-07.00]",
    label: "Mountain Standard Time",
  },
  {
    id: 8,
    value: "US/Central",
    offset: "[-06.00]",
    label: "Central Standard Time",
  },
  {
    id: 9,
    value: "US/Eastern",
    offset: "[-05.00]",
    label: "Eastern Standard Time",
  },
  {
    id: 10,
    value: "Canada/Atlantic",
    offset: "[-04.00]",
    label: "Atlantic Standard Time",
  },
  {
    id: 11,
    value: "Canada/Newfoundland",
    offset: "[-03.30]",
    label: "Newfoundland Standard Time",
  },
  {
    id: 12,
    value: "Brazil/East",
    offset: "[-03:00]",
    label: "Amazon Standard Time, Central Greenland Time",
  },
  {
    id: 13,
    value: "Atlantic/South_Georgia",
    offset: "[-02:00]",
    label: "Fernando de Noronha Time, South Georgia",
  },
  {
    id: 14,
    value: "Atlantic/Azores",
    offset: "[-01:00]",
    label: "Azores Standard Time, Eastern Greenland Time",
  },
  {
    id: 15,
    value: "Etc/UTC",
    offset: "[+00:00]",
    label: "[UTC] Western European Time, Greenwich Mean Time",
  },
  {
    id: 16,
    value: "Europe/Warsaw",
    offset: "[+01:00]",
    label: "Central European Time, West African Time",
  },
  {
    id: 17,
    value: "Europe/Helsinki",
    offset: "[+02:00]",
    label: "Eastern European Time, Central African Time",
  },
  {
    id: 18,
    value: "Europe/Istanbul",
    offset: "[+03:00]",
    label: "Moscow Standard Time, Eastern African Time",
  },
  {
    id: 19,
    value: "Iran",
    offset: "[+03:30]",
    label: "Iran Standard Time",
  },
  {
    id: 20,
    value: "Europe/Samara",
    offset: "[+04:00]",
    label: "Gulf Standard Time, Samara Standard Time",
  },
  {
    id: 21,
    value: "Asia/Kabul",
    offset: "[+04:30]",
    label: "Afghanistan Time",
  },
  {
    id: 22,
    value: "Indian/Maldives",
    offset: "[+05:00]",
    label: "Pakistan Standard Time",
  },
  {
    id: 23,
    value: "Asia/Calcutta",
    offset: "[+05:30]",
    label: "Indian Standard Time, Sri Lanka Time",
  },
  {
    id: 24,
    value: "Asia/Katmandu",
    offset: "[+05:45]",
    label: "Nepal Time",
  },
  {
    id: 25,
    value: "Asia/Dacca",
    offset: "[+06:00]",
    label: "Bangladesh Time, Bhutan Time",
  },
  {
    id: 26,
    value: "Indian/Cocos",
    offset: "[+06:30]",
    label: "Cocos Islands Time, Myanmar Time",
  },
  {
    id: 27,
    value: "Asia/Jakarta",
    offset: "[+07:00]",
    label: "Indochina Time",
  },
  {
    id: 28,
    value: "Australia/West",
    offset: "[+08:00]",
    label: "Chinese Standard Time, Australian WST",
  },
  {
    id: 29,
    value: "Australia/Eucla",
    offset: "[+08:45]",
    label: "Southeastern Western Australia Standard Time",
  },
  {
    id: 30,
    value: "Japan",
    offset: "[+09:00]",
    label: "Japan Standard Time, Korea Standard Time",
  },
  {
    id: 31,
    value: "Australia/North",
    offset: "[+09:30]",
    label: "Australian Central Standard Time",
  },
  {
    id: 32,
    value: "Australia/Brisbane",
    offset: "[+10:00]",
    label: "Australian Eastern Standard Time",
  },
  {
    id: 33,
    value: "Australia/Lord_Howe",
    offset: "[+10:30]",
    label: "Lord Howe Standard Tim",
  },
  {
    id: 34,
    value: "Pacific/Bougainville",
    offset: "[+11:00]",
    label: "Solomon Island Time, Magadan Standard Time",
  },
  {
    id: 35,
    value: "Pacific/Auckland",
    offset: "[+12:00]",
    label: "New Zealand Time, Fiji Time, Kamchatka ST",
  },
  {
    id: 36,
    value: "Pacific/Chatham",
    offset: "[+12:45]",
    label: "Chatham Islands Time",
  },
  {
    id: 37,
    value: "Pacific/Tongatapu",
    offset: "[+13:00]",
    label: "Tonga Time, Phoenix Islands Time",
  },
  {
    id: 38,
    value: "Pacific/Kiritimati",
    offset: "[+14:00]",
    label: "Line Island Time",
  },
];
