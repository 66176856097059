import { Box, useTheme } from "@mui/material";
import { tokens } from '../../../theme';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

function OrderComment(props) {

    const { text } = props;

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const iconStyle = { 
        fontSize: '30px',
        color: colors.grey[100],
        mx: '10px'
    }

    return (
        <>
            {text !== "" ? (
                <Box
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{
                        backgroundColor: colors.assigned.orderComment,
                        color: colors.assigned.textContrast,
                        borderRadius: '3px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap'
                    }}
                >
                    <WarningAmberOutlinedIcon sx={iconStyle} />
                    {text}
                    <WarningAmberOutlinedIcon sx={iconStyle} />
                </Box>
            ) : (
                <Box />
            )}
        </>
    );
};

export default OrderComment;