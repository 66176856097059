import { useSelector } from 'react-redux/es/hooks/useSelector';
import { getTimezone } from '../../store/slices/loginSlice';
import { Box, Typography, useTheme } from '@mui/material'
import { tokens } from '../../theme';
import moment from 'moment-timezone';


function CreatedUpdatedField(props) {

    const { label, date } = props

    const theme = useTheme();
    const colors = tokens(theme.palette.mode)

    const timezone = useSelector(getTimezone)

    const formatedDate = moment.utc(date).tz(timezone).format('DD-MM-YYYY, HH:mm')

    return (
        <Box display='flex' alignItems='center' gap='10px'>
            <Typography variant='subtitle2' noWrap sx={{ color: colors.grey[200] }}>
                {label}:
            </Typography>
            <Typography variant='subtitle1' noWrap>
                {formatedDate}
            </Typography>
        </Box>
    )
}

export default CreatedUpdatedField