import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    fetchProduct,
    getProduct
} from '../../store/slices/productsSlice';
import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import settings from '../../config.json'
import useMediaQuery from '../../hooks/useMediaQuery';
import ProductScrapRateChart from './analytics/ProductScrapRateChart';
import ProductProductionOutputChart from './analytics/ProductProductionOutputChart';


const gridTemplateLargeScreen =
    `
    "a a a "
    "b b b "
    `

const gridTemplateMobileScreen =
    `
    "a"
    "b"
    `

function PageProductAnalytics() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const dispatch = useDispatch();
    const isMounted = useRef(false);
    const { id } = useParams();
    const isAboveMediumScreens = useMediaQuery(settings.ABOVE_MEDIUM_MEDIA_QUERY);

    const product = useSelector(getProduct);

    /* --------- GET ---------------------------------------------------------------------*/

    useEffect(() => {
        if (isMounted.current) return;
        /* eslint-disable */

        if (!product) {
            dispatch(fetchProduct(id));
        };

        isMounted.current = true;
    }, []);

    /* -----------------------------------------------------------------------------------*/

    const areaStyle = {
        //backgroundColor: colors.primary[400],
        border: '1px solid',
        borderColor: colors.primary[400],
        borderRadius: '10px',
        boxShadow: '3px 3px 10px -5px rgba(0, 0, 0, .8)'
    };

    return (
        <>
            <Typography
                variant='h4'
                color={colors.assigned.tableHeader}
                p='0 20px 20px 20px'
            >
                {product ? `${product?.product_number} - ${product?.product_name}` : "loading..."}
            </Typography>
            <Box
                display='grid'
                gap='20px'
                sx={isAboveMediumScreens ? {
                    gridTemplateColumns: "repeat(3, minmax(200px, 1fr))",
                    gridTemplateRows: "repeat(2, minmax(380px, 1fr))",
                    gridTemplateAreas: gridTemplateLargeScreen,
                } : {
                    gridAutoColumns: "1fr",
                    gridAutoRows: "380px",
                    gridTemplateAreas: gridTemplateMobileScreen,
                }}
            >
                <Box gridArea='a' sx={areaStyle}>
                    <ProductScrapRateChart />
                </Box>
                <Box gridArea='b' sx={areaStyle}>
                    <ProductProductionOutputChart />
                </Box>
            </Box>
        </>
    );
};

export default PageProductAnalytics;