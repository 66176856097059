import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { tokens } from '../../theme';


function TileOrder(props) {

    const { title, value, color, loading } = props;

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const color2 = colors.primary[400];

    return (
        <Box
            height='100%'
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
            alignItems='center'
            sx={{ background: `linear-gradient(to right, ${color}, ${color2}20)` }}
            p='20px'
        >
            <Typography
                variant='subtitle1'
                align='center'
            >
                {title}
            </Typography>
            <Box
                height='100%'
                display='flex'
                alignItems='center'
            >
                {loading ? (
                    <CircularProgress color='secondary' />
                ) : (
                    <Typography
                        variant='h6'
                        color={colors.grey[100]}
                        sx={{ fontSize: '60px' }}
                        my='-10px'
                    >
                        {value}
                    </Typography>
                )}
            </Box>
        </Box>
    );
};

export default TileOrder;