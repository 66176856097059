import { Box, useTheme } from "@mui/material";
import { tokens } from '../../theme';
import { useTranslation } from 'react-i18next';
import TableHeader from '../../components/table/TableHeader';
import DeviceScreen from "../../components/device/DeviceScreen";
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';


function PageImpersonate() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { t } = useTranslation();


    return (
        <Box
            display='flex'
            flexDirection='column'
            minWidth='1064px'
        >
            <TableHeader
                title={t('page.impersonate')}
                icon={<MobileScreenShareIcon sx={{ color: colors.assigned.tableHeader, fontSize: '32px' }} />}
            >
                {/* Add Children Here */}
            </TableHeader>
            <Box
                display='flex'
                justifyContent='center'
                py='50px'
            >
                <DeviceScreen />
            </Box>
        </Box>
    );
};

export default PageImpersonate;