import { useSelector } from 'react-redux';
import { getActivePlace } from '../../../store/slices/impersonateSlice';
import { Box, Typography } from '@mui/material';
import { stepIndexForPlace, noOfFinishedSteps } from '../../../utils/orderStepProgress';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined';

function StepsComponent(props) {

    const { order } = props

    const activePlace = useSelector(getActivePlace)

    const stepsTotal = order.steps.length
    const currentStep = stepIndexForPlace(activePlace, order)
    const finishedSteps = noOfFinishedSteps(order)

    /* -----------------------------------------------------------------------------------*/

    return (
        <Box
            display='flex'
            gap='6px'
        >
            <Box
                display='flex'
                alignItems='center'
            >
                <CheckCircleOutlinedIcon color='success' sx={{ fontSize: '20px' }} />
                <Typography variant='subtitle1'>
                    {finishedSteps} / {stepsTotal}
                </Typography>
            </Box>
            <Box
                display='flex'
                alignItems='center'
            >
                <ArrowCircleDownOutlinedIcon color='success' sx={{ fontSize: '20px' }} />
                <Typography variant='subtitle1'>
                    {currentStep}
                </Typography>
            </Box>
        </Box>
    )
}

export default StepsComponent