import { useState } from 'react';
import { Box, Button, Divider, Grid, Stepper, Step, StepButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
    calculateScrapRate
} from '../../utils/orderStepAnalytics';


function OrderDetails(props) {

    const { order } = props

    const { t } = useTranslation();

    const [activeStep, setActiveStep] = useState(0);
    const [completed, /*setCompleted*/] = useState({});

    const steps = order.steps || []

    const gridStyle = { display: 'flex', alignItems: 'center', p: '5px 15px' };

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    // const handleComplete = () => {
    //     const newCompleted = completed;
    //     newCompleted[activeStep] = true;
    //     setCompleted(newCompleted);
    //     handleNext();
    // };

    // const handleReset = () => {
    //     setActiveStep(0);
    //     setCompleted({});
    // };

    return (
        <Box
            width='100%'
            height='100%'
        >
            <Box
                display='flex'
                alignItems='center'
            >
                <Button
                    variant='outlined'
                    color="secondary"
                    size='large'
                    disabled={activeStep === 0}
                    onClick={handleBack}
                >
                    {t('button.back')}
                </Button>
                <Stepper
                    nonLinear
                    alternativeLabel
                    activeStep={activeStep}
                    sx={{
                        width: '100%',
                        p: '10px',
                        m: '10px',
                        overflowX: 'auto',
                        overflowY: 'hidden'
                    }}>
                    {steps?.map((step, index) => (
                        <Step key={step.process} completed={completed[index]}>
                            <StepButton color="inherit" onClick={handleStep(index)}>
                                {step.process}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
                <Button
                    variant='outlined'
                    color="secondary"
                    size='large'
                    onClick={handleNext}
                >
                    {t('button.next')}
                </Button>
            </Box>
            <Divider />
            <Grid
                container
                sx={{ mt: '40px' }}
            >
                <Grid
                    item
                    xs={4}
                    sx={gridStyle}
                >
                    <Typography variant='h5'>
                        {t('label.process')}:
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={8}
                    sx={gridStyle}
                >
                    <Typography variant='h4' color='secondary'>
                        {steps[activeStep]?.process}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={4}
                    sx={gridStyle}
                >
                    <Typography variant='h5'>
                        {t('label.place')}:
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={8}
                    sx={gridStyle}
                >
                    <Typography variant='h4' color='secondary'>
                        {steps[activeStep]?.place.place_number + " - " + steps[activeStep]?.place.place_name}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={4}
                    sx={gridStyle}
                >
                    <Typography variant='h5'>
                        {t('label.status')}:
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={8}
                    sx={gridStyle}
                >
                    <Typography variant='h4' color='error'>
                        {steps[activeStep]?.status}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={4}
                    sx={gridStyle}
                >
                    <Typography variant='h5'>
                        {t('label.conformance')}:
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={8}
                    sx={gridStyle}
                >
                    <Typography variant='h4'>
                        {steps[activeStep]?.quantities.conformance}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={4}
                    sx={gridStyle}
                >
                    <Typography variant='h5'>
                        {t('label.nonconformance')}:
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={8}
                    sx={gridStyle}
                >
                    <Typography variant='h4'>
                        {steps[activeStep]?.quantities.nonconformance}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={4}
                    sx={gridStyle}
                >
                    <Typography variant='h5'>
                        {t('label.scrap_rate')}:
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={8}
                    sx={gridStyle}
                >
                    <Typography variant='h4'>
                        {calculateScrapRate(order, activeStep) + " %"}
                    </Typography>
                </Grid>
            </Grid>
            {/* <>
                {allStepsCompleted() ? (
                    <>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Reset</Button>
                        </Box>
                    </>
                ) : (
                    <>
                        <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                            Step {activeStep + 1}
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            {activeStep !== steps &&
                                (completed[activeStep] ? (
                                    <Typography variant="caption" sx={{ display: 'inline-block' }}>
                                        Step {activeStep + 1} already completed
                                    </Typography>
                                ) : (
                                    <Button onClick={handleComplete}>
                                        {completedSteps() === totalSteps() - 1
                                            ? 'Finish'
                                            : 'Complete Step'}
                                    </Button>
                                ))
                            }
                        </Box>
                    </>
                )}
            </> */}
        </Box >
    );
};

export default OrderDetails;