import { useTranslation } from 'react-i18next';
import { tokens } from '../../theme';
import { Box, useTheme } from '@mui/material';
import TableHeader from '../../components/table/TableHeader';
import AccordionSettings from './AccordionSettings';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import SnackbarBasic from '../../components/notifications/SnackbarBasic';


function PageSettings() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { t } = useTranslation();


    return (
        <>

            {/* PAGE */}
            <Box display='flex' flexDirection='column'>
                <TableHeader
                    title={t('page.settings')}
                    icon={<TuneOutlinedIcon sx={{ color: colors.assigned.tableHeader, fontSize: '32px' }} />}
                >
                    {/* children here */}
                </TableHeader>
                <AccordionSettings />
            </Box>
            <SnackbarBasic />

        </>
    );
};

export default PageSettings;