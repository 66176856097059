import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";
import { formatNoResponseError } from "../storeUtils";

const initialState = {
  db_summary: {
    summary: {},
    downloading: {
      status: "idle", //  'idle' | 'loading' | 'succeeded' | 'failed'
      error: { status: null, status_text: null, detail: null },
    },
  },
  orders_summary: {
    summary: {},
    downloading: {
      status: "idle", //  'idle' | 'loading' | 'succeeded' | 'failed'
      error: { status: null, status_text: null, detail: null },
    },
  },
  places: {
    places: [],
    downloading: {
      status: "idle", //  'idle' | 'loading' | 'succeeded' | 'failed'
      error: { status: null, status_text: null, detail: null },
    },
  },
};

export const fetchDBSummary = createAsyncThunk(
  "analytics/fetchDBSummary",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/analytics/db-summary", data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const fetchOrdersSummary = createAsyncThunk(
  "analytics/fetchOrdersSummary",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/analytics/orders-summary");
      return response.data;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const fetchAllPlaces = createAsyncThunk(
  "analytics/fetchAllPlaces",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/analytics/places/");
      return [...response.data];
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* --- DB ---------------------------------------------- */
      .addCase(fetchDBSummary.pending, (state) => {
        state.db_summary.downloading.status = "loading";
      })
      .addCase(fetchDBSummary.fulfilled, (state, action) => {
        state.db_summary.downloading.status = "succeeded";
        state.db_summary.summary = action.payload;
      })
      .addCase(fetchDBSummary.rejected, (state, action) => {
        const d = state.db_summary.downloading;
        const r = action.payload.response;
        d.status = "failed";
        d.error.status = r.status;
        d.error.status_text = r.statusText;
        d.error.detail = r.data.detail || r.data;
      })
      /* --- ORDERS ------------------------------------------ */
      .addCase(fetchOrdersSummary.pending, (state) => {
        state.orders_summary.downloading.status = "loading";
      })
      .addCase(fetchOrdersSummary.fulfilled, (state, action) => {
        state.orders_summary.downloading.status = "succeeded";
        state.orders_summary.summary = action.payload;
      })
      .addCase(fetchOrdersSummary.rejected, (state, action) => {
        const d = state.orders_summary.downloading;
        const r = action.payload.response;
        d.status = "failed";
        d.error.status = r.status;
        d.error.status_text = r.statusText;
        d.error.detail = r.data.detail || r.data;
      })
      /* --- PLACES ------------------------------------------ */
      .addCase(fetchAllPlaces.pending, (state) => {
        state.places.downloading.status = "loading";
      })
      .addCase(fetchAllPlaces.fulfilled, (state, action) => {
        state.places.downloading.status = "succeeded";
        state.places.places = action.payload;
      })
      .addCase(fetchAllPlaces.rejected, (state, action) => {
        const d = state.places.downloading;
        const r = action.payload.response;
        d.status = "failed";
        d.error.status = r.status;
        d.error.status_text = r.statusText;
        d.error.detail = r.data.detail || r.data;
      });
  },
});

/* --- DB -------------------------------------------------------------- */
export const getDBSummary = (state) => state.analytics.db_summary.summary;
export const getDBSummaryDownloadState = (state) =>
  state.analytics.db_summary.downloading;

/* --- ORDERS ---------------------------------------------------------- */
export const getOrdersSummary = (state) =>
  state.analytics.orders_summary.summary;
export const getOrdersSummaryDownloadState = (state) =>
  state.analytics.orders_summary.downloading;

/* --- PLACES ---------------------------------------------------------- */
export const selectPlacesBySupport = (state, support) =>
  state.analytics.places.places.filter((place) => place.support === support);
export const getPlacesAnalyticsDownloadState = (state) =>
  state.analytics.places.downloading;

/* --------------------------------------------------------------------- */
export default analyticsSlice.reducer;
