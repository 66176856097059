import { useSelector } from 'react-redux';
import { getTimezone } from '../../store/slices/loginSlice';
import { Typography, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import moment from 'moment-timezone';

function TableDueDate(props) {

    const { due_date } = props;

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const timezone = useSelector(getTimezone);

    //const date_formated = moment(due_date).format('DD-MM-YYYY')
    const date_formated = moment.utc(due_date).tz(timezone).format('DD-MM-YYYY');
    const date = moment(due_date);
    const now = moment(new Date()).startOf('day');
    const difference = date.diff(now, 'days');


    const renderStandard = () => {
        return <>
            <Typography variant='subtitle1'>
                {date_formated}
            </Typography>
        </>
    };

    const renderRedWarning = () => {
        return <>
            <Typography variant='subtitle1' color={colors.redAccent[400]}>
                {date_formated}
            </Typography>
        </>
    };

    const renderYellowWarning = () => {
        return <>
            <Typography variant='subtitle1' color={colors.yellowAccent[300]}>
                {date_formated}
            </Typography>
        </>
    };


    return (
        <>
            {due_date !== "1970-01-01T00:00:00" ? (difference >= 1) ? renderStandard() : (difference >= 0) ? renderYellowWarning() : renderRedWarning() : ''}
        </>
    );
};

export default TableDueDate;