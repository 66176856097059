import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PauseOutlinedIcon from '@mui/icons-material/PauseOutlined';


function IdleChip() {

    const { t } = useTranslation();

    return (
        <Chip
            icon={<PauseOutlinedIcon />}
            label={t('chip.idle')}
            variant="outlined"
            color="info"
            size="medium"
            sx={{ width: '100%' }}
        />
    );
};

export default IdleChip;