import { Button } from '@mui/material';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';


function TableActionAnalytics(props) {

    const { onClick } = props;


    return (
        <Button
            variant='outlined'
            color='warning'
            onClick={onClick}
        >
            <BarChartOutlinedIcon />
        </Button>
    );
};

export default TableActionAnalytics;