import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from '../../theme';


function ChartHeader(props) {

    const { title, subtitle } = props

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            m='10px 20px'
        >
            <Typography
                variant='h5'
                color={colors.grey[200]}
            >
                {title}
            </Typography>
            <Typography
                variant='subtitle2'
                color={colors.grey[400]}
            >
                {subtitle}
            </Typography>
        </Box>
    );
};

export default ChartHeader;