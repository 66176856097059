import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";
import { notifySuccess } from "./snackbarSlice";
import { formatNoResponseError } from "../storeUtils";

const initialTechnology = {
  technology_name: "",
  technology_number: "",
  description: "",
  steps: [{ process: "", place: "" }],
};

const initialState = {
  technologies: [],
  activeTechnology: initialTechnology,
  technologyDialog: {
    isOpen: false,
    mode: null, //  null | 'add' | 'edit'
    activeTab: "general",
  },
  downloading: {
    status: "idle", //  'idle' | 'loading' | 'succeeded' | 'failed'
    error: { status: null, status_text: null, detail: null },
  },
  uploading: {
    status: "idle", //  'idle' | 'loading' | 'succeeded' | 'failed'
    error: { status: null, status_text: null, detail: null },
  },
  deleting: {
    dialog: false,
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: { status: null, status_text: null, detail: null },
  },
};

export const getAllTechnologies = createAsyncThunk(
  "technologies/getTechnologies",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/technology/");
      return [...response.data];
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const createTechnology = createAsyncThunk(
  "technologies/createTechnology",
  async (technology, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/technology/create`,
        technology
      );
      dispatch(notifySuccess());
      return response.data;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const updateTechnology = createAsyncThunk(
  "technologies/updateTechnology",
  async (technology, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/technology/${technology.technology_id}`,
        technology
      );
      dispatch(notifySuccess());
      return response.data;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const deleteTechnology = createAsyncThunk(
  "technologies/deleteTechnology",
  async (technology, { rejectWithValue }) => {
    try {
      await axiosInstance.delete(`/technology/${technology.technology_id}`);
      return technology.technology_id;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const technologiesSlice = createSlice({
  name: "technologies",
  initialState,
  reducers: {
    /* --- Technology ------------------------------------------- */
    setActiveTechnology: (state, action) => {
      state.activeTechnology = state.technologies.find(
        (technology) =>
          technology.technology_id === action.payload.technology_id
      );
    },
    resetActiveTechnology: (state) => {
      state.activeTechnology = initialTechnology;
    },
    /* --- Technology dialog -------------------------------------*/
    startAddingTechnology: (state) => {
      state.technologyDialog.mode = "add";
      state.technologyDialog.activeTab = "general";
      state.technologyDialog.isOpen = true;
    },
    startEditingTechnology: (state) => {
      state.technologyDialog.mode = "edit";
      state.technologyDialog.activeTab = "general";
      state.technologyDialog.isOpen = true;
    },
    closeTechnologyDialog: (state) => {
      state.technologyDialog.isOpen = false;
      state.technologyDialog.activeTab = "general";
      state.technologyDialog.mode = null;
      state.activeTechnology = initialTechnology;
    },
    setTechnologyActiveTab: (state, action) => {
      state.technologyDialog.activeTab = action.payload;
    },
    /* --- Information dialogs ---------------------------- */
    startDeletingTechnology: (state) => {
      state.deleting.status = "idle";
      state.deleting.dialog = true;
    },
    closeDeletingDialog: (state) => {
      state.deleting.dialog = false;
    },
    closeLoadingErrorDialog: (state) => {
      state.uploading.status = "idle";
    },
  },
  extraReducers(builder) {
    builder
      /* --- READ ------------------------------------------- */
      .addCase(getAllTechnologies.pending, (state) => {
        state.downloading.status = "loading";
      })
      .addCase(getAllTechnologies.fulfilled, (state, action) => {
        state.downloading.status = "succeeded";
        state.technologies = action.payload;
      })
      .addCase(getAllTechnologies.rejected, (state, action) => {
        state.downloading.status = "failed";
        state.downloading.error.status = action.payload.response.status;
        state.downloading.error.status_text =
          action.payload.response.statusText;
        state.downloading.error.detail =
          action.payload.response.data.detail || action.payload.response.data;
      })
      /* --- CREATE ----------------------------------------- */
      .addCase(createTechnology.pending, (state) => {
        state.uploading.status = "loading";
      })
      .addCase(createTechnology.fulfilled, (state, action) => {
        state.activeTechnology = action.payload;
        state.technologies.push(action.payload);
        state.uploading.status = "succeeded";
        state.technologyDialog.mode = "edit";
      })
      .addCase(createTechnology.rejected, (state, action) => {
        state.uploading.status = "failed";
        state.uploading.error.status = action.payload.response.status;
        state.uploading.error.status_text = action.payload.response.statusText;
        state.uploading.error.detail =
          action.payload.response.data.detail || action.payload.response.data;
      })
      /* --- UPDATE ----------------------------------------- */
      .addCase(updateTechnology.pending, (state) => {
        state.uploading.status = "loading";
      })
      .addCase(updateTechnology.fulfilled, (state, action) => {
        state.activeTechnology = action.payload;
        const technology_index = state.technologies.findIndex(
          (technology) =>
            technology.technology_id === action.payload.technology_id
        );
        state.technologies[technology_index] = action.payload;
        state.uploading.status = "succeeded";
      })
      .addCase(updateTechnology.rejected, (state, action) => {
        state.uploading.status = "failed";
        state.uploading.error.status = action.payload.response.status;
        state.uploading.error.status_text = action.payload.response.statusText;
        state.uploading.error.detail =
          action.payload.response.data.detail || action.payload.response.data;
      })
      /* --- DELETE ----------------------------------------- */
      .addCase(deleteTechnology.pending, (state) => {
        state.deleting.status = "loading";
      })
      .addCase(deleteTechnology.fulfilled, (state, action) => {
        const technology_index = state.technologies.findIndex(
          (technology) => technology.technology_id === action.payload
        );
        state.technologies.splice(technology_index, 1);
        state.activeTechnology = initialTechnology;
        state.deleting.status = "succeeded";
      })
      .addCase(deleteTechnology.rejected, (state, action) => {
        state.deleting.status = "failed";
        state.deleting.error.status = action.payload.response.status;
        state.deleting.error.status_text = action.payload.response.statusText;
        state.deleting.error.detail =
          action.payload.response.data.detail || action.payload.response.data;
      });
  },
});

/* --- Technologies ------------------------------------------------------------------- */
export const getActiveTechnology = (state) =>
  state.technologies.activeTechnology;
export const selectAllTechnologies = (state) => state.technologies.technologies;

/* --- Technology Dialog ------------------------------------------------------------- */
export const getTechnologyDialogState = (state) =>
  state.technologies.technologyDialog;

/* --- Technology Functions ---------------------------------------------------------- */
export const getTechnologiesDownloadState = (state) =>
  state.technologies.downloading;
export const getTechnologyUploadState = (state) => state.technologies.uploading;
export const getTechnologyDeleteState = (state) => state.technologies.deleting;

/* --- REDUCERS --------------------------------------- */
export const {
  setActiveTechnology,
  resetActiveTechnology,
  startAddingTechnology,
  startEditingTechnology,
  closeTechnologyDialog,
  setTechnologyActiveTab,
  startDeletingTechnology,
  closeDeletingDialog,
  closeLoadingErrorDialog,
} = technologiesSlice.actions;

export default technologiesSlice.reducer;
