import {
  getConformanceParts,
  getNonconformanceParts,
} from "./orderStepAnalytics";

export const isReadyToSave = (order, OKcounter, NOKcounter) => {
  const OKdb = getConformanceParts(order);
  const NOKdb = getNonconformanceParts(order);

  if (OKdb !== OKcounter || NOKdb !== NOKcounter) {
    return true;
  } else {
    return false;
  }
};
