import { useSelector, useDispatch } from 'react-redux';
import {
    createProduct,
    updateProduct,
    resetActiveProduct,
    closeLoadingErrorDialog,
    getActiveProduct,
    getProductDialogState,
    getProductUploadState,
} from '../../store/slices/productsSlice';
import {
    getAllTechnologies,
    selectAllTechnologies,
    getTechnologiesDownloadState
} from '../../store/slices/technologiesSlice';
import { useTranslation } from 'react-i18next';
import settings from '../../config.json';
import useMediaQuery from '../../hooks/useMediaQuery';
import { Autocomplete, CircularProgress, Grid, TextField } from '@mui/material';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import SubmitResetButtonGroup from '../../components/buttons/SubmitResetButtonGroup';
import CreatedUpdatedField from '../../components/form/CreatedUpdatedField';
import ErrorLoadingDataDialog from '../../components/dialogs/ErrorLoadingDataDialog';;


const productSchema = yup.object().shape({
    product_name: yup
        .string()
        .required('form.error.field_required'),
    product_number: yup
        .string()
        .required('form.error.field_required'),
    technology: yup
        .object()
        .required('form.error.field_required'),
    drawing_number: yup
        .string(),
    description: yup
        .string()
});


function FormProduct() {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const isAboveMobileSize = useMediaQuery(settings.NON_MOBILE_MEDIA_QUERY);

    const activeProduct = useSelector(getActiveProduct);
    const productDialogState = useSelector(getProductDialogState);
    const uploadState = useSelector(getProductUploadState);

    const technologies = useSelector(selectAllTechnologies)
    const technologiesDownloadState = useSelector(getTechnologiesDownloadState)

    const gridTextFieldStyle = { height: '66px', px: '15px' };
    const gridMultilineFieldStyle = { height: '95px', px: '15px' };
    const gridCreatedUpdatedStyle = { px: '15px' };

    /* --------- GET ---------------------------------------------------------------------*/

    const fetchTechnologies = () => {
        dispatch(getAllTechnologies());
    }

    /* ----- CREATE / UPDATE -------------------------------------------------------------*/

    const handleFormSubmit = async (values) => {

        const product = {
            product_id: activeProduct.product_id,
            product_name: values.product_name,
            product_number: values.product_number,
            drawing_number: values.drawing_number,
            description: values.description,
            technology: values.technology.technology_id,
        };

        if (productDialogState.mode === 'add') {
            dispatch(createProduct(product));
        };
        if (productDialogState.mode === 'edit') {
            dispatch(updateProduct(product));
        };
    };

    /* ----- RESET -----------------------------------------------------------------------*/

    // Pressing "ADD NEXT" button is not clearing inputs - expected behavior
    // Manual Reset added for clearing inputs in 'add' mode
    // 'edit' mode is using 'Formik' builded-in functionality

    const handleResetClick = () => {
        if (productDialogState.mode === 'add') {
            dispatch(resetActiveProduct());
        };
    };

    /* -----------------------------------------------------------------------------------*/


    return (
        <>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={activeProduct}
                validationSchema={productSchema}
                enableReinitialize
            >
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                    <Form>

                        <Grid
                            container
                            height={isAboveMobileSize ? '360px' : '360px'} // set height order: --- 3/3 ---
                            p={isAboveMobileSize ? '6px 0 0 0' : '6px 0 12px 0'}
                            overflow='auto'
                        >
                            <Grid
                                item
                                xs={12}
                                sx={gridTextFieldStyle}
                            >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    type='text'
                                    size="small"
                                    label={t('label.product_name')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.product_name || ''}
                                    name='product_name'
                                    error={!!touched.product_name && !!errors.product_name}
                                    helperText={touched.product_name && <>{t(errors.product_name)}</>}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={gridTextFieldStyle}
                            >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    type='text'
                                    size="small"
                                    label={t('label.product_number')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.product_number || ''}
                                    name='product_number'
                                    error={!!touched.product_number && !!errors.product_number}
                                    helperText={touched.product_number && <>{t(errors.product_number)}</>}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={gridTextFieldStyle}
                            >

                                <Autocomplete
                                    onOpen={fetchTechnologies}
                                    options={technologies}
                                    onChange={(e, value) => {
                                        if (value) {
                                            handleChange({
                                                ...e, target: {
                                                    ...e.target,
                                                    name: "technology",
                                                    value: {
                                                        technology_id: value.technology_id,
                                                        technology_name: value.technology_name,
                                                        technology_number: value.technology_number,
                                                    },
                                                },
                                            });
                                        } else {
                                            handleChange({
                                                ...e, target: {
                                                    ...e.target,
                                                    name: "technology",
                                                    value: ""
                                                },
                                            });
                                        }
                                    }}
                                    value={values.technology.technology_id !== undefined ? values.technology : null}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            fullWidth
                                            variant='outlined'
                                            type='text'
                                            size="small"
                                            label={t('label.technology')}
                                            name='technology'
                                            onBlur={handleBlur}
                                            error={!!touched.technology && !!errors.technology}
                                            helperText={touched.technology && <>{t(errors.technology)}</>}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {technologiesDownloadState.status === 'loading'
                                                            ? <CircularProgress color='secondary' size={20} />
                                                            : null}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                ),
                                            }}
                                        />
                                    }
                                    getOptionLabel={(option) => option.technology_name + " | " + option.technology_number}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={gridTextFieldStyle}
                            >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    type='text'
                                    size="small"
                                    label={t('label.drawing_number')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.drawing_number || ''}
                                    name='drawing_number'
                                    error={!!touched.drawing_number && !!errors.drawing_number}
                                    helperText={touched.drawing_number && <>{t(errors.drawing_number)}</>}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={gridMultilineFieldStyle}
                            >
                                <TextField
                                    multiline
                                    rows={3}
                                    fullWidth
                                    variant='outlined'
                                    type='text'
                                    size="small"
                                    label={t('label.description')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.description || ''}
                                    name='description'
                                    error={!!touched.description && !!errors.description}
                                    helperText={touched.description && <>{t(errors.description)}</>}
                                />
                            </Grid>
                            {productDialogState.mode === 'edit' ?
                                <Grid
                                    item
                                    xs={12}
                                //mt='-4px'
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sx={gridCreatedUpdatedStyle}
                                    >
                                        <CreatedUpdatedField
                                            label={t('label.created')}
                                            date={activeProduct.created}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={gridCreatedUpdatedStyle}
                                    >
                                        <CreatedUpdatedField
                                            label={t('label.updated')}
                                            date={activeProduct.updated}
                                        />
                                    </Grid>
                                </Grid>
                                :
                                null
                            }
                        </Grid>

                        <SubmitResetButtonGroup
                            onSubmit={handleSubmit}
                            onReset={handleResetClick}
                            labelSubmit={productDialogState.mode === 'add' ? t('button.submit') : t('button.edit')}
                            labelReset={productDialogState.mode === 'add' ? t('button.clear') : t('button.reset')}
                            loading={uploadState.status === 'loading'}
                        />

                    </Form>
                )}
            </Formik>

            <ErrorLoadingDataDialog
                open={uploadState.status === 'failed'}
                error={uploadState.error}
                onClose={() => dispatch(closeLoadingErrorDialog())}
            />
        </>
    );
};

export default FormProduct;