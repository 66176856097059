import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";
import { notifySuccess } from "./snackbarSlice";
import { formatNoResponseError } from "../storeUtils";

const initialState = {
  uploading: {
    status: "idle", //  'idle' | 'loading' | 'succeeded' | 'failed'
    error: { status: null, status_text: null, detail: null },
  },
};

export const updateAccount = createAsyncThunk(
  "accounts/updateAccount",
  async (account, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/accounts/${account.account_id}`,
        account
      );
      dispatch(notifySuccess());
      return response.data;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const accountSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    /* --- Information dialogs ---------------------------- */
    closeLoadingErrorDialog: (state) => {
      state.uploading.status = "idle";
    },
  },
  extraReducers(builder) {
    builder
      /* --- UPDATE ----------------------------------------- */
      .addCase(updateAccount.pending, (state) => {
        state.uploading.status = "loading";
      })
      .addCase(updateAccount.fulfilled, (state) => {
        state.uploading.status = "succeeded";
      })
      .addCase(updateAccount.rejected, (state, action) => {
        state.uploading.status = "failed";
        state.uploading.error.status = action.payload.response.status;
        state.uploading.error.status_text = action.payload.response.statusText;
        state.uploading.error.detail =
          action.payload.response.data.detail || action.payload.response.data;
      });
  },
});

/* --- Account Functions --------------------------------------------------------- */
export const getAccountUploadState = (state) => state.account.uploading;

/* --- REDUCERS --------------------------------------- */
export const { closeLoadingErrorDialog } = accountSlice.actions;

export default accountSlice.reducer;
