import { useSelector } from 'react-redux';
import { getLoggedAccount } from '../../store/slices/loginSlice';
import { Typography, useTheme } from '@mui/material';
import { tokens } from '../../theme';


function LoggedAccount() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const loggedAccount = useSelector(getLoggedAccount) || "";

    return (
        <Typography color={colors.grey[100]}>
            {loggedAccount.account_name}
        </Typography>
    );
};

export default LoggedAccount;