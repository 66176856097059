import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    fetchAllProducts,
    setActiveProduct,
    startEditingProduct,
    startDeletingProduct,
    selectAllProducts,
    getProductsDownloadState,
} from '../../store/slices/productsSlice';
import { getTimezone } from '../../store/slices/loginSlice';
import { useTranslation } from 'react-i18next';
import usePermission from '../../hooks/usePermission';
import { Box, LinearProgress } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import DataGridStyle from '../../styles/DataGridStyle';
import TableActionAnalytics from '../../components/table/TableActionAnalytics';
import TableActionsMenu from '../../components/table/TableActionsMenu';
import TableActionEdit from '../../components/table/TableActionEdit';
import TableActionDelete from '../../components/table/TableActionDelete';
import NoRowsOverlay from '../../assets/overlays/NoRowsOverlay';
import NoPermissionsOverlay from '../../assets/overlays/NoPermissionsOverlay';
import moment from 'moment-timezone';


function GridProducts() {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isMounted = useRef(false);
    const navigate = useNavigate();

    const writeAccess = usePermission(['products_write']);
    const readAccess = usePermission(['products_read']);

    const rows = useSelector(selectAllProducts);
    const downloadState = useSelector(getProductsDownloadState);
    const timezone = useSelector(getTimezone);

    const [anchorEl, setAnchorEl] = useState(null);


    const columns = [
        {
            field: 'product_id',
            headerName: '#',
            width: 40,
            sortable: false,
            filterable: false,
            hideable: false,
            columnMenu: false,
            disableColumnMenu: true,
            valueGetter: (params) => params.api.getRowIndexRelativeToVisibleRows(params.row.product_id) + 1,
        },
        {
            field: 'product_name',
            headerName: t('label.product_name'),
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'product_number',
            headerName: t('label.product_number'),
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'technology',
            headerName: t('label.technology'),
            minWidth: 150,
            flex: 1,
            valueGetter: (params) => params.row.technology.technology_name + " | " + params.row.technology.technology_number
        },
        {
            field: 'drawing_number',
            headerName: t('label.drawing_number'),
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'description',
            headerName: t('label.description'),
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'created',
            headerName: t('label.created'),
            minWidth: 150,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => moment.utc(params.row.created).tz(timezone).format('DD-MM-YYYY, HH:mm')
        },
        {
            field: 'updated',
            headerName: t('label.updated'),
            minWidth: 150,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => moment.utc(params.row.updated).tz(timezone).format('DD-MM-YYYY, HH:mm')
        },
        {
            field: 'actions',
            headerName: t('label.actions'),
            minWidth: 150,
            sortable: false,
            filterable: false,
            hideable: false,
            headerAlign: 'center',
            align: 'center',
            disableColumnMenu: true,
            renderCell: (params) => (
                <Box
                    display='flex'
                    gap='10px'
                >
                    <TableActionAnalytics
                        onClick={() => handleAnalyticsClick(params)}
                    />
                    <TableActionsMenu
                        onClick={handleMenuClick}
                        permission={writeAccess}
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}

                    >
                        <TableActionEdit onClick={handleEditClick} />
                        <TableActionDelete onClick={handleDeleteClick} />
                    </TableActionsMenu>
                </Box>
            )
        },
    ];

    /* --------- GET ---------------------------------------------------------------------*/

    useEffect(() => {
        if (isMounted.current) return;
        /* eslint-disable */
        dispatch(fetchAllProducts());

        isMounted.current = true;
    }, []);

    /* -----------------------------------------------------------------------------------*/

    const handleRowClick = (params) => {
        dispatch(setActiveProduct(params.row));
    };

    const handleAnalyticsClick = (params) => {
        navigate(`/products/${params.row.product_id}/analytics`);
    };

    const handleMenuClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleEditClick = () => {
        dispatch(startEditingProduct());
        setAnchorEl(null);
    };

    const handleDeleteClick = () => {
        dispatch(startDeletingProduct());
        setAnchorEl(null);
    };


    return (
        <DataGridStyle>
            <DataGrid
                rows={rows}
                columns={columns}
                getRowId={(row) => row.product_id}
                loading={downloadState.status === 'loading'}
                onRowClick={(params) => handleRowClick(params)}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            description: false,
                            created: false,
                            updated: false,
                        },
                    },
                    sorting: { sortModel: [{ field: 'product_name', sort: 'asc' }] },
                    pagination: { paginationModel: { pageSize: 100 } },
                    filter: {
                        filterModel: {
                            items: [],
                            quickFilterExcludeHiddenColumns: true,
                        },
                    },
                }}
                slots={{
                    toolbar: GridToolbar,
                    loadingOverlay: LinearProgress,
                    noRowsOverlay: readAccess ? NoRowsOverlay : NoPermissionsOverlay,
                }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
            />
        </DataGridStyle>
    );
};

export default GridProducts;