import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    fetchPlace,
    getPlace
} from '../../store/slices/placesSlice';
import {
    getPlacesTimeline,
    selectTimelineData,
    getTimelineDownloadState
} from '../../store/slices/placesAnalyticsSlice';
import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import TimelineComponent from '../../components/analytics/timelineComponent/TimelineComponent';
import TimelineLegendElement from '../../components/analytics/timelineComponent/TimelineLegendElement';
import CircularProgressBasic from '../../components/progress/CircularProgressBasic';


function PagePlaceAnalytics() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isMounted = useRef(false);
    const { id } = useParams();

    const backendData = useSelector(selectTimelineData);
    const downloadState = useSelector(getTimelineDownloadState);
    //const place = useSelector((state) => selectPlaceById(state, id));
    const place = useSelector(getPlace);

    /* --------- GET ---------------------------------------------------------------------*/

    useEffect(() => {
        if (isMounted.current) return;
        /* eslint-disable */
        dispatch(getPlacesTimeline(
            {
                place_id: id,
            }
        ));

        if (!place) {
            dispatch(fetchPlace(id));
        };

        isMounted.current = true;
    }, []);

    /* -----------------------------------------------------------------------------------*/

    const legend = [
        {
            label: t('charts.legend.process'),
            color: colors.greenAccent[500],
        },
        {
            label: t('charts.legend.setup'),
            color: colors.greenAccent[800],
        },
        {
            label: t('charts.legend.scheduled'),
            color: colors.yellowAccent[700],
        },
        {
            label: t('charts.legend.blocker'),
            color: colors.redAccent[500],
        },
        {
            label: t('charts.legend.now'),
            color: colors.violetAccent[500],
        }
    ];


    const getWeekday = (moment) => {

        const wd = moment.format("dddd");

        if (wd === "Monday") {
            return t("time.weekday.monday");
        } else if (wd === "Tuesday") {
            return t("time.weekday.tuesday");
        } else if (wd === "Wednesday") {
            return t("time.weekday.wednesday");
        } else if (wd === "Thursday") {
            return t("time.weekday.thursday");
        } else if (wd === "Friday") {
            return t("time.weekday.friday");
        } else if (wd === "Saturday") {
            return t("time.weekday.saturday");
        } else if (wd === "Sunday") {
            return t("time.weekday.sunday");
        } else {
            return "";
        }
    };


    return (
        <>
            <Typography
                variant='h4'
                color={colors.assigned.tableHeader}
            >
                {place ? `${place?.place_number} - ${place?.place_name}` : "loading..."}
            </Typography>
            {downloadState.status === 'loading' ? <CircularProgressBasic /> : null}
            {downloadState.status === 'succeeded' ?
                <>
                    <TimelineComponent
                        data={backendData}
                        startTime={moment().subtract(6, "days").startOf('day')}
                        endTime={moment().subtract(6, "days").endOf('day')}
                        label={
                            `${moment().subtract(6, "days").format("DD-MM-YYYY")} 
                    (${getWeekday(moment().subtract(6, "days"))})`
                        }
                        marker={false}
                    />
                    <TimelineComponent
                        data={backendData}
                        startTime={moment().subtract(5, "days").startOf('day')}
                        endTime={moment().subtract(5, "days").endOf('day')}
                        label={
                            `${moment().subtract(5, "days").format("DD-MM-YYYY")} 
                    (${getWeekday(moment().subtract(5, "days"))})`
                        }
                        marker={false}
                    />
                    <TimelineComponent
                        data={backendData}
                        startTime={moment().subtract(4, "days").startOf('day')}
                        endTime={moment().subtract(4, "days").endOf('day')}
                        label={
                            `${moment().subtract(4, "days").format("DD-MM-YYYY")} 
                    (${getWeekday(moment().subtract(4, "days"))})`
                        }
                        marker={false}
                    />
                    <TimelineComponent
                        data={backendData}
                        startTime={moment().subtract(3, "days").startOf('day')}
                        endTime={moment().subtract(3, "days").endOf('day')}
                        label={
                            `${moment().subtract(3, "days").format("DD-MM-YYYY")} 
                    (${getWeekday(moment().subtract(3, "days"))})`
                        }
                        marker={false}
                    />
                    <TimelineComponent
                        data={backendData}
                        startTime={moment().subtract(2, "days").startOf('day')}
                        endTime={moment().subtract(2, "days").endOf('day')}
                        label={
                            `${moment().subtract(2, "days").format("DD-MM-YYYY")} 
                    (${getWeekday(moment().subtract(2, "days"))})`
                        }
                        marker={false}
                    />
                    <TimelineComponent
                        data={backendData}
                        startTime={moment().subtract(1, "days").startOf('day')}
                        endTime={moment().subtract(1, "days").endOf('day')}
                        label={
                            `${moment().subtract(1, "days").format("DD-MM-YYYY")} 
                    (${getWeekday(moment().subtract(1, "days"))})`
                        }
                        marker={false}
                    />
                    <TimelineComponent
                        data={backendData}
                        startTime={moment().startOf('day')}
                        endTime={moment().endOf('second')}
                        label={moment().format("DD-MM-YYYY")}
                        marker={true}
                    />
                    <Box
                        display='flex'
                        justifyContent='center'
                        gap='20px'
                        m='30px'
                    >
                        {legend.map((action, index) => {
                            return (
                                <TimelineLegendElement
                                    key={index}
                                    label={action.label}
                                    color={action.color}
                                />
                            )
                        })}
                    </Box>
                </>
                : null}
        </>
    );
};

export default PagePlaceAnalytics;