import { useSelector } from 'react-redux';
import { getActivePlace } from '../../../store/slices/impersonateSlice';
import { Box } from '@mui/material';
import StatusIdle from '../statuses/StatusIdle';
import StatusOrder from '../statuses/StatusOrder';


function TileStatus() {

    const activePlace = useSelector(getActivePlace)
    const status = activePlace.status

    return (
        <>
            {!status ? <Box width='100%' height='100%' /> : null}
            {status === 'idle' ? <StatusIdle /> : null}
            {status === 'order' ? <StatusOrder /> : null}
        </>
    );
};

export default TileStatus;