import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getPlaceOrders,
    getActivePlace,
    getBacklog,
    getBacklogDownloadState
} from '../../../store/slices/impersonateSlice';
import { Box, /*useTheme*/ } from '@mui/material';
// import { tokens } from '../../../theme';
import BacklogElement from '../backlog/BacklogElement';
import CircularProgressBasic from '../../progress/CircularProgressBasic';
import LoadingError from '../../notifications/LoadingError';
import NoRowsOverlay from '../../../assets/overlays/NoRowsOverlay';


function ViewBacklog() {

    const dispatch = useDispatch();
    const isMounted = useRef(false);

    // const theme = useTheme();
    // const colors = tokens(theme.palette.mode);

    const activePlace = useSelector(getActivePlace);
    const backlog = useSelector(getBacklog);
    const downloadState = useSelector(getBacklogDownloadState);

    /* --------- GET ---------------------------------------------------------------------*/

    useEffect(() => {
        if (isMounted.current) return;
        if (activePlace.place_id) {
            /* eslint-disable */
            dispatch(getPlaceOrders(activePlace.place_id));
        };
        isMounted.current = true;
    }, [activePlace.place_id]);

    /* -----------------------------------------------------------------------------------*/

    return (
        <Box
            width='100%'
            height='460px'
            overflow='auto'
        // border='1px solid'
        // borderColor={colors.primary[400]}
        // borderRadius='4px'
        // p='10px'
        >
            {downloadState.status === 'loading' ? (<CircularProgressBasic />) : null}
            {downloadState.status === 'failed' ? (<LoadingError error={downloadState.error.detail} />) : null}
            {downloadState.status === 'succeeded' && !backlog.length ? (<NoRowsOverlay />) : null}
            {downloadState.status === 'succeeded' && backlog ? (
                backlog?.map((order, index) => (
                    <BacklogElement
                        key={index}
                        index={index}
                        order={order}
                    />
                ))
            ) : null}
        </Box>
    );
};

export default ViewBacklog;