import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    updatePlaceAction,
    closeLoadingErrorDialog,
    getActivePlace,
    getPlaceTileDownloadState,
    getActionUploadState
} from '../../../store/slices/impersonateSlice';
import {
    createActionTimestamp,
    closeTimestampLoadingErrorDialog,
    getActionTimestampUploadState
} from '../../../store/slices/timestampsSlice';
import { useTranslation } from 'react-i18next';
import usePermission from '../../../hooks/usePermission';
import { Box, Menu, useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import ButtonMenu from './ButtonMenu';
import ButtonMenuOption from './ButtonMenuOption';
import ErrorLoadingDataDialog from '../../dialogs/ErrorLoadingDataDialog';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import StopOutlinedIcon from '@mui/icons-material/StopOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';


function MenuAction() {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const writeAccess = usePermission(['impersonate_write'])

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [anchor, setAnchor] = useState(null);
    const open = Boolean(anchor);

    const activePlace = useSelector(getActivePlace);
    const placeAction = activePlace?.action || "stop";
    const placeStatus = activePlace?.status || "idle";
    const downloadState = useSelector(getPlaceTileDownloadState);
    const downloadStatus = downloadState.status;
    const actionUploadState = useSelector(getActionUploadState);
    const actionUploadStatus = actionUploadState.status;
    const timestampUploadState = useSelector(getActionTimestampUploadState);
    const timestampUploadStatus = timestampUploadState.status;


    const buttons = [
        {
            label: t('button.device.process'),
            status: 'process',
            variant: 'outlined',
            color: 'success',
            icon: <PlayArrowOutlinedIcon sx={{ fontSize: '40px' }} />,
        },
        {
            label: t('button.device.stop'),
            status: 'stop',
            variant: 'outlined',
            color: 'error',
            icon: <StopOutlinedIcon sx={{ fontSize: '40px' }} />,
        },
        {
            label: t('button.device.setup'),
            status: 'setup',
            variant: 'outlined',
            color: 'success',
            icon: <EngineeringOutlinedIcon sx={{ fontSize: '40px' }} />,
        },
        {
            label: t('button.device.scheduled'),
            status: 'scheduled',
            variant: 'outlined',
            color: 'warning',
            icon: <BuildCircleOutlinedIcon sx={{ fontSize: '40px' }} />,
        },
        {
            label: t('button.device.blocker'),
            status: 'blocker',
            variant: 'contained',
            color: 'error',
            icon: <PriorityHighOutlinedIcon sx={{ fontSize: '40px' }} />,
        }
    ];


    const handleClick = (newStatus) => {

        const place = {
            place_id: activePlace.place_id,
            action: newStatus
        };

        const timestamp = {
            place_id: activePlace.place_id,
            action: newStatus,
        };

        dispatch(updatePlaceAction(place));
        dispatch(createActionTimestamp(timestamp));
        setAnchor(null);
    };


    return (
        <>
            {buttons.filter(button => button.status === placeAction).map((button, id) =>
                <ButtonMenu
                    key={id}
                    label={button.label}
                    variant={button.variant}
                    color={button.color}
                    icon={button.icon}
                    setAnchor={setAnchor}
                    loading={downloadStatus === 'loading' || !activePlace.action || actionUploadStatus === 'loading' || timestampUploadStatus === 'loading'}
                    disabled={!writeAccess}
                />
            )}

            <Menu
                anchorEl={anchor}
                open={open}
                onClose={() => setAnchor(null)}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                sx={{
                    '& .MuiList-root': {
                        backgroundColor: colors.primary[500],
                        padding: '0px'
                    },
                }}
            >
                {placeStatus === 'idle' ?
                    <Box display='flex' flexDirection='row-reverse' gap='10px' p='10px'>
                        {buttons.filter(button =>
                            button.status !== placeAction &&
                            button.status !== 'process' &&
                            button.status !== 'setup'
                        ).map((button, id) =>
                            <ButtonMenuOption
                                key={id}
                                label={button.label}
                                variant={button.variant}
                                color={button.color}
                                icon={button.icon}
                                onClick={() => handleClick(button.status)}
                            />
                        )}
                    </Box> : null}

                {placeStatus !== 'idle' ?
                    <Box display='flex' flexDirection='row-reverse' gap='10px' p='10px'>
                        {buttons.filter(button => button.status !== placeAction).map((button, id) =>
                            <ButtonMenuOption
                                key={id}
                                label={button.label}
                                variant={button.variant}
                                color={button.color}
                                icon={button.icon}
                                onClick={() => handleClick(button.status)}
                            />
                        )}
                    </Box> : null}

            </Menu>

            <ErrorLoadingDataDialog
                open={actionUploadState.status === 'failed'}
                error={actionUploadState.error}
                onClose={() => dispatch(closeLoadingErrorDialog())}
            />

            <ErrorLoadingDataDialog
                open={timestampUploadState.status === 'failed'}
                error={timestampUploadState.error}
                onClose={() => dispatch(closeTimestampLoadingErrorDialog())}
            />
        </>
    );
};

export default MenuAction;