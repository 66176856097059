import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Box, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useAuth } from '../../hooks/useAuth';
import PasswordVisibility from '../../components/switches/PasswordVisibility';


const loginSchema = yup.object().shape({
    email: yup
        .string()
        .email('form.error.email_invalid_format')
        .required('form.error.email_required'),
    password: yup
        .string()
        .required('form.error.password_required')
});

const emptyValues = {
    email: '',
    password: ''
};


function FormLogin() {

    const { t } = useTranslation();
    const { login } = useAuth();

    const [loading, setLoading] = useState(false);
    const [isAlert, setIsAlert] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState({ status: '', detail: '' });


    const handleFormSubmit = async (values) => {

        try {
            setLoading(true);
            await login(values.email, values.password);
            setLoading(false);
        } catch (error) {
            setIsAlert(true);
            setLoading(false);
            setError({
                status: error.response.status,
                detail: error.response.data.detail
            });
        };
    };


    return (
        <Box>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={emptyValues}
                validationSchema={loginSchema}
                enableReinitialize
            >
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                    <Form>
                        <Box
                            display='flex'
                            flexDirection='column'
                            minWidth='300px'
                            gap='30px'
                            my='30px'
                        >
                            <TextField
                                fullWidth
                                variant='outlined'
                                type='text'
                                size="small"
                                label={t('label.email')}
                                autoComplete='off'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email || ''}
                                name='email'
                                error={!!touched.email && !!errors.email}
                                helperText={touched.email && <>{t(errors.email)}</>}
                            />
                            <TextField
                                fullWidth
                                variant='outlined'
                                type={showPassword ? 'text' : 'password'}
                                size="small"
                                label={t('label.password')}
                                autoComplete='off'
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.password || ''}
                                name='password'
                                error={!!touched.password && !!errors.password}
                                helperText={touched.password && <>{t(errors.password)}</>}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <PasswordVisibility
                                                showPassword={showPassword}
                                                onClick={() => setShowPassword(!showPassword)}
                                            />
                                        </InputAdornment>
                                }}
                            />
                        </Box>
                        {isAlert ? (
                            <Box mb='20px' mt='-10px'>
                                <Alert
                                    variant="outlined"
                                    severity="error"
                                    onClose={() => { setIsAlert(false) }}
                                >
                                    {error.detail}
                                </Alert>
                            </Box>
                        ) : null}
                        <LoadingButton
                            fullWidth
                            type='submit'
                            variant="contained"
                            color='primary'
                            size='large'
                            loading={loading}
                            onClick={handleSubmit}
                        >
                            <span>{t('button.login')}</span>    {/* span tags due to muiLab error */}
                        </LoadingButton>
                    </Form>
                )}
            </Formik>
        </Box>
    );

};

export default FormLogin;
