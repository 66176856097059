import { Box, useTheme } from '@mui/material';
import { tokens } from '../../../theme';

function TileInfo() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            width='100%'
            height='100%'
            display='flex'
            alignItems='center'
            border='1px solid'
            borderColor={colors.primary[400]}
            borderRadius='4px'
            p='10px'
        >
            Info Field
        </Box>
    );
};

export default TileInfo;