import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    fetchAllPlaces,
    setActivePlace,
    startEditingPlace,
    startDeletingPlace,
    selectAllPlaces,
    getPlacesDownloadState,
} from '../../store/slices/placesSlice';
import { getTimezone } from '../../store/slices/loginSlice';
import { useTranslation } from 'react-i18next';
import usePermission from '../../hooks/usePermission';
import { Box, LinearProgress } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import DataGridStyle from '../../styles/DataGridStyle';
import TableActionAnalytics from '../../components/table/TableActionAnalytics';
import TableActionsMenu from '../../components/table/TableActionsMenu';
import TableActionEdit from '../../components/table/TableActionEdit';
import TableActionDelete from '../../components/table/TableActionDelete';
import NoRowsOverlay from '../../assets/overlays/NoRowsOverlay';
import NoPermissionsOverlay from '../../assets/overlays/NoPermissionsOverlay';
import BlockerChip from '../../components/chips/BlockerChip';
import IdleChip from '../../components/chips/IdleChip';
import MaintenanceChip from '../../components/chips/MaintenanceChip';
import OrderChip from '../../components/chips/OrderChip';
import ProcessChip from '../../components/chips/ProcessChip';
import QualityChip from '../../components/chips/QualityChip';
import ScheduledChip from '../../components/chips/ScheduledChip';
import SetupChip from '../../components/chips/SetupChip';
import StopChip from '../../components/chips/StopChip';
import SupervisorChip from '../../components/chips/SupervisorChip';
import TechnologyChip from '../../components/chips/TechnologyChip';
import moment from 'moment-timezone';


function GridPlaces() {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isMounted = useRef(false);
    const navigate = useNavigate();

    const writeAccess = usePermission(['places_write'])
    const readAccess = usePermission(['places_read'])

    const rows = useSelector(selectAllPlaces);
    const downloadState = useSelector(getPlacesDownloadState);
    const timezone = useSelector(getTimezone)

    const [anchorEl, setAnchorEl] = useState(null);


    const columns = [
        {
            field: 'place_id',
            headerName: '#',
            width: 40,
            sortable: false,
            filterable: false,
            hideable: false,
            columnMenu: false,
            disableColumnMenu: true,
            valueGetter: (params) => params.api.getRowIndexRelativeToVisibleRows(params.row.place_id) + 1,
        },
        {
            field: 'place_name',
            headerName: t('label.place_name'),
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'place_number',
            headerName: t('label.place_number'),
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'process',
            headerName: t('label.process'),
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'status',
            headerName: t('label.status'),
            minWidth: 150,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                if (params.row.status === 'idle') {
                    return t('chip.idle');
                } else if (params.row.status === 'order') {
                    return t('chip.order');
                };
            },
            renderCell: (params) => {
                if (params.row.status === 'idle') {
                    return <IdleChip />;
                } else if (params.row.status === 'order') {
                    return <OrderChip />;
                };
            },
        },
        {
            field: 'action',
            headerName: t('label.action'),
            minWidth: 150,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                if (params.row.action === 'stop') {
                    return t('chip.stop');
                } else if (params.row.action === 'process') {
                    return t('chip.process');
                } else if (params.row.action === 'setup') {
                    return t('chip.setup');
                } else if (params.row.action === 'scheduled') {
                    return t('chip.scheduled');
                } else if (params.row.action === 'blocker') {
                    return t('chip.blocker');
                };
            },
            renderCell: (params) => {
                if (params.row.action === 'stop') {
                    return <StopChip />;
                } else if (params.row.action === 'process') {
                    return <ProcessChip />;
                } else if (params.row.action === 'setup') {
                    return <SetupChip />;
                } else if (params.row.action === 'scheduled') {
                    return <ScheduledChip />;
                } else if (params.row.action === 'blocker') {
                    return <BlockerChip />;
                };
            },
        },
        {
            field: 'support',
            headerName: t('label.support'),
            minWidth: 150,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => {
                if (params.row.support === 'supervisor') {
                    return t('chip.supervisor');
                } else if (params.row.support === 'technology') {
                    return t('chip.technology');
                } else if (params.row.support === 'quality') {
                    return t('chip.quality');
                } else if (params.row.support === 'maintenance') {
                    return t('chip.maintenance');
                };
            },
            renderCell: (params) => {
                if (params.row.support === 'supervisor') {
                    return <SupervisorChip />;
                } else if (params.row.support === 'technology') {
                    return <TechnologyChip />;
                } else if (params.row.support === 'quality') {
                    return <QualityChip />;
                } else if (params.row.support === 'maintenance') {
                    return <MaintenanceChip />;
                };
            },
        },
        {
            field: 'description',
            headerName: t('label.description'),
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'created',
            headerName: t('label.created'),
            minWidth: 150,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => moment.utc(params.row.created).tz(timezone).format('DD-MM-YYYY, HH:mm')
        },
        {
            field: 'updated',
            headerName: t('label.updated'),
            minWidth: 150,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) => moment.utc(params.row.updated).tz(timezone).format('DD-MM-YYYY, HH:mm')
        },
        {
            field: 'actions',
            headerName: t('label.actions'),
            minWidth: 150,
            sortable: false,
            filterable: false,
            hideable: false,
            headerAlign: 'center',
            align: 'center',
            disableColumnMenu: true,
            renderCell: (params) => (
                <Box
                    display='flex'
                    gap='10px'
                >
                    <TableActionAnalytics
                        onClick={() => handleAnalyticsClick(params)}
                    />
                    <TableActionsMenu
                        onClick={handleMenuClick}
                        permission={writeAccess}
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}

                    >
                        <TableActionEdit onClick={handleEditClick} />
                        <TableActionDelete onClick={handleDeleteClick} />
                    </TableActionsMenu>
                </Box>
            )
        },
    ];

    /* --------- GET ---------------------------------------------------------------------*/

    useEffect(() => {
        if (isMounted.current) return;
        /* eslint-disable */
        dispatch(fetchAllPlaces());

        isMounted.current = true;
    }, []);

    /* -----------------------------------------------------------------------------------*/

    const handleRowClick = (params) => {
        dispatch(setActivePlace(params.row));
    };

    const handleAnalyticsClick = (params) => {
        navigate(`/places/${params.row.place_id}/analytics`);
    };

    const handleMenuClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleEditClick = () => {
        dispatch(startEditingPlace());
        setAnchorEl(null);
    };

    const handleDeleteClick = () => {
        dispatch(startDeletingPlace());
        setAnchorEl(null);
    };


    return (
        <DataGridStyle>
            <DataGrid
                rows={rows}
                columns={columns}
                getRowId={(row) => row.place_id}
                loading={downloadState.status === 'loading'}
                onRowClick={(params) => handleRowClick(params)}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            description: false,
                            created: false,
                            updated: false,
                        },
                    },
                    sorting: { sortModel: [{ field: 'place_name', sort: 'asc' }] },
                    pagination: { paginationModel: { pageSize: 100 } },
                    filter: {
                        filterModel: {
                            items: [],
                            quickFilterExcludeHiddenColumns: true,
                        },
                    },
                }}
                slots={{
                    toolbar: GridToolbar,
                    loadingOverlay: LinearProgress,
                    noRowsOverlay: readAccess ? NoRowsOverlay : NoPermissionsOverlay,
                }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
            />
        </DataGridStyle>
    );
};

export default GridPlaces;