import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import {
    getConformanceParts,
    getNonconformanceParts,
    getQuantity,
    calculateProgress,
    calculateScrapRate
} from '../../../utils/orderStepAnalytics';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';
import DataUsageOutlinedIcon from '@mui/icons-material/DataUsageOutlined';
import DoneOutlineRoundedIcon from '@mui/icons-material/DoneOutlineRounded';
import FunctionsOutlinedIcon from '@mui/icons-material/FunctionsOutlined';
import TrendingDownOutlinedIcon from '@mui/icons-material/TrendingDownOutlined';


function InformationTiles(props) {

    const { order, loading } = props;

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { t } = useTranslation();

    const iconStyle = {
        color: colors.primary[400],
        fontSize: '30px',
        mt: '3px'
    };

    const tiles = [
        {
            label: t('label.device.conformance'),
            value: getConformanceParts(order),
            icon: <DoneOutlineRoundedIcon sx={iconStyle} />,
        },
        {
            label: t('label.device.quantity_ordered'),
            value: getQuantity(order),
            icon: <FunctionsOutlinedIcon sx={iconStyle} />,
        },
        {
            label: t('label.device.progress'),
            value: calculateProgress(order) + " %",
            icon: <DataUsageOutlinedIcon sx={iconStyle} />,
        },
        {
            label: t('label.device.nonconformance'),
            value: getNonconformanceParts(order),
            icon: <DangerousOutlinedIcon sx={iconStyle} />,
        },
        {
            label: t('label.device.scrap_rate'),
            value: calculateScrapRate(order) + " %",
            icon: <TrendingDownOutlinedIcon sx={iconStyle} />,
        },
    ];


    return (
        <Box
            height='100%'
            width='100%'
            display='grid'
            gridTemplateColumns='repeat(5, 1fr)'
            gap='10px'
        >
            {tiles.map((tile, id) =>
                <Box
                    key={id}
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    alignItems='center'
                    // border='1px solid'
                    // borderColor={colors.primary[400]}
                    // borderRadius='3px'
                    sx={{ overflow: 'hidden', whiteSpace: 'nowrap' }}
                >

                    <Box
                        height='100%'
                        display='flex'
                        alignItems='center'
                        gap='10px'
                    >
                        {!loading ? (
                            <>
                                {tile.icon}
                                <Typography sx={{ fontSize: '30px', color: colors.primary[300] }}>
                                    {tile.value}
                                </Typography>
                            </>
                        ) : (
                            <CircularProgress sx={{ color: colors.primary[400] }} size={20} />
                        )}
                    </Box>
                    <Typography sx={{ fontSize: '12px', color: colors.primary[300] }}>
                        {tile.label}
                    </Typography>
                </Box>
            )}
        </Box>
    )
}

export default InformationTiles