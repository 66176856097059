import { Box, Button, CircularProgress, Typography, useTheme } from "@mui/material";
import { tokens } from '../../../theme';


function Counter(props) {

    const { label, value, setValue, loading, color } = props;

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const buttonStyle = { height: '100%', fontSize: '30px' };


    const handleUpdateValue = (increment) => {

        const newValue = value + increment;

        if (newValue < 0) {
            setValue(0);
        } else {
            setValue(newValue);
        }
    };


    return (
        <Box
            width='100%'
            display='grid'
            gridTemplateColumns='1fr 1fr 2fr 1fr 1fr'
            justifyContent='center'
            alignItems='center'
            gap='10px'
        >
            <Button
                variant='contained'
                color='info'
                onClick={() => handleUpdateValue(-10)}
                sx={buttonStyle}
            >
                -10
            </Button>
            <Button
                variant='contained'
                color='info'
                onClick={() => handleUpdateValue(-1)}
                sx={buttonStyle}
            >
                -1
            </Button>
            <Box
                height='100%'
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                p='0px'
            >
                <Box
                    height='80%'
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                >
                    {!loading ? (
                        <Typography sx={{ fontSize: '50px', color: { color } }}>
                            {value}
                        </Typography>
                    ) : (
                        <CircularProgress sx={{ color: colors.primary[400] }} size={20} />
                    )}
                </Box>
                <Box
                    height='20%'
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                >
                    <Typography sx={{ fontSize: '12px', color: colors.primary[300], textAlign: 'center' }}>
                        {label}
                    </Typography>
                </Box>
            </Box >
            <Button
                variant='contained'
                color='info'
                onClick={() => handleUpdateValue(+1)}
                sx={buttonStyle}
            >
                +1
            </Button>
            <Button
                variant='contained'
                color='info'
                onClick={() => handleUpdateValue(+10)}
                sx={buttonStyle}
            >
                +10
            </Button>
        </Box >
    );
};

export default Counter;