import { useSelector, useDispatch } from 'react-redux'
import { updateOrderPriority, selectAllOrders, getOrdersDownloadState } from '../../../store/slices/ordersSlice';
import { Box } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ListOfOrdersHeader from './ListOfOrdersHeader';
import ListOfOrdersElement from './ListOfOrdersElement';
import CircularProgressBasic from '../../../components/progress/CircularProgressBasic'
import LoadingError from '../../../components/notifications/LoadingError';
import EmptyListNotification from '../../../components/notifications/EmptyListNotification';


function ListOfProductionOrders() {

    const dispatch = useDispatch();

    const orders = useSelector(selectAllOrders).filter(order => order.status !== "finished");
    const downloadState = useSelector(getOrdersDownloadState);

    /* -------- DRAG  --------------------------------------------------------------------*/

    const handleOnDragEnd = (result) => {
        if (!result.destination)
            return

        dispatch(updateOrderPriority(result))
    };
    /* -----------------------------------------------------------------------------------*/


    return (
        <Box
        //overflow='auto'
        >

            {/* HEADER */}
            <ListOfOrdersHeader
                title={'Production Orders'}
            />

            {/* LIST */}
            {downloadState.status === 'loading' ? (<CircularProgressBasic />) : null}
            {downloadState.status === 'failed' ? (<LoadingError error={downloadState.error.detail} />) : null}
            {downloadState.status === 'succeeded' && !orders.length ? (<EmptyListNotification />) : null}
            {downloadState.status === 'succeeded' && orders ? (
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId='orders'>
                        {(provided) => (
                            <section
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {orders?.map((order, index) => (
                                    <Draggable
                                        key={order.order_id}
                                        draggableId={order.order_id /*.toString()*/}
                                        index={index}
                                    >
                                        {(provided) => (
                                            <div
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                            >
                                                <ListOfOrdersElement
                                                    order={order}
                                                    index={index}
                                                    dragIcon={true}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </section>
                        )}
                    </Droppable>
                </DragDropContext>
            ) : null}
        </Box>
    );
};

export default ListOfProductionOrders;