import { Box, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import { useTranslation } from 'react-i18next';
import CircularProgressBasic from '../../components/progress/CircularProgressBasic';
import ChartDataError from '../../components/notifications/ChartDataError';
import { PieChart, Pie, Cell } from 'recharts';


function OrderStatusPieChart(props) {

    const { data, loadingState } = props;

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { t } = useTranslation();

    const color = colors.primary[400];
    const color2 = colors.primary[400];

    const chartData = [
        {
            label: t('status.backlog'),
            value: data.status_backlog,
            color: colors.blueAccent[400],
        },
        {
            label: t('status.on_hold'),
            value: data.status_on_hold,
            color: colors.yellowAccent[600],
        },
        {
            label: t('status.started'),
            value: data.status_started,
            color: colors.greenAccent[700],
        },
        {
            label: t('status.in_progress'),
            value: data.status_in_progress,
            color: colors.greenAccent[300],
        },
        {
            label: t('status.blocker'),
            value: data.status_blocker,
            color: colors.redAccent[400],
        },
    ]

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value, label, percent, index }) => {

        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        if (value !== 0) {
            return (
                <text x={x} y={y} fill={colors.grey[100]} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" fontSize={12}>
                    {`${label} (${value})`}
                </text>
            );
        } else {
            return null
        }
    };


    return (
        <Box
            height='100%'
            display='flex'
            sx={{ background: `linear-gradient(to right, ${color}, ${color2}20)` }}
        >

            {loadingState.status === 'loading' ? <CircularProgressBasic /> : null}
            {loadingState.status === 'failed' ? <ChartDataError error={loadingState.error} /> : null}
            {loadingState.status === 'succeeded' ?
                <Box 
                width="100%" 
                height='100%'
                display='flex'
                justifyContent='center'
                alignItems='center'
                >
                    <PieChart
                        width={250}
                        height={140} /*onMouseEnter={this.onPieEnter}*/
                    >
                        <Pie
                            data={chartData}
                            innerRadius={30}
                            outerRadius={60}
                            stroke='none'
                            //paddingAngle={3}
                            dataKey="value"
                            label={renderCustomizedLabel}
                            labelLine={false}
                        >
                            {chartData.map((slice, index) => (
                                <Cell key={index} fill={`${slice.color}90`} />
                            ))}
                        </Pie>
                    </PieChart>
                </Box>
                : null}
        </Box>
    );
};

export default OrderStatusPieChart;