import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    fetchAllPlaces,
    selectPlacesBySupport,
    getPlacesAnalyticsDownloadState
} from '../../store/slices/analyticsSlice';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import settings from '../../config.json'
import useMediaQuery from '../../hooks/useMediaQuery';
import TileSupport from './TileSupport';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';


function GroupSupport() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const dispatch = useDispatch();
    const isMounted = useRef(false);
    const { t } = useTranslation();
    const isAboveMediumScreens = useMediaQuery(settings.ABOVE_MEDIUM_MEDIA_QUERY);

    const supervisorData = useSelector((state) => selectPlacesBySupport(state, "supervisor"));
    const technologyData = useSelector((state) => selectPlacesBySupport(state, "technology"));
    const qualityData = useSelector((state) => selectPlacesBySupport(state, "quality"));
    const maintenanceData = useSelector((state) => selectPlacesBySupport(state, "maintenance"));
    const downloadState = useSelector(getPlacesAnalyticsDownloadState);
    const loading = downloadState.status;

    /* --------- GET ---------------------------------------------------------------------*/

    useEffect(() => {
        if (isMounted.current) return;
        /* eslint-disable */
        dispatch(fetchAllPlaces());

        isMounted.current = true;
    }, [supervisorData]);

    /* -----------------------------------------------------------------------------------*/

    const iconStyle = {
        //color: colors.primary[700],
        fontSize: '30px'
    };

    return (
        <Box
            height='100%'
            display='flex'
            flexDirection='column'
            p='10px'
        >
            <Typography
                variant='h6'
                //align='center'
                color={colors.primary[300]}
                mt='-8px'
            >
                {t('header.support')}
            </Typography>
            <Box
                height='100%'
                display='grid'
                gridTemplateColumns={isAboveMediumScreens ? '1fr 1fr 1fr 1fr' : null}
                gap='10px'
            >
                <TileSupport
                    title={t('chip.supervisor')}
                    data={supervisorData}
                    icon={<StarOutlineOutlinedIcon sx={iconStyle} />}
                    loading={loading === 'loading'}
                />
                <TileSupport
                    title={t('chip.technology')}
                    data={technologyData}
                    icon={<PersonOutlineOutlinedIcon sx={iconStyle} />}
                    loading={loading === 'loading'}
                />
                <TileSupport
                    title={t('chip.quality')}
                    data={qualityData}
                    icon={<PersonSearchOutlinedIcon sx={iconStyle} />}
                    loading={loading === 'loading'}
                />
                <TileSupport
                    title={t('chip.maintenance')}
                    data={maintenanceData}
                    icon={<ManageAccountsOutlinedIcon sx={iconStyle} />}
                    loading={loading === 'loading'}
                />
            </Box>
        </Box>
    );
};

export default GroupSupport;