import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loggedUser: {},
  timezone: "Etc/UTC",
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setLoggedUser: (state, action) => {
      state.loggedUser = action.payload;
      state.timezone = state.loggedUser.account.timezone;
    },
    logoutUser: (state) => {
      state.loggedUser = {};
      state.timezone = "Etc/UTC";
    },
  },
});

export const getLoggedUser = (state) => state.login.loggedUser;
export const getLoggedAccount = (state) => state.login.loggedUser.account;
export const getTimezone = (state) => state.login.timezone;

export const { setLoggedUser, logoutUser } = loginSlice.actions;

export default loginSlice.reducer;
