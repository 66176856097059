import { configureStore } from "@reduxjs/toolkit";
import accountReducer from "./slices/accountSlice";
import actionsMenuReducer from "./slices/actionsMenuSlice";
import analyticsReducer from "./slices/analyticsSlice";
import archiveReducer from "./slices/archiveSlice";
import impersonateReducer from "./slices/impersonateSlice";
import loginReducer from "./slices/loginSlice";
import materialsReducer from "./slices/materialsSlice";
import ordersReducer from "./slices/ordersSlice";
import placesReducer from "./slices/placesSlice";
import placesAnalyticsReducer from "./slices/placesAnalyticsSlice";
import productsReducer from "./slices/productsSlice";
import productsAnalyticsReducer from "./slices/productsAnalyticsSlice";
import registerReducer from "./slices/registerSlice";
import snackbarReducer from "./slices/snackbarSlice";
import technologiesReducer from "./slices/technologiesSlice";
import timestampsReducer from "./slices/timestampsSlice";
import usersReducer from "./slices/usersSlice";

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [],
        // Ignore these field paths in all actions
        ignoredActionPaths: ["payload"],
        // Ignore these paths in the state
        ignoredPaths: [],
      },
    }),
  reducer: {
    account: accountReducer,
    actionsMenu: actionsMenuReducer,
    analytics: analyticsReducer,
    archive: archiveReducer,
    impersonate: impersonateReducer,
    login: loginReducer,
    materials: materialsReducer,
    orders: ordersReducer,
    places: placesReducer,
    placesAnalytics: placesAnalyticsReducer,
    products: productsReducer,
    productsAnalytics: productsAnalyticsReducer,
    register: registerReducer,
    snackbar: snackbarReducer,
    technologies: technologiesReducer,
    timestamps: timestampsReducer,
    users: usersReducer,
  },
});
