import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    getArchive,
    setActiveOrder,
    startDeletingOrder,
    openOrderDetailDialog,
    selectAllOrders,
    getOrdersDownloadState,
} from '../../store/slices/archiveSlice';
import { getTimezone } from '../../store/slices/loginSlice';
import { useTranslation } from 'react-i18next';
import usePermission from '../../hooks/usePermission';
import { LinearProgress } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import DataGridStyle from '../../styles/DataGridStyle';
import TableActionsMenu from '../../components/table/TableActionsMenu';
import TableActionDetail from '../../components/table/TableActionDetails';
import TableActionDelete from '../../components/table/TableActionDelete';
import NoRowsOverlay from '../../assets/overlays/NoRowsOverlay';
import NoPermissionsOverlay from '../../assets/overlays/NoPermissionsOverlay';
import moment from 'moment-timezone';


function GridArchive() {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isMounted = useRef(false);

    const writeAccess = usePermission(['archive_write']);
    const readAccess = usePermission(['archive_read']);

    const rows = useSelector(selectAllOrders);
    const downloadState = useSelector(getOrdersDownloadState);
    const timezone = useSelector(getTimezone);

    const [anchorEl, setAnchorEl] = useState(null);


    const columns = [
        {
            field: 'order_id',
            headerName: '#',
            width: 40,
            sortable: false,
            filterable: false,
            hideable: false,
            columnMenu: false,
            disableColumnMenu: true,
            valueGetter: (params) =>
                params.api.getRowIndexRelativeToVisibleRows(params.row.order_id) + 1,
        },
        {
            field: 'order_number',
            headerName: t('label.order_number'),
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'product',
            headerName: t('label.product'),
            minWidth: 250,
            flex: 1,
            valueGetter: (params) =>
                params.row.product.product_name + " | " + params.row.product.product_number
        },
        {
            field: 'quantity',
            headerName: t('label.quantity'),
            minWidth: 120,
            flex: 1,
        },
        {
            field: 'due_date',
            headerName: t('label.due_date'),
            minWidth: 150,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>
                params.row.due_date !== "1970-01-01T00:00:00"
                    ? moment.utc(params.row.due_date).tz(timezone).format('DD-MM-YYYY')
                    : ""
        },
        {
            field: 'archived',
            headerName: t('label.archived'),
            minWidth: 150,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>
                moment.utc(params.row.updated).tz(timezone).format('DD-MM-YYYY, HH:mm'),
            renderCell: (params) =>
                moment.utc(params.row.updated).tz(timezone).format('DD-MM-YYYY'),
        },
        {
            field: 'order_comment',
            headerName: t('label.order_comment'),
            minWidth: 150,
            flex: 1,
        },
        {
            field: 'created',
            headerName: t('label.created'),
            minWidth: 150,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>
                moment.utc(params.row.created).tz(timezone).format('DD-MM-YYYY')
        },
        {
            field: 'updated',
            headerName: t('label.updated'),
            minWidth: 150,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            valueGetter: (params) =>
                moment.utc(params.row.updated).tz(timezone).format('DD-MM-YYYY')
        },
        {
            field: 'actions',
            headerName: t('label.actions'),
            minWidth: 100,
            sortable: false,
            filterable: false,
            hideable: false,
            headerAlign: 'center',
            align: 'center',
            disableColumnMenu: true,
            renderCell: () => (
                <TableActionsMenu
                    onClick={handleMenuClick}
                    permission={writeAccess}
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                >
                    <TableActionDetail onClick={handleDetailClick} />
                    <TableActionDelete onClick={handleDeleteClick} />
                </TableActionsMenu>
            )
        },
    ];

    /* --------- GET ---------------------------------------------------------------------*/

    useEffect(() => {
        if (isMounted.current) return;
        /* eslint-disable */
        dispatch(getArchive());

        isMounted.current = true;
    }, []);

    /* -----------------------------------------------------------------------------------*/

    const handleRowClick = (params) => {
        dispatch(setActiveOrder(params.row));
    };

    const handleMenuClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleDeleteClick = () => {
        dispatch(startDeletingOrder());
        setAnchorEl(null);
    };

    const handleDetailClick = () => {
        dispatch(openOrderDetailDialog());
        setAnchorEl(null);
    };


    return (
        <DataGridStyle>
            <DataGrid
                rows={rows}
                columns={columns}
                getRowId={(row) => row.order_id}
                loading={downloadState.status === 'loading'}
                onRowClick={(params) => handleRowClick(params)}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            order_comment: false,
                            created: false,
                            updated: false,
                        },
                    },
                    sorting: { sortModel: [{ field: 'archived', sort: 'desc' }] },
                    pagination: { paginationModel: { pageSize: 100 } },
                    filter: {
                        filterModel: {
                            items: [],
                            quickFilterExcludeHiddenColumns: true,
                        },
                    },
                }}
                slots={{
                    toolbar: GridToolbar,
                    loadingOverlay: LinearProgress,
                    noRowsOverlay: readAccess ? NoRowsOverlay : NoPermissionsOverlay,
                }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
            />
        </DataGridStyle>
    );
};

export default GridArchive;