import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';


function QualityChip() {

    const { t } = useTranslation();

    return (
        <Chip
            icon={<PersonSearchOutlinedIcon />}
            label={t('chip.quality')}
            variant="filled"
            color="warning"
            size="medium"
            sx={{ width: '100%' }}
        />
    );
};

export default QualityChip;