import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from '../../../theme';

function TimelineLegendElement(props) {

    const { label, color } = props

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            display='flex'
            alignItems='center'
            gap='10px'
        >
            <Box
                height='20px'
                width='40px'
                backgroundColor={color}
            />
            <Typography
                variant='subtitle1'
                color={colors.primary[300]}
            >
                {label}
            </Typography>
        </Box>
    );
};

export default TimelineLegendElement;