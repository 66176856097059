import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';


function SetupChip() {

    const { t } = useTranslation();

    return (
        <Chip
            icon={<EngineeringOutlinedIcon />}
            label={t('chip.setup')}
            variant="outlined"
            color="success"
            size="medium"
            sx={{ width: '100%' }}
        />
    );
};

export default SetupChip;