import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";
import { formatNoResponseError } from "../storeUtils";

const initialOrder = {
  order_name: "",
  order_number: "",
  process: "",
  description: "",
  action: "stop", // 'stop' | 'process' | 'setup' | 'scheduled' | 'blocker'
  support: "no_support", // 'no_support' | 'supervisor' | 'technology' | 'quality' | 'maintenance'
};

const initialState = {
  archive: [],
  activeOrder: initialOrder,
  orderDetailDialog: {
    isOpen: false,
  },
  downloading: {
    status: "idle", //  'idle' | 'loading' | 'succeeded' | 'failed'
    error: { status: null, status_text: null, detail: null },
  },
  deleting: {
    dialog: false,
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: { status: null, status_text: null, detail: null },
  },
};

export const getArchive = createAsyncThunk(
  "archive/getArchive",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/archive/");
      return [...response.data];
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const deleteOrder = createAsyncThunk(
  "archive/deleteOrder",
  async (order, { rejectWithValue }) => {
    try {
      await axiosInstance.delete(`/archive/${order.order_id}`);
      return order.order_id;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const archiveSlice = createSlice({
  name: "archive",
  initialState,
  reducers: {
    /* --- Archive ------------------------------------------- */
    setActiveOrder: (state, action) => {
      state.activeOrder = state.archive.find(
        (order) => order.order_id === action.payload.order_id
      );
    },
    /* --- OrderDetail dialog -------------------------------*/
    openOrderDetailDialog: (state) => {
      state.orderDetailDialog.isOpen = true;
    },
    closeOrderDetailDialog: (state) => {
      state.orderDetailDialog.isOpen = false;
      state.activeOrder = initialOrder;
    },
    /* --- Information dialogs ---------------------------- */
    startDeletingOrder: (state) => {
      state.deleting.status = "idle";
      state.deleting.dialog = true;
    },
    closeDeletingDialog: (state) => {
      state.deleting.dialog = false;
    },
  },
  extraReducers(builder) {
    builder
      /* --- READ ------------------------------------------- */
      .addCase(getArchive.pending, (state) => {
        state.downloading.status = "loading";
      })
      .addCase(getArchive.fulfilled, (state, action) => {
        state.downloading.status = "succeeded";
        state.archive = action.payload;
      })
      .addCase(getArchive.rejected, (state, action) => {
        state.downloading.status = "failed";
        state.downloading.error.status = action.payload.response.status;
        state.downloading.error.status_text =
          action.payload.response.statusText;
        state.downloading.error.detail =
          action.payload.response.data.detail || action.payload.response.data;
      })
      /* --- DELETE ----------------------------------------- */
      .addCase(deleteOrder.pending, (state) => {
        state.deleting.status = "loading";
      })
      .addCase(deleteOrder.fulfilled, (state, action) => {
        const order_index = state.archive.findIndex(
          (order) => order.order_id === action.payload
        );
        state.archive.splice(order_index, 1);
        state.activeOrder = initialOrder;
        state.deleting.status = "succeeded";
      })
      .addCase(deleteOrder.rejected, (state, action) => {
        state.deleting.status = "failed";
        state.deleting.error.status = action.payload.response.status;
        state.deleting.error.status_text = action.payload.response.statusText;
        state.deleting.error.detail =
          action.payload.response.data.detail || action.payload.response.data;
      });
  },
});

/* --- Orders ------------------------------------------------------------------- */
export const getActiveOrder = (state) => state.archive.activeOrder;
export const selectAllOrders = (state) => state.archive.archive;

/* --- OrderDetail Dialog ------------------------------------------------------- */
export const getOrderDetailDialogState = (state) =>
  state.archive.orderDetailDialog;

/* --- Archive Functions ---------------------------------------------------------- */
export const getOrdersDownloadState = (state) => state.archive.downloading;
export const getOrderDeleteState = (state) => state.archive.deleting;

/* --- REDUCERS --------------------------------------- */
export const {
  setActiveOrder,
  openOrderDetailDialog,
  closeOrderDetailDialog,
  startDeletingOrder,
  closeDeletingDialog,
} = archiveSlice.actions;

export default archiveSlice.reducer;
