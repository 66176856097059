import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    updatePlaceSupport,
    closeLoadingErrorDialog,
    getActivePlace,
    getPlaceTileDownloadState,
    getSupportUploadState
} from '../../../store/slices/impersonateSlice';
import { useTranslation } from 'react-i18next';
import usePermission from '../../../hooks/usePermission';
import { Box, Menu, useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import ButtonMenu from './ButtonMenu';
import ButtonMenuOption from './ButtonMenuOption';
import ErrorLoadingDataDialog from '../../dialogs/ErrorLoadingDataDialog';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';


function MenuSupport() {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const writeAccess = usePermission(['impersonate_write'])

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [anchor, setAnchor] = useState(null);
    const open = Boolean(anchor);

    const activePlace = useSelector(getActivePlace);
    const supportStatus = activePlace?.support || 'no_support';
    const downloadState = useSelector(getPlaceTileDownloadState);
    const downloadStatus = downloadState.status
    const uploadState = useSelector(getSupportUploadState);
    const uploadStatus = uploadState.status;


    const buttons = [
        {
            label: t('button.device.support'),
            status: 'no_support',
            variant: 'outlined',
            color: 'success',
            icon: <CheckRoundedIcon sx={{ fontSize: '40px' }} />,
        },
        {
            label: t('button.device.supervisor'),
            status: 'supervisor',
            variant: 'contained',
            color: 'warning',
            icon: <StarOutlineOutlinedIcon sx={{ fontSize: '40px' }} />,
        },
        {
            label: t('button.device.technology'),
            status: 'technology',
            variant: 'contained',
            color: 'warning',
            icon: <PersonOutlineOutlinedIcon sx={{ fontSize: '40px' }} />,
        },
        {
            label: t('button.device.quality'),
            status: 'quality',
            variant: 'contained',
            color: 'warning',
            icon: <PersonSearchOutlinedIcon sx={{ fontSize: '40px' }} />,
        },
        {
            label: t('button.device.maintenance'),
            status: 'maintenance',
            variant: 'contained',
            color: 'error',
            icon: <ManageAccountsOutlinedIcon sx={{ fontSize: '40px' }} />,
        }
    ];


    const handleClick = (newStatus) => {

        const place = {
            place_id: activePlace.place_id,
            support: newStatus
        };

        dispatch(updatePlaceSupport(place));
        setAnchor(null);
    };


    return (
        <>
            {buttons.filter(button => button.status === supportStatus).map((button, id) =>
                <ButtonMenu
                    key={id}
                    label={button.label}
                    variant={button.variant}
                    color={button.color}
                    icon={button.icon}
                    setAnchor={setAnchor}
                    loading={downloadStatus === 'loading' || !activePlace.support || uploadStatus === 'loading'}
                    disabled={!writeAccess}
                />
            )}

            <Menu
                anchorEl={anchor}
                open={open}
                onClose={() => setAnchor(null)}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                sx={{
                    '& .MuiList-root': {
                        backgroundColor: colors.primary[500],
                        padding: '0px'
                    },
                }}
            >
                <Box display='flex' flexDirection='row-reverse' gap='10px' p='10px'>
                    {buttons.filter(button => button.status !== supportStatus).map((button, id) =>
                        <ButtonMenuOption
                            key={id}
                            label={button.label}
                            variant={button.variant}
                            color={button.color}
                            icon={button.icon}
                            onClick={() => handleClick(button.status)}
                        />
                    )}
                </Box>
            </Menu>

            <ErrorLoadingDataDialog
                open={uploadState.status === 'failed'}
                error={uploadState.error}
                onClose={() => dispatch(closeLoadingErrorDialog())}
            />
        </>
    );
};

export default MenuSupport;