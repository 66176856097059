import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getTimezone } from '../../../store/slices/loginSlice';
import { Box, Typography, Tooltip, useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import moment from 'moment-timezone';
import UtilizationDisplay from '../UtilizationDisplay';
import TimelineTooltip from './TimelineTooltip';


function TimelineComponent(props) {

    const { data, startTime, endTime, label, marker } = props;

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const timezone = useSelector(getTimezone);
    const [componentData, setComponentData] = useState([]);

    const positionRef = useRef({
        x: 0,
        y: 0,
    });
    const popperRef = useRef(null);
    const areaRef = useRef(null);

    const timeScale = [];
    for (let i = 0; i < 25; i++) {
        timeScale.push(`${i}:00`);
    };


    useEffect(() => {
        /* eslint-disable */
        const filterData = (data_, startTime, endTime) => {

            const data = [...data_]
            const filteredData = [];

            if (data.length) {

                const lastIndex = data.length - 1;
                data[lastIndex] = {
                    start: data[lastIndex].start,
                    end: moment().toISOString(),
                    action: data[lastIndex].action,
                    duration: parseInt(moment().startOf('second').diff(moment.utc(data[lastIndex].start).tz(timezone).startOf('second'), 'seconds')),
                };


                for (let i = 0, len = data.length; i < len; i++) {
                    let timestampStart = moment.utc(data[i].start).tz(timezone);
                    let timestampEnd = moment.utc(data[i].end).tz(timezone);

                    if ((timestampStart < startTime) && (startTime < timestampEnd) && (timestampEnd < endTime)) {
                        /* timestamp ENDS this day */
                        filteredData.push({
                            start: timestampStart.startOf('second'),
                            start_timeline: startTime,
                            end: timestampEnd.startOf('second'),
                            end_timeline: timestampEnd.startOf('second'),
                            action: data[i].action,
                            duration: data[i].duration,
                            duration_timeline: parseInt(timestampEnd.startOf('second').diff(startTime, 'seconds')),
                        });

                    } else if ((startTime < timestampStart) && (timestampEnd < endTime)) {
                        /* timestamp STARTS and ENDS this day */
                        filteredData.push({
                            start: timestampStart.startOf('second'),
                            start_timeline: timestampStart.startOf('second'),
                            end: timestampEnd.startOf('second'),
                            end_timeline: timestampEnd.startOf('second'),
                            action: data[i].action,
                            duration: data[i].duration,
                            duration_timeline: parseInt(timestampEnd.startOf('second').diff(timestampStart.startOf('second'), 'seconds')),
                        });

                    } else if ((startTime < timestampStart) && (timestampStart < endTime) && (endTime < timestampEnd)) {
                        /* timestamp STARTS this day */
                        filteredData.push({
                            start: timestampStart.startOf('second'),
                            start_timeline: timestampStart.startOf('second'),
                            end: timestampEnd.startOf('second'),
                            end_timeline: endTime,
                            action: data[i].action,
                            duration: data[i].duration,
                            duration_timeline: parseInt(endTime.diff(timestampStart.startOf('second'), 'seconds')),
                        });

                    } else if ((timestampStart < startTime) && (endTime < timestampEnd)) {
                        /* FULL day */
                        filteredData.push({
                            start: timestampStart.startOf('second'),
                            start_timeline: startTime,
                            end: timestampEnd.startOf('second'),
                            end_timeline: endTime,
                            action: data[i].action,
                            duration: data[i].duration,
                            duration_timeline: parseInt(endTime.diff(startTime, 'seconds')),
                        });
                    };
                };
            };

            return filteredData;
        };

        setComponentData(filterData(data, startTime, endTime));

    }, [data]);


    const getColor = (action) => {

        let color = 'transparent'
        let color2 = 'transparent'

        if (action === 'process') {
            color = colors.greenAccent[500];
            color2 = colors.greenAccent[400];
        }
        if (action === 'setup') {
            color = colors.greenAccent[800];
            color2 = colors.greenAccent[700];
        }
        if (action === 'scheduled') {
            color = colors.yellowAccent[700];
            color2 = colors.yellowAccent[600];
        }
        if (action === 'blocker') {
            color = colors.redAccent[500];
            color2 = colors.redAccent[400];
        };

        return `linear-gradient(to bottom, ${color2}, ${color}, ${color}, ${color2})`
    };

    const getStart = (timestamp) => {
        //  left='0%'   -->  0:00h
        //  left='100%' --> 24:00h
        const dayStart = moment.utc(timestamp).tz(timezone).startOf('day')
        const fromStart = moment.utc(timestamp).tz(timezone).diff(dayStart, 'seconds')
        const start_proc = fromStart / 864;
        return `${start_proc}%`;
    };

    const getWidth = (duration_sec) => {
        //  100% width = 24h
        const width_proc = duration_sec / 864;
        return `${width_proc}%`;
    };

    const handleMouseMove = (event) => {
        positionRef.current = { x: event.clientX, y: event.clientY };

        if (popperRef.current != null) {
            popperRef.current.update();
        };
    };

    return (
        <Box
            minWidth='900px'
            display='flex'
            alignItems='center'
            gap='40px'
        >
            <Box
                width='100%'
            >
                {/* TITLE */}
                <Typography
                    variant='subtitle1'
                    color={colors.primary[300]}
                    m='6px -9px'
                >
                    {label}
                </Typography>

                {/* TIMELINE */}
                <Box
                    width='100%'
                    height='45px'
                    position='relative' // parent to timestamps
                    display='flex'
                    border='1px solid'
                    borderColor={colors.primary[400]}
                >

                    {componentData?.map((timestamp, index) => {
                        return (
                            <Tooltip
                                key={index}
                                title={<TimelineTooltip data={timestamp} />}
                                placement="top"
                                arrow
                                PopperProps={{
                                    popperRef,
                                    anchorEl: {
                                        getBoundingClientRect: () => {
                                            return new DOMRect(
                                                positionRef.current.x,
                                                areaRef.current.getBoundingClientRect().y,
                                                0,
                                                0,
                                            );
                                        },
                                    },
                                }}
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            p: 0,
                                            '& .MuiTooltip-arrow': {
                                                color: colors.grey[100],
                                            },
                                        },
                                    },
                                }}
                            >
                                <Box
                                    ref={areaRef}
                                    onMouseMove={handleMouseMove}
                                    height='100%'
                                    position='absolute'
                                    left={getStart(timestamp.start_timeline)}
                                    width={getWidth(timestamp.duration_timeline)}
                                    sx={{
                                        background: getColor(timestamp.action),
                                        "&:hover": {
                                            border: '3px solid',
                                            borderColor: colors.grey[100]
                                        }
                                    }}
                                />
                            </Tooltip>
                        )
                    })}

                    {/* NOW MARKER */}
                    {marker ? (
                        <Box
                            width='2px'
                            position='absolute'
                            left={`calc(${getStart(moment())} - 1px)`}
                            top='-7px'
                            bottom='-7px'
                            border='1px solid'
                            borderColor={colors.violetAccent[500]}
                            backgroundColor={colors.violetAccent[500]}
                        />
                    ) : (null)}

                </Box>

                {/* TIMELINE SCALE */}
                <Box
                    display='flex'
                    justifyContent='space-between'
                >
                    {timeScale.map((hour, index) => {
                        return (
                            <Box
                                key={index}
                                minWidth='30px'
                                mx='-14px'
                                display='flex'
                                flexDirection='column'
                                alignItems='center'
                            >
                                <Box width='1px' height='6px' border='1px solid' borderColor={colors.primary[400]} />
                                <Typography
                                    color={colors.primary[300]}
                                    fontSize='12px'
                                >
                                    {hour}
                                </Typography>
                            </Box>
                        )
                    })}
                </Box>

            </Box>

            <Box mt='25px'>
                <UtilizationDisplay
                    data={componentData}
                />
            </Box>
        </Box>
    );
};

export default TimelineComponent;