import { useDispatch, useSelector } from 'react-redux';
import { getLoggedAccount, getTimezone } from '../../store/slices/loginSlice';
import { updateAccount, closeLoadingErrorDialog, getAccountUploadState } from '../../store/slices/accountSlice';
import { Box, Accordion, AccordionDetails, AccordionSummary, Grid, MenuItem, TextField, Typography, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import settings from '../../config.json';
import useMediaQuery from '../../hooks/useMediaQuery';
import usePermission from '../../hooks/usePermission';
import { tokens } from '../../theme';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import ErrorLoadingDataDialog from '../../components/dialogs/ErrorLoadingDataDialog';
import moment from 'moment-timezone';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { timezones } from '../../assets/lists/timezones';


/* eslint-disable */
const emailRegExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

const accountSchema = yup.object().shape({
    account_name: yup
        .string()
        .required('form.error.field_required'),
    account_email: yup
        .string()
        .email('form.error.email_invalid_format')
        .matches(emailRegExp, 'form.error.email_invalid_format')
        .required('form.error.email_required')
});


function AccordionSettings() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const isAboveMobileSize = useMediaQuery(settings.NON_MOBILE_MEDIA_QUERY);
    const access = usePermission([]) // - only admin

    const activeAccount = useSelector(getLoggedAccount);
    const uploadState = useSelector(getAccountUploadState);
    const timezone = useSelector(getTimezone);

    const accordionStyle = { backgroundColor: colors.primary[500] };
    const gridTextFieldStyle = { height: '66px', px: '15px' };
    const createdUpdatedStyle = { maxWidth: '400px', px: '15px' };

    const emptyValues = {
        account_name: "",
        account_email: "",
        created: "",
        updated: ""
    };

    /* ----- UPDATE ----------------------------------------------------------------------*/

    const handleFormSubmit = async (values) => {

        dispatch(updateAccount(
            {
                account_id: activeAccount.account_id,
                account_name: values.account_name,
                account_email: values.account_email,
                timezone: values.timezone
            },
        ));
    };

    /* -----------------------------------------------------------------------------------*/

    return (
        <>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={activeAccount || emptyValues}
                validationSchema={accountSchema}
                enableReinitialize
            >
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                    <Form>

                        <Accordion
                            sx={accordionStyle}
                            disabled={!access}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>{t('header.account_details')}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid
                                    container
                                    height={isAboveMobileSize ? 'auto' : 'auto'} // set height order: --- 3/3 ---
                                    maxWidth='600px'
                                    p={isAboveMobileSize ? '6px 0 0 0' : '6px 0 0 0'}
                                    overflow='auto'
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sx={gridTextFieldStyle}
                                    >
                                        <TextField
                                            fullWidth
                                            variant='outlined'
                                            type='text'
                                            size="small"
                                            label={t('label.account_name')}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.account_name || ''}
                                            name='account_name'
                                            error={!!touched.account_name && !!errors.account_name}
                                            helperText={touched.account_name && <>{t(errors.account_name)}</>}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={gridTextFieldStyle}
                                    >
                                        <TextField
                                            fullWidth
                                            variant='outlined'
                                            type='text'
                                            size="small"
                                            label={t('label.account_email')}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.account_email || ''}
                                            name='account_email'
                                            error={!!touched.account_email && !!errors.account_email}
                                            helperText={touched.account_email && <>{t(errors.account_email)}</>}
                                        />
                                    </Grid>
                                </Grid>
                                <Typography sx={createdUpdatedStyle}>
                                    {t('label.created')}: {moment.utc(values.created).tz(timezone).format('DD-MM-YYYY')}
                                </Typography>
                                <Typography sx={createdUpdatedStyle}>
                                    {t('label.updated')}: {moment.utc(values.updated).tz(timezone).format('DD-MM-YYYY')}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            sx={accordionStyle}
                            disabled={!access}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography>{t('header.timezone')}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid
                                    container
                                    height={isAboveMobileSize ? 'auto' : 'auto'} // set height order: --- 3/3 ---
                                    maxWidth='600px'
                                    p={isAboveMobileSize ? '6px 0 0 0' : '6px 0 0 0'}
                                    overflow='auto'
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sx={gridTextFieldStyle}
                                    >
                                        <TextField
                                            select
                                            fullWidth
                                            variant='outlined'
                                            type='text'
                                            size="small"
                                            label={t('label.timezone')}
                                            onChange={handleChange}
                                            value={values.timezone}
                                            name='timezone'
                                        >
                                            {timezones.map((option) => {
                                                return (
                                                    <MenuItem value={option.value} key={option.id}>
                                                        <Grid container>
                                                            <Grid item xs={3}>{option.offset}</Grid>
                                                            <Grid item xs={9}>{option.label}</Grid>
                                                        </Grid>
                                                    </MenuItem>
                                                )
                                            })}
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion
                            sx={accordionStyle}
                            disabled
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel3a-content"
                                id="panel3a-header"
                            >
                                <Typography>Options</Typography>
                            </AccordionSummary>
                        </Accordion>

                        <Box
                            display='flex'
                            justifyContent='center'
                            m="20px"
                        >
                            <LoadingButton
                                color='secondary'
                                size='large'
                                variant="contained"
                                onClick={handleSubmit}
                                loading={uploadState.status === 'loading'}
                                startIcon={<SaveOutlinedIcon />}
                                disabled={!access}
                                sx={{
                                    px: '50px',
                                    py: '15px'
                                }}
                            >
                                {t('button.save')}
                            </LoadingButton>
                        </Box>

                    </Form>
                )}
            </Formik>

            <ErrorLoadingDataDialog
                open={uploadState.status === 'failed'}
                error={uploadState.error}
                onClose={() => dispatch(closeLoadingErrorDialog())}
            />
        </>
    );
};

export default AccordionSettings;