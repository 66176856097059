import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    fetchOrdersSummary,
    getOrdersSummary,
    getOrdersSummaryDownloadState
} from '../../store/slices/analyticsSlice';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import settings from '../../config.json'
import useMediaQuery from '../../hooks/useMediaQuery';
import TileOrder from './TileOrder';
import OrderStatusPieChart from './OrderStatusPieChart';


function GroupOrders() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const dispatch = useDispatch();
    const isMounted = useRef(false);
    const { t } = useTranslation();
    const isAboveMediumScreens = useMediaQuery(settings.ABOVE_MEDIUM_MEDIA_QUERY);

    const data = useSelector(getOrdersSummary);
    const downloadState = useSelector(getOrdersSummaryDownloadState);
    const loadingStatus = downloadState.status;

    /* --------- GET ---------------------------------------------------------------------*/

    useEffect(() => {
        if (isMounted.current) return;
        /* eslint-disable */
        dispatch(fetchOrdersSummary());

        isMounted.current = true;
    }, []);

    /* -----------------------------------------------------------------------------------*/

    return (
        <Box
            height='100%'
            display='flex'
            flexDirection='column'
            p='10px'
        >
            <Typography
                variant='h6'
                //align='center'
                color={colors.primary[300]}
                mt='-8px'
            >
                {t('header.orders')}
            </Typography>
            <Box
                height='100%'
                display='grid'
                gridTemplateColumns={isAboveMediumScreens ? '1fr 1fr 1fr 1fr' : null}
                gap='10px'
            >
                <TileOrder
                    title={t('label.orders_backlog')}
                    value={data.production_orders}
                    color={colors.primary[400]}
                    loading={loadingStatus === 'loading'}
                />
                <TileOrder
                    title={t('label.orders_in_progress')}
                    value={data.status_in_progress}
                    color={colors.greenAccent[500]}
                    loading={loadingStatus === 'loading'}
                />
                <OrderStatusPieChart
                    data={data}
                    loadingState={downloadState}
                />
                <TileOrder
                    title={t('label.orders_finished')}
                    value={data.status_finished}
                    color={colors.primary[400]}
                    loading={loadingStatus === 'loading'}
                />
            </Box>
        </Box>
    );
};

export default GroupOrders;