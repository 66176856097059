import { Box, Button } from '@mui/material';

function ButtonMenuOption(props) {

    const { label, variant, color, icon, onClick } = props;


    return (
        <Button
            variant={variant}
            color={color}
            sx={{ height: '120px', width: '150px' }}
            onClick={onClick}
        >
            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                {icon}
                {label}
            </Box>
        </Button>
    );
};

export default ButtonMenuOption;