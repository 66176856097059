import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';


function BlockerChip() {

    const { t } = useTranslation();

    return (
        <Chip
            icon={<PriorityHighOutlinedIcon />}
            label={t('chip.blocker')}
            variant="filled"
            color="error"
            size="medium"
            sx={{ width: '100%' }}
        />
    );
};

export default BlockerChip;