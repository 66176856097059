import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";
import { notifySuccess } from "./snackbarSlice";
import { formatNoResponseError } from "../storeUtils";

const initialPlace = {
  place_name: "",
  place_number: "",
  process: "",
  description: "",
  status: "idle", // 'idle' | 'order'
  action: "stop", // 'stop' | 'process' | 'setup' | 'scheduled' | 'blocker'
  support: "no_support", // 'no_support' | 'supervisor' | 'technology' | 'quality' | 'maintenance'
};

const initialState = {
  places: [],
  place: null,
  activePlace: initialPlace,
  placeDialog: {
    isOpen: false,
    mode: null, //  null | 'add' | 'edit'
    activeTab: "general",
  },
  downloading: {
    status: "idle", //  'idle' | 'loading' | 'succeeded' | 'failed'
    error: { status: null, status_text: null, detail: null },
  },
  uploading: {
    status: "idle", //  'idle' | 'loading' | 'succeeded' | 'failed'
    error: { status: null, status_text: null, detail: null },
  },
  deleting: {
    dialog: false,
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: { status: null, status_text: null, detail: null },
  },
};

export const fetchAllPlaces = createAsyncThunk(
  "places/fetchAllPlaces",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/places/");
      return [...response.data];
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const fetchPlace = createAsyncThunk(
  "places/fetchPlace",
  async (place_id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`/places/${place_id}`);
      return response.data;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const createPlace = createAsyncThunk(
  "places/createPlace",
  async (place, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/places/create", place);
      dispatch(notifySuccess());
      return response.data;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const updatePlace = createAsyncThunk(
  "places/updatePlace",
  async (place, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `/places/${place.place_id}`,
        place
      );
      dispatch(notifySuccess());
      return response.data;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const deletePlace = createAsyncThunk(
  "places/deletePlace",
  async (place, { rejectWithValue }) => {
    try {
      await axiosInstance.delete(`/places/${place.place_id}`);
      return place.place_id;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const placesSlice = createSlice({
  name: "places",
  initialState,
  reducers: {
    /* --- Place ------------------------------------------- */
    setActivePlace: (state, action) => {
      state.activePlace = state.places.find(
        (place) => place.place_id === action.payload.place_id
      );
      state.place = state.places.find(
        (place) => place.place_id === action.payload.place_id
      );
    },
    resetActivePlace: (state) => {
      state.activePlace = initialPlace;
    },
    /* --- Place dialog -------------------------------------*/
    startAddingPlace: (state) => {
      state.placeDialog.mode = "add";
      state.placeDialog.activeTab = "general";
      state.placeDialog.isOpen = true;
    },
    startEditingPlace: (state) => {
      state.placeDialog.mode = "edit";
      state.placeDialog.activeTab = "general";
      state.placeDialog.isOpen = true;
    },
    closePlaceDialog: (state) => {
      state.placeDialog.isOpen = false;
      state.placeDialog.activeTab = "general";
      state.placeDialog.mode = null;
      state.activePlace = initialPlace;
    },
    setPlaceActiveTab: (state, action) => {
      state.placeDialog.activeTab = action.payload;
    },
    /* --- Information dialogs ---------------------------- */
    startDeletingPlace: (state) => {
      state.deleting.status = "idle";
      state.deleting.dialog = true;
    },
    closeDeletingDialog: (state) => {
      state.deleting.dialog = false;
    },
    closeLoadingErrorDialog: (state) => {
      state.uploading.status = "idle";
    },
  },
  extraReducers(builder) {
    builder
      /* --- FETCH ALL -------------------------------------- */
      .addCase(fetchAllPlaces.pending, (state) => {
        state.downloading.status = "loading";
      })
      .addCase(fetchAllPlaces.fulfilled, (state, action) => {
        state.downloading.status = "succeeded";
        state.places = action.payload;
      })
      .addCase(fetchAllPlaces.rejected, (state, action) => {
        state.downloading.status = "failed";
        state.downloading.error.status = action.payload.response.status;
        state.downloading.error.status_text =
          action.payload.response.statusText;
        state.downloading.error.detail =
          action.payload.response.data.detail || action.payload.response.data;
      })
      /* --- FETCH SINGLE ----------------------------------- */
      .addCase(fetchPlace.pending, (state) => {
        state.downloading.status = "loading";
      })
      .addCase(fetchPlace.fulfilled, (state, action) => {
        state.downloading.status = "succeeded";
        state.place = action.payload;
      })
      .addCase(fetchPlace.rejected, (state, action) => {
        state.downloading.status = "failed";
        state.downloading.error.status = action.payload.response.status;
        state.downloading.error.status_text =
          action.payload.response.statusText;
        state.downloading.error.detail =
          action.payload.response.data.detail || action.payload.response.data;
      })
      /* --- CREATE ----------------------------------------- */
      .addCase(createPlace.pending, (state) => {
        state.uploading.status = "loading";
      })
      .addCase(createPlace.fulfilled, (state, action) => {
        state.activePlace = action.payload;
        state.places.push(action.payload);
        state.uploading.status = "succeeded";
        state.placeDialog.mode = "edit";
      })
      .addCase(createPlace.rejected, (state, action) => {
        state.uploading.status = "failed";
        state.uploading.error.status = action.payload.response.status;
        state.uploading.error.status_text = action.payload.response.statusText;
        state.uploading.error.detail =
          action.payload.response.data.detail || action.payload.response.data;
      })
      /* --- UPDATE ----------------------------------------- */
      .addCase(updatePlace.pending, (state) => {
        state.uploading.status = "loading";
      })
      .addCase(updatePlace.fulfilled, (state, action) => {
        state.activePlace = action.payload;
        const place_index = state.places.findIndex(
          (place) => place.place_id === action.payload.place_id
        );
        state.places[place_index] = action.payload;
        state.uploading.status = "succeeded";
      })
      .addCase(updatePlace.rejected, (state, action) => {
        state.uploading.status = "failed";
        state.uploading.error.status = action.payload.response.status;
        state.uploading.error.status_text = action.payload.response.statusText;
        state.uploading.error.detail =
          action.payload.response.data.detail || action.payload.response.data;
      })
      /* --- DELETE ----------------------------------------- */
      .addCase(deletePlace.pending, (state) => {
        state.deleting.status = "loading";
      })
      .addCase(deletePlace.fulfilled, (state, action) => {
        const place_index = state.places.findIndex(
          (place) => place.place_id === action.payload
        );
        state.places.splice(place_index, 1);
        state.activePlace = initialPlace;
        state.deleting.status = "succeeded";
      })
      .addCase(deletePlace.rejected, (state, action) => {
        state.deleting.status = "failed";
        state.deleting.error.status = action.payload.response.status;
        state.deleting.error.status_text = action.payload.response.statusText;
        state.deleting.error.detail =
          action.payload.response.data.detail || action.payload.response.data;
      });
  },
});

/* --- Places ------------------------------------------------------------------- */
export const getActivePlace = (state) => state.places.activePlace;
export const getPlace = (state) => state.places.place;
export const selectAllPlaces = (state) => state.places.places;
// export const selectPlaceById = (state, id) =>
//   state.places.places.find((place) => place.place_id === id);

/* --- Place Dialog ------------------------------------------------------------- */
export const getPlaceDialogState = (state) => state.places.placeDialog;

/* --- Place Functions ---------------------------------------------------------- */
export const getPlacesDownloadState = (state) => state.places.downloading;
export const getPlaceUploadState = (state) => state.places.uploading;
export const getPlaceDeleteState = (state) => state.places.deleting;

/* --- REDUCERS --------------------------------------- */
export const {
  setActivePlace,
  resetActivePlace,
  startAddingPlace,
  startEditingPlace,
  closePlaceDialog,
  setPlaceActiveTab,
  startDeletingPlace,
  closeDeletingDialog,
  closeLoadingErrorDialog,
} = placesSlice.actions;

export default placesSlice.reducer;
