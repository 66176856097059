import { useSelector, useDispatch } from 'react-redux';
import {
    createMaterial,
    updateMaterial,
    resetActiveMaterial,
    closeLoadingErrorDialog,
    getActiveMaterial,
    getMaterialDialogState,
    getMaterialUploadState,
} from '../../store/slices/materialsSlice';
import { useTranslation } from 'react-i18next';
import settings from '../../config.json';
import useMediaQuery from '../../hooks/useMediaQuery';
import { Grid, TextField } from '@mui/material';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import SubmitResetButtonGroup from '../../components/buttons/SubmitResetButtonGroup';
import CreatedUpdatedField from '../../components/form/CreatedUpdatedField';
import ErrorLoadingDataDialog from '../../components/dialogs/ErrorLoadingDataDialog';;


const materialSchema = yup.object().shape({
    material_name: yup
        .string()
        .required('form.error.field_required'),
    material_number: yup
        .string(),
    unit: yup
        .string()
        .required('form.error.field_required'),
    description: yup
        .string()
});


function FormMaterial() {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const isAboveMobileSize = useMediaQuery(settings.NON_MOBILE_MEDIA_QUERY);

    const activeMaterial = useSelector(getActiveMaterial);
    const materialDialogState = useSelector(getMaterialDialogState);
    const uploadState = useSelector(getMaterialUploadState);

    const gridTextFieldStyle = { height: '66px', px: '15px' };
    const gridMultilineFieldStyle = { height: '95px', px: '15px' };
    const gridCreatedUpdatedStyle = { px: '15px' };

    /* ----- CREATE / UPDATE -------------------------------------------------------------*/

    const handleFormSubmit = async (values) => {

        const material = {
            material_id: activeMaterial.material_id,
            material_name: values.material_name,
            material_number: values.material_number,
            unit: values.unit,
            description: values.description
        };

        if (materialDialogState.mode === 'add') {
            dispatch(createMaterial(material));
        };
        if (materialDialogState.mode === 'edit') {
            dispatch(updateMaterial(material));
        };
    };

    /* ----- RESET -----------------------------------------------------------------------*/

    // Pressing "ADD NEXT" button is not clearing inputs - expected behavior
    // Manual Reset added for clearing inputs in 'add' mode
    // 'edit' mode is using 'Formik' builded-in functionality

    const handleResetClick = () => {
        if (materialDialogState.mode === 'add') {
            dispatch(resetActiveMaterial());
        };
    };

    /* -----------------------------------------------------------------------------------*/


    return (
        <>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={activeMaterial}
                validationSchema={materialSchema}
                enableReinitialize
            >
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                    <Form>

                        <Grid
                            container
                            height={isAboveMobileSize ? 'auto' : '360px'} // set height order: --- 3/3 ---
                            p={isAboveMobileSize ? '6px 0 0 0' : '6px 0 12px 0'}
                            overflow='auto'
                        >
                            <Grid
                                item
                                xs={12}
                                sx={gridTextFieldStyle}
                            >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    type='text'
                                    size="small"
                                    label={t('label.material_name')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.material_name || ''}
                                    name='material_name'
                                    error={!!touched.material_name && !!errors.material_name}
                                    helperText={touched.material_name && <>{t(errors.material_name)}</>}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={gridTextFieldStyle}
                            >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    type='text'
                                    size="small"
                                    label={t('label.material_number')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.material_number || ''}
                                    name='material_number'
                                    error={!!touched.material_number && !!errors.material_number}
                                    helperText={touched.material_number && <>{t(errors.material_number)}</>}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={gridTextFieldStyle}
                            >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    type='text'
                                    size="small"
                                    label={t('label.unit')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.unit || ''}
                                    name='unit'
                                    error={!!touched.unit && !!errors.unit}
                                    helperText={touched.unit && <>{t(errors.unit)}</>}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={gridMultilineFieldStyle}
                            >
                                <TextField
                                    multiline
                                    rows={3}
                                    fullWidth
                                    variant='outlined'
                                    type='text'
                                    size="small"
                                    label={t('label.description')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.description || ''}
                                    name='description'
                                    error={!!touched.description && !!errors.description}
                                    helperText={touched.description && <>{t(errors.description)}</>}
                                />
                            </Grid>
                            {materialDialogState.mode === 'edit' ?
                                <Grid
                                    item
                                    xs={12}
                                //mt='-4px'
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sx={gridCreatedUpdatedStyle}
                                    >
                                        <CreatedUpdatedField
                                            label={t('label.created')}
                                            date={activeMaterial.created}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={gridCreatedUpdatedStyle}
                                    >
                                        <CreatedUpdatedField
                                            label={t('label.updated')}
                                            date={activeMaterial.updated}
                                        />
                                    </Grid>
                                </Grid>
                                :
                                null
                            }
                        </Grid>

                        <SubmitResetButtonGroup
                            onSubmit={handleSubmit}
                            onReset={handleResetClick}
                            labelSubmit={materialDialogState.mode === 'add' ? t('button.submit') : t('button.edit')}
                            labelReset={materialDialogState.mode === 'add' ? t('button.clear') : t('button.reset')}
                            loading={uploadState.status === 'loading'}
                        />

                    </Form>
                )}
            </Formik>

            <ErrorLoadingDataDialog
                open={uploadState.status === 'failed'}
                error={uploadState.error}
                onClose={() => dispatch(closeLoadingErrorDialog())}
            />
        </>
    );
};

export default FormMaterial;