import { useSelector, useDispatch } from 'react-redux';
import {
    createTechnology,
    updateTechnology,
    resetActiveTechnology,
    closeLoadingErrorDialog,
    getActiveTechnology,
    getTechnologyDialogState,
    getTechnologyUploadState,
} from '../../store/slices/technologiesSlice';
import { useTranslation } from 'react-i18next';
import settings from '../../config.json';
import useMediaQuery from '../../hooks/useMediaQuery';
import { Grid, TextField } from '@mui/material';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import SubmitResetButtonGroup from '../../components/buttons/SubmitResetButtonGroup';
import CreatedUpdatedField from '../../components/form/CreatedUpdatedField';
import ErrorLoadingDataDialog from '../../components/dialogs/ErrorLoadingDataDialog';;


const technologySchema = yup.object().shape({
    technology_name: yup
        .string()
        .required('form.error.field_required'),
    technology_number: yup
        .string(),
    description: yup
        .string()
});


function FormTechnology() {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const isAboveMobileSize = useMediaQuery(settings.NON_MOBILE_MEDIA_QUERY);

    const activeTechnology = useSelector(getActiveTechnology);
    const technologyDialogState = useSelector(getTechnologyDialogState);
    const uploadState = useSelector(getTechnologyUploadState);

    const gridTextFieldStyle = { height: '66px', px: '15px' };
    const gridMultilineFieldStyle = { height: '95px', px: '15px' };
    const gridCreatedUpdatedStyle = { px: '15px' };

    /* ----- CREATE / UPDATE -------------------------------------------------------------*/

    const handleFormSubmit = async (values) => {

        const technology = {
            technology_id: activeTechnology.technology_id,
            technology_name: values.technology_name,
            technology_number: values.technology_number,
            description: values.description
        };

        if (technologyDialogState.mode === 'add') {
            dispatch(createTechnology(technology));
        };
        if (technologyDialogState.mode === 'edit') {
            dispatch(updateTechnology(technology));
        };
    };

    /* ----- RESET -----------------------------------------------------------------------*/

    // Pressing "ADD NEXT" button is not clearing inputs - expected behavior
    // Manual Reset added for clearing inputs in 'add' mode
    // 'edit' mode is using 'Formik' builded-in functionality

    const handleResetClick = () => {
        if (technologyDialogState.mode === 'add') {
            dispatch(resetActiveTechnology());
        };
    };

    /* -----------------------------------------------------------------------------------*/


    return (
        <>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={activeTechnology}
                validationSchema={technologySchema}
                enableReinitialize
            >
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                    <Form>

                        <Grid
                            container
                            height={isAboveMobileSize ? 'auto' : '360px'} // set height order: --- 3/3 ---
                            p={isAboveMobileSize ? '6px 0 0 0' : '6px 0 12px 0'}
                            overflow='auto'
                        >
                            <Grid
                                item
                                xs={12}
                                sx={gridTextFieldStyle}
                            >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    type='text'
                                    size="small"
                                    label={t('label.technology_name')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.technology_name || ''}
                                    name='technology_name'
                                    error={!!touched.technology_name && !!errors.technology_name}
                                    helperText={touched.technology_name && <>{t(errors.technology_name)}</>}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={gridTextFieldStyle}
                            >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    type='text'
                                    size="small"
                                    label={t('label.technology_number')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.technology_number || ''}
                                    name='technology_number'
                                    error={!!touched.technology_number && !!errors.technology_number}
                                    helperText={touched.technology_number && <>{t(errors.technology_number)}</>}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={gridMultilineFieldStyle}
                            >
                                <TextField
                                    multiline
                                    rows={3}
                                    fullWidth
                                    variant='outlined'
                                    type='text'
                                    size="small"
                                    label={t('label.description')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.description || ''}
                                    name='description'
                                    error={!!touched.description && !!errors.description}
                                    helperText={touched.description && <>{t(errors.description)}</>}
                                />
                            </Grid>
                            {technologyDialogState.mode === 'edit' ?
                                <Grid
                                    item
                                    xs={12}
                                //mt='-4px'
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sx={gridCreatedUpdatedStyle}
                                    >
                                        <CreatedUpdatedField
                                            label={t('label.created')}
                                            date={activeTechnology.created}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={gridCreatedUpdatedStyle}
                                    >
                                        <CreatedUpdatedField
                                            label={t('label.updated')}
                                            date={activeTechnology.updated}
                                        />
                                    </Grid>
                                </Grid>
                                :
                                null
                            }
                        </Grid>

                        <SubmitResetButtonGroup
                            onSubmit={handleSubmit}
                            onReset={handleResetClick}
                            labelSubmit={technologyDialogState.mode === 'add' ? t('button.submit') : t('button.edit')}
                            labelReset={technologyDialogState.mode === 'add' ? t('button.clear') : t('button.reset')}
                            loading={uploadState.status === 'loading'}
                        />

                    </Form>
                )}
            </Formik>

            <ErrorLoadingDataDialog
                open={uploadState.status === 'failed'}
                error={uploadState.error}
                onClose={() => dispatch(closeLoadingErrorDialog())}
            />
        </>
    );
};

export default FormTechnology;