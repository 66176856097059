import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from '../../theme';


function TooltipContainer(props) {

    const { label, children } = props

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    return (
        <Box
            backgroundColor={colors.primary[500]}
            border='1px solid'
            borderColor={colors.grey[100]}
            borderRadius='4px'
            p='10px'
        >
            <Typography
                variant='subtitle1'
                textAlign='center'
                pb='5px'
                color={colors.grey[100]}
            >
                {label}
            </Typography>
            {children}
        </Box>
    );
};

export default TooltipContainer;