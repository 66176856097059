import { Box, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import settings from '../../config.json'
import useMediaQuery from '../../hooks/useMediaQuery';
import GroupOrders from './GroupOrders';
import GroupSupport from './GroupSupport';
import GroupDB from './GroupDB';


const gridTemplateLargeScreen =
    `
    "o o o o db"
    ". . . . db"
    "s s s s db"
    "s s s s db"
    `

const gridTemplateMobileScreen =
    `
    "o"
    "o"
    "o"
    "o"
    "s"
    "s"
    "s"
    "s"
    "db"
    "db"
    "db"
    "db"
    `


function PageDashboard() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const isAboveMediumScreens = useMediaQuery(settings.ABOVE_MEDIUM_MEDIA_QUERY);

    const areaStyle = {
        //backgroundColor: colors.primary[400],
        border: '1px solid',
        borderColor: colors.primary[500],
        borderRadius: '10px',
        boxShadow: '3px 3px 10px -5px rgba(0, 0, 0, .8)'
    };


    return (
        <Box
            display='grid'
            gap='20px'
            sx={isAboveMediumScreens ? {
                gridTemplateColumns: "repeat(5, minmax(200px, 1fr))",
                gridTemplateRows: "repeat(4, 190px)",
                gridTemplateAreas: gridTemplateLargeScreen,
            } : {
                gridAutoColumns: "1fr",
                gridAutoRows: "minmax(150px, 1fr)",
                gridTemplateAreas: gridTemplateMobileScreen,
            }}
        >
            <Box gridArea='o' sx={areaStyle}>
                <GroupOrders />
            </Box>
            <Box gridArea='s' sx={areaStyle}>
                <GroupSupport />
            </Box>
            <Box gridArea='db' sx={areaStyle}>
                <GroupDB />
            </Box>
        </Box>
    );
};

export default PageDashboard;