import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';


function ButtonNavigation(props) {

    const { label, view, variant, icon, activeView, setActiveView } = props;

    const [buttonColor, setButtonColor] = useState('info');

    useEffect(() => {
        if (view === activeView) {
            setButtonColor('warning');
        }
        else {
            setButtonColor('info');
        }
    }, [view, activeView]);


    return (
        <Button
            variant={variant}
            color={buttonColor}
            sx={{
                height: '100%',
                width: '100%',
                p: '5px 0 0 0'
            }}
            onClick={() => setActiveView(view)}
        >
            <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
            >
                {icon}
                {label}
            </Box>
        </Button>
    );
};

export default ButtonNavigation;