import { useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getTimezone } from '../../../store/slices/loginSlice';
import {
    getProductsScrapRate,
    selectScrapRateData,
    getScrapRateDownloadState
} from "../../../store/slices/productsAnalyticsSlice";
import { useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import { useTranslation } from 'react-i18next';
import ChartHeader from '../../../components/analytics/ChartHeader';
import TooltipContainer from '../../../components/analytics/TooltipContainer';
import TooltipElement from '../../../components/analytics/TooltipElement';
import CircularProgressBasic from '../../../components/progress/CircularProgressBasic';
import NoMatchesNotification from '../../../components/notifications/NoMatchesNotification';
import ChartDataError from '../../../components/notifications/ChartDataError';
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import moment from 'moment-timezone';


function ProductScrapRateChart() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isMounted = useRef(false);
    const { id } = useParams();

    const timezone = useSelector(getTimezone);
    const backendData = useSelector(selectScrapRateData);
    const downloadState = useSelector(getScrapRateDownloadState);

    const chartData = useMemo(() => {
        return (
            /* eslint-disable */
            backendData &&
            backendData.map(({ date, conformance, nonconformance }) => {
                return {
                    date: moment.utc(date).tz(timezone).unix(),
                    //conformance: conformance,
                    nonconformance: nonconformance,
                    scrapRate: parseFloat((nonconformance / (conformance + nonconformance) * 100).toFixed(2))
                }
            })
        )
    }, [backendData])

    const chartDatasets = [
        //  ***ORDER MATTERS*** - list merged to payload when creating a chart tooltip
        // {
        //     label: t('charts.legend.conformance'),
        //     legend_type: 'square',
        //     color: colors.greenAccent[500],
        //     unit: t('charts.unit.pcs'),
        // },
        {
            label: t('charts.legend.nonconformance'),
            legend_type: 'square',
            color: colors.redAccent[500],
            unit: t('charts.unit.pcs'),
        },
        {
            label: t('charts.legend.scrap_rate'),
            legend_type: 'line',
            color: colors.yellowAccent[200],
            unit: t('charts.unit.percent'),
        },
    ]

    /* --------- GET ---------------------------------------------------------------------*/

    useEffect(() => {
        if (isMounted.current) return;
        /* eslint-disable */
        dispatch(getProductsScrapRate(
            {
                product_id: id,
                bucket_size: 'week'
            }
        ));

        isMounted.current = true;
    }, []);

    /* -----------------------------------------------------------------------------------*/

    return (
        <>
            <ChartHeader
                title={t('charts.product_scrap_rate.title')}
                subtitle={t('charts.product_scrap_rate.subtitle')}
            />
            <ResponsiveContainer width="100%" height={300}>
                {downloadState.status === 'loading' ? <CircularProgressBasic /> : null}
                {downloadState.status === 'failed' ? <ChartDataError error={downloadState.error} /> : null}
                {downloadState.status === 'succeeded' && !backendData.length ? <NoMatchesNotification /> : null}
                {downloadState.status === 'succeeded' && backendData.length ?
                    <ComposedChart
                        width={500}
                        height={300}
                        data={chartData.sort((a, b) => a.date - b.date)}
                        margin={{
                            top: 20,
                            right: 5,
                            left: 5,
                            bottom: 0,
                        }}
                    >
                        <defs>
                            {/* dont repeat 'id' - may read from another component (?) */}
                            <linearGradient id="colorConformanceSR" x1="0" y1="0" x2="0" y2="1">
                                <stop
                                    offset="5%"
                                    stopColor={colors.greenAccent[500]}
                                    stopOpacity={0.8}
                                />
                                <stop
                                    offset="95%"
                                    stopColor={colors.greenAccent[500]}
                                    stopOpacity={0}
                                />
                            </linearGradient>
                            {/* dont repeat 'id' - may read from another component (?) */}
                            <linearGradient id="colorNonconformanceSR" x1="0" y1="0" x2="0" y2="1">
                                <stop
                                    offset="5%"
                                    stopColor={colors.redAccent[500]}
                                    stopOpacity={0.8}
                                />
                                <stop
                                    offset="95%"
                                    stopColor={colors.redAccent[500]}
                                    stopOpacity={0}
                                />
                            </linearGradient>
                        </defs>
                        <CartesianGrid
                            stroke={colors.primary[400]}
                            vertical={false}
                        //strokeDasharray="3 3"
                        />
                        <XAxis
                            dataKey="date"
                            tickLine={false}
                            style={{ fontSize: '12px' }}
                            scale="time"
                            // --- below makes time-series chart: ------------------------------
                            type="number"   // default: category
                            domain={['dataMin-86400', 'dataMax+86400']} //  add a day --> 60*60*24
                            tickFormatter={unixTime => `CW ${moment.unix(unixTime).format("W")}`} //    previously formatted to unix
                        />
                        <YAxis
                            yAxisId="left"
                            orientation="left"
                            stroke={colors.grey[200]}
                            axisLine={{ strokeWidth: "0" }}
                            tickLine={false}
                            style={{ fontSize: '12px' }}
                            label={{
                                value: t('charts.label.quantity'),
                                angle: -90,
                                position: 'insideLeft',
                                fontSize: '12px',
                                fill: colors.grey[200],
                                offset: 15,
                                style: { textAnchor: 'middle' }
                            }}
                        />
                        <YAxis
                            yAxisId="right"
                            orientation="right"
                            stroke={colors.grey[200]}
                            axisLine={{ strokeWidth: "0" }}
                            tickLine={false}
                            style={{ fontSize: '12px' }}
                            label={{
                                value: t('charts.label.scrap_rate'),
                                angle: -90,
                                position: 'insideRight',
                                fontSize: '12px',
                                fill: colors.grey[200],
                                offset: 15,
                                style: { textAnchor: 'middle' }
                            }}
                        />
                        <Tooltip
                            content={({ active, payload, label }) => {
                                if (active && payload && payload.length) {
                                    const extendedPayload = payload.map((obj, index) => ({
                                        ...obj,
                                        ...chartDatasets[index]
                                    }));
                                    //console.log(extendedPayload)    // check if merged correctly
                                    return (
                                        <TooltipContainer
                                            label={`CW ${moment.unix(label).format("W")} | ${moment.unix(label).format("DD-MM-YYYY")}`}
                                        >
                                            {/* <TooltipElement
                                                label={t('charts.legend.sum')}
                                                value={payload[0].value + payload[1].value}
                                                unit={t('charts.unit.pcs')}
                                                color={colors.grey[100]}
                                            /> */}
                                            {extendedPayload.map((pld, index) => (
                                                <TooltipElement
                                                    key={index}
                                                    label={pld.label}
                                                    value={pld.value}
                                                    unit={pld.unit}
                                                    color={pld.color}
                                                />
                                            ))}
                                        </TooltipContainer>
                                    );
                                }
                                return null;
                            }}
                        />
                        <Legend
                            payload={chartDatasets.map((set) => (
                                { value: set.label, type: set.legend_type, color: set.color }
                            ))}
                        />
                        {/* <Bar
                            yAxisId="left"
                            dataKey="conformance" stackId="a"
                            fill="url(#colorConformance)"
                            fillOpacity={1}
                            maxBarSize={100}
                        /> */}
                        <Bar
                            yAxisId="left"
                            dataKey="nonconformance"
                            stackId="a"
                            fill="url(#colorNonconformanceSR)"
                            fillOpacity={1}
                            maxBarSize={100}
                        />
                        <Line
                            yAxisId="right"
                            type="monotone"
                            dataKey="scrapRate"
                            label={{ position: 'top', fontSize: '12px', fill: colors.yellowAccent[200] }}
                            stroke={colors.yellowAccent[200]}
                        />
                    </ComposedChart>
                    : null}
            </ResponsiveContainer>
        </>
    );
};

export default ProductScrapRateChart;