import { useSelector, useDispatch } from 'react-redux';
import {
    updatePlaceOrder,
    getActivePlace
} from '../../../store/slices/impersonateSlice';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import { useTranslation } from 'react-i18next';
import {
    isReadyForStep,
    isOrderInProgressHere
} from '../../../utils/orderStepProgress';
import TableDueDate from '../../table/TableDueDate';
import StepsComponent from './StepsComponent';


function BacklogElement(props) {

    const { index, order } = props;

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const activePlace = useSelector(getActivePlace);

    const stepReady = isReadyForStep(activePlace, order);
    const inProgressHere = isOrderInProgressHere(activePlace, order);


    const handleStartOrderClick = () => {

        const data = {
            request_type: "startOrder",
            place_id: activePlace.place_id,
            order_id: order.order_id
        };

        dispatch(updatePlaceOrder(data));
    };

    const styleStandard = {
        borderBottom: '1px solid',
        borderColor: colors.primary[400]
    };
    const styleInProgress = {
        borderBottom: '1px solid',
        borderColor: colors.primary[400],
        backgroundColor: colors.greenAccent[900]
    };


    return (
        <Box
            display='grid'
            gridTemplateColumns='1fr 2fr 2fr 3fr 1fr 2fr 1fr'
            height='44px' // adjust to size of button
            gap='15px'
            p='6px 10px'
            mb='-1px'
            sx={inProgressHere ? styleInProgress : styleStandard}
        >
            <Box display='flex' alignItems='center' minWidth='50px'>
                <Typography variant='subtitle1'>
                    {index + 1}
                </Typography>
            </Box>
            <Box display='flex' alignItems='center' minWidth='50px'>
                <StepsComponent
                    order={order}
                />
            </Box>
            <Box display='flex' alignItems='center' minWidth='50px' sx={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                <Typography variant='subtitle1'>
                    {order.order_number}
                </Typography>
            </Box>
            <Box display='flex' alignItems='center' minWidth='50px' sx={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                <Typography variant='subtitle1'>
                    {order.product.product_name} | {order.product.product_number}
                </Typography>
            </Box>
            <Box display='flex' alignItems='center' minWidth='50px'>
                <Typography variant='subtitle1'>
                    {order.quantity}
                </Typography>
            </Box>
            <Box display='flex' alignItems='center' justifyContent='center' minWidth='50px'>
                <TableDueDate due_date={order.due_date} />
            </Box>
            <Box display='flex' alignItems='center' justifyContent='center' minWidth='85px'>
                {order.status === 'on_hold' ? (
                    <Typography variant='subtitle1' color={colors.primary[300]}>
                        {t('label.on_hold')}
                    </Typography>
                ) : (
                    order.status === 'in_progress' ? (
                        <Typography variant='subtitle1' color={colors.primary[300]}>
                            {t('label.in_progress')}
                        </Typography>
                    ) : (
                        stepReady ? (
                            activePlace.status === 'order' ? (
                                <Typography variant='subtitle1' color={colors.primary[300]}>
                                    {t('label.ready')}
                                </Typography>
                            ) : (
                                <Button
                                    variant='contained'
                                    color='success'
                                    fullWidth
                                    size='small'
                                    onClick={handleStartOrderClick}
                                >
                                    {t('button.device.start')}
                                </Button>
                            )
                        ) : (
                            null
                        )
                    )
                )}
            </Box>
        </Box>
    );
};

export default BacklogElement;