import { useTranslation } from 'react-i18next';
import settings from '../../config.json';
import { Box, Button, Dialog, Divider, Typography, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import useMediaQuery from '../../hooks/useMediaQuery';
import CircularProgressBasic from '../progress/CircularProgressBasic';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';


function ConfirmActionDialog(props) {

    const { open, onConfirm, onCancel, confirmButtonLabel, message, icon, status, error } = props

    const theme = useTheme();
    const colors = tokens(theme.palette.mode)

    const { t } = useTranslation();

    const isAboveMobileSize = useMediaQuery(settings.NON_MOBILE_MEDIA_QUERY);


    return (
        <Dialog
            open={open}
            PaperProps={{
                sx: {
                    backgroundColor: colors.primary[500],
                    width: isAboveMobileSize ? { sm: '500px' } : '90vw',
                    maxWidth: '500px',
                    height: isAboveMobileSize ? '223px' : '223px',
                    m: "0px"
                }
            }}
        >
            <Box
                height="100%"
                display='flex' flexDirection='column'
                justifyContent='center' alignItems='center'
                mt='20px'
            >
                {status === 'loading' ? (
                    <Box
                        height='100%'
                        mt="-20px"
                    >
                        <CircularProgressBasic />
                    </Box>
                ) : (
                    <>
                        <Box
                            width='100%' height='140px'
                            display='flex' alignItems='center'
                        >
                            {/* CONFIRM */}
                            {status === 'idle' ? (
                                <>
                                    <Box width='40%' display='flex' justifyContent='center' mr='-30px'>
                                        {icon}
                                    </Box>
                                    <Box
                                        width='60%'
                                        display='flex'
                                        justifyContent='center' alignItems='center'
                                    >
                                        <Typography variant='h5'>
                                            {message}
                                        </Typography>
                                    </Box>
                                </>
                            ) : (null)}

                            {/* SUCCESS */}
                            {status === 'succeeded' ? (
                                <>
                                    <Box width='100%' display='flex' justifyContent='center' alignItems='center'>
                                        <CheckCircleOutlinedIcon sx={{ color: colors.greenAccent[500], fontSize: '120px' }} />
                                    </Box>
                                </>
                            ) : (null)}

                            {/* ERROR */}
                            {status === 'failed' ? (
                                <>
                                    <Box width='40%' display='flex' justifyContent='center' mr='-30px'>
                                        <ErrorOutlineIcon sx={{ color: colors.redAccent[500], fontSize: '100px' }} />
                                    </Box>
                                    <Box
                                        width='60%'
                                        display='flex' flexDirection='column'
                                        justifyContent='center' alignItems='center'
                                        gap='16px'
                                    >
                                        <Typography variant='h3'>
                                            {t('header.error')}: &nbsp; &nbsp; {error.status} - {error.status_text}
                                        </Typography>
                                        <Typography variant='h5'>
                                            {error.detail}
                                        </Typography>
                                    </Box>
                                </>
                            ) : (null)}

                        </Box>
                        <Box width='100%'>
                            <Divider orientation="horizontal" variant="middle" flexItem />
                            <Box
                                width='100%'
                                display='flex'
                                flexDirection='row'
                                justifyContent={isAboveMobileSize ? 'flex-end' : 'center'}
                                gap='20px'
                                p='12px'
                            >
                                {/* CONFIRM */}
                                {status === 'idle' ? (
                                    <>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            onClick={onConfirm}
                                            sx={{ paddingX: '40px' }}
                                        >
                                            {confirmButtonLabel}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={onCancel}
                                        >
                                            {t('button.cancel')}
                                        </Button>
                                    </>
                                ) : (null)}

                                {/* SUCCESS */}
                                {status === 'succeeded' ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={onCancel}
                                        sx={{ paddingX: '40px' }}
                                    >
                                        {t('button.ok')}
                                    </Button>
                                ) : (null)}

                                {/* ERROR */}
                                {status === 'failed' ? (
                                    <>
                                        <Button
                                            variant="contained"
                                            color="error"
                                            onClick={onConfirm}
                                            sx={{ paddingX: '40px' }}
                                        >
                                            {t('button.try_again')}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={onCancel}
                                        >
                                            {t('button.cancel')}
                                        </Button>
                                    </>
                                ) : (null)}

                            </Box>
                        </Box>
                    </>
                )}
            </Box>
        </Dialog>
    )
}

export default ConfirmActionDialog