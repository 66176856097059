import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import StopOutlinedIcon from '@mui/icons-material/StopOutlined';


function StopChip() {

    const { t } = useTranslation();

    return (
        <Chip
            icon={<StopOutlinedIcon />}
            label={t('chip.stop')}
            variant="outlined"
            color="error"
            size="medium"
            sx={{ width: '100%' }}
        />
    );
};

export default StopChip;