import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    registerAccount,
    setRegisterErrorNotification,
    closeRegisterErrorNotification,
    getRegisterLoadingState
} from '../../store/slices/registerSlice';
import { useTranslation } from 'react-i18next';
import { Alert, Box, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import RegisterVerificationDialog from '../../components/dialogs/RegisterVerificationDialog';
import PasswordVisibility from '../../components/switches/PasswordVisibility';


// yup email check with current version is not enough, therefore:
/* eslint-disable */
const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

const registerSchema = yup.object().shape({
    account_name: yup
        .string()
        .required('form.error.company_required'),
    username: yup
        .string()
        .min(5, 'form.error.min_5')
        .max(24, 'form.error.max_24')
        .required('form.error.username_required'),
    email: yup
        .string()
        .email('form.error.email_invalid_format')
        .matches(emailRegex, 'form.error.email_invalid_format')
        .required('form.error.email_required'),
    password: yup
        .string()
        .min(5, 'form.error.min_5')
        .max(24, 'form.error.max_24')
        .required('form.error.password_required'),
    confirm_password: yup
        .string()
        .min(5, 'form.error.min_5')
        .max(24, 'form.error.max_24')
        .required('form.error.password_required')
});

const emptyValues = {
    account_name: '',
    email: '',
    username: '',
    password: '',
    confirm_password: ''
};


function FormRegister() {

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const loadState = useSelector(getRegisterLoadingState);
    const [showPassword, setShowPassword] = useState({ password: false, cpassword: false });

    /* ------- SUBMIT --------------------------------------------------------------------*/

    const handleFormSubmit = async (values) => {
        if (values.password === values.confirm_password) {
            const formated_values = formatValues(values);
            dispatch(registerAccount(formated_values));
        } else {
            dispatch(setRegisterErrorNotification(t('notification.passwords_not_identical')));
        };
    };

    const formatValues = (values) => {
        const newValues = { ...values };  /* mutate object in order not to erase 'confirm password' field in form */
        delete newValues.confirm_password;
        return newValues;
    };

    /* -----------------------------------------------------------------------------------*/

    return (
        <>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={emptyValues}
                validationSchema={registerSchema}
                enableReinitialize
            >
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                    <Form>
                        <Box
                            display='flex'
                            flexDirection='column'
                            minWidth='300px'
                            gap='16px'
                            my='30px'
                        >
                            <TextField
                                fullWidth
                                variant='outlined'
                                type='text'
                                size="small"
                                label={t('label.company')}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.account_name || ''}
                                name='account_name'
                                error={!!touched.account_name && !!errors.account_name}
                                helperText={touched.account_name && <>{t(errors.account_name)}</>}
                            />
                            <TextField
                                fullWidth
                                variant='outlined'
                                type='text'
                                size="small"
                                label={t('label.username')}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.username || ''}
                                name='username'
                                error={!!touched.username && !!errors.username}
                                helperText={touched.username && <>{t(errors.username)}</>}
                            />
                            <TextField
                                fullWidth
                                variant='outlined'
                                type='text'
                                size="small"
                                label={t('label.email')}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email || ''}
                                name='email'
                                error={!!touched.email && !!errors.email}
                                helperText={touched.email && <>{t(errors.email)}</>}
                            />
                            <TextField
                                fullWidth
                                variant='outlined'
                                type={showPassword.password ? 'text' : 'password'}
                                size="small"
                                label={t('label.password')}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.password || ''}
                                name='password'
                                error={!!touched.password && !!errors.password}
                                helperText={touched.password && <>{t(errors.password)}</>}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <PasswordVisibility
                                                showPassword={showPassword.password}
                                                onClick={() => setShowPassword({ ...showPassword, password: !showPassword.password })}
                                            />
                                        </InputAdornment>
                                }}
                            />
                            <TextField
                                fullWidth
                                variant='outlined'
                                type={showPassword.cpassword ? 'text' : 'password'}
                                size="small"
                                label={t('label.password_confirm')}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.confirm_password || ''}
                                name='confirm_password'
                                error={!!touched.confirm_password && !!errors.confirm_password}
                                helperText={touched.confirm_password && <>{t(errors.confirm_password)}</>}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <PasswordVisibility
                                                showPassword={showPassword.cpassword}
                                                onClick={() => setShowPassword({ ...showPassword, cpassword: !showPassword.cpassword })}
                                            />
                                        </InputAdornment>
                                }}
                            />
                        </Box>
                        {loadState.status === 'failed' ? (
                            <Box mb='20px' mt='-10px'>
                                <Alert
                                    variant="outlined"
                                    severity="error"
                                    onClose={() => dispatch(closeRegisterErrorNotification())}
                                >
                                    {loadState.error.detail}
                                </Alert>
                            </Box>
                        ) : null}
                        <LoadingButton
                            fullWidth
                            type='submit'
                            variant="contained"
                            color='primary'
                            size='large'
                            loading={loadState.status === 'loading'}
                            onClick={handleSubmit}
                        >
                            <span>{t('button.register')}</span>    {/* span tags due to muiLab error */}
                        </LoadingButton>
                    </Form>
                )}
            </Formik>
            <RegisterVerificationDialog />
        </>
    );
};

export default FormRegister;