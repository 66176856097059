import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    updateUser,
    getActiveUser,
    closeLoadingErrorDialog,
    getUserUploadState,
} from '../../store/slices/usersSlice';
import { tokens } from '../../theme';
import { useTranslation } from 'react-i18next';
import settings from '../../config.json';
import useMediaQuery from '../../hooks/useMediaQuery';
import { Box, Grid, Checkbox, FormControlLabel, useTheme } from '@mui/material';
import { Formik, Form } from 'formik';
import usePermission from '../../hooks/usePermission';
import SubmitResetButtonGroup from '../../components/buttons/SubmitResetButtonGroup';
import ErrorLoadingDataDialog from '../../components/dialogs/ErrorLoadingDataDialog';
import DividerLeftLabel from '../../components/dialogs/DividerLeftLabel';


const initialCheckboxState = {
    admin: false,
    archive_read: false,
    archive_write: false,
    orders_read: false,
    orders_write: false,
    places_read: false,
    places_write: false,
    products_read: false,
    products_write: false,
    materials_read: false,
    materials_write: false,
    technology_read: false,
    technology_write: false,
    impersonate_read: false,
    impersonate_write: false,
    users_read: false,
    users_write: false,
};


function PermissionsTab() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const isAboveMobileSize = useMediaQuery(settings.NON_MOBILE_MEDIA_QUERY);
    const adminAccess = usePermission([]);

    const activeUser = useSelector(getActiveUser);
    const uploadState = useSelector(getUserUploadState);

    const [permissionsArray, setPermissionsArray] = useState(activeUser.permissions);
    const [checkboxState, setCheckboxState] = useState(initialCheckboxState);
    const [admin, setAdmin] = useState(undefined);

    const gridCheckBoxStyle = { px: '15px' };


    useEffect(() => {
        /* eslint-disable */
        updateCheckboxes();
        checkIfAdmin();
    }, [activeUser.permissions]);

    /* ------ SUBMIT ---------------------------------------------------------------------*/

    const handleFormSubmit = async (values) => {

        dispatch(updateUser(
            {
                user_id: values.user_id,
                disabled: values.disabled,
                permissions: permissionsArray,
            },
        ));
    };

    /* ------ PERMISSIONS ----------------------------------------------------------------*/

    const handlePermissionsClick = (e) => {

        // Handle Permissions array
        let updated_array = [...permissionsArray]; // copy to make extensible
        const { name, checked } = e.target;
        if (checked) {
            if (!updated_array.includes(String(name))) {
                updated_array.push(String(name));
            }
        } else {
            if (updated_array.includes(String(name))) {
                let index = updated_array.indexOf(String(name));
                updated_array.splice(index, 1);
            };
        };
        setPermissionsArray(updated_array);

        // Handle CheckBox state:    
        Object.keys(checkboxState).forEach(checkbox => {
            if (name === checkbox) {
                if (updated_array.includes(checkbox)) {
                    setCheckboxState({ ...checkboxState, [name]: true });
                } else {
                    setCheckboxState({ ...checkboxState, [name]: false });
                };
            };
        });
    };

    /* ----- RESET -----------------------------------------------------------------------*/

    const handleResetClick = () => {

        setPermissionsArray(activeUser.permissions); // Reset array
        updateCheckboxes(); // Reset Checkboxes
    };

    /* --- CHECKBOXES STATE --------------------------------------------------------------*/

    const updateCheckboxes = () => {

        let original_array = [...activeUser.permissions];
        Object.keys(checkboxState).forEach(checkbox => {
            if (original_array.includes(checkbox)) {
                setCheckboxState(checkboxState => ({ ...checkboxState, [checkbox]: true }));
            } else {
                setCheckboxState(checkboxState => ({ ...checkboxState, [checkbox]: false }));
            };
        });
    };

    /* ------ IS ADMIN -------------------------------------------------------------------*/

    const checkIfAdmin = () => {

        let array = [...activeUser.permissions];
        if (array.includes('admin')) {
            setAdmin(true);
        } else {
            setAdmin(false);
        }
    };

    /* -----------------------------------------------------------------------------------*/

    return (
        <>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={activeUser}
                enableReinitialize
            >
                {({ values, /*errors, touched, handleBlur,*/ handleChange, handleSubmit }) => (
                    <Form>

                        <Box
                            width='100%'
                            sx={{
                                maxHeight: isAboveMobileSize ? '325px' : '425px', // set height order: --- 3/3 ---
                                overflow: 'auto'
                            }}
                        >
                            <DividerLeftLabel label='GLOBAL' />
                            <Grid
                                container
                                p={isAboveMobileSize ? 'auto' : 'auto'}
                                overflow='auto'
                            >
                                <Grid
                                    item
                                    xs={6}
                                    sx={gridCheckBoxStyle}
                                >
                                    <FormControlLabel
                                        control={<Checkbox />}
                                        label={t('label.permissions.admin')}
                                        checked={checkboxState.admin || false}
                                        onChange={handlePermissionsClick}
                                        name='admin'
                                        disabled={!adminAccess}
                                    />
                                </Grid>
                                {admin === false ? (
                                    <Grid
                                        item
                                        xs={6}
                                        sx={gridCheckBoxStyle}
                                    >
                                        <FormControlLabel
                                            control={<Checkbox
                                                color="error"
                                                sx={{ color: colors.redAccent[400] }}
                                            />}
                                            label={t('label.permissions.disabled')}
                                            checked={Boolean(values.disabled) || false}
                                            onChange={handleChange}
                                            name='disabled'
                                            sx={{ color: colors.redAccent[400] }}
                                        />
                                    </Grid>
                                ) : null}
                            </Grid>

                            {admin === false ? (
                                <>
                                    <DividerLeftLabel label='PAGES' />
                                    <Grid
                                        container
                                    >
                                        <Grid
                                            item
                                            xs={6}
                                            sx={gridCheckBoxStyle}
                                        >
                                            <FormControlLabel
                                                control={<Checkbox />}
                                                label={t('label.permissions.orders_read')}
                                                checked={checkboxState.orders_read || false}
                                                onChange={handlePermissionsClick}
                                                name='orders_read'
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={gridCheckBoxStyle}
                                        >
                                            <FormControlLabel
                                                control={<Checkbox />}
                                                label={t('label.permissions.orders_write')}
                                                checked={checkboxState.orders_write || false}
                                                onChange={handlePermissionsClick}
                                                name='orders_write'
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={gridCheckBoxStyle}
                                        >
                                            <FormControlLabel
                                                control={<Checkbox />}
                                                label={t('label.permissions.places_read')}
                                                checked={checkboxState.places_read || false}
                                                onChange={handlePermissionsClick}
                                                name='places_read'
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={gridCheckBoxStyle}
                                        >
                                            <FormControlLabel
                                                control={<Checkbox />}
                                                label={t('label.permissions.places_write')}
                                                checked={checkboxState.places_write || false}
                                                onChange={handlePermissionsClick}
                                                name='places_write'
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={gridCheckBoxStyle}
                                        >
                                            <FormControlLabel
                                                control={<Checkbox />}
                                                label={t('label.permissions.products_read')}
                                                checked={checkboxState.products_read || false}
                                                onChange={handlePermissionsClick}
                                                name='products_read'
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={gridCheckBoxStyle}
                                        >
                                            <FormControlLabel
                                                control={<Checkbox />}
                                                label={t('label.permissions.products_write')}
                                                checked={checkboxState.products_write || false}
                                                onChange={handlePermissionsClick}
                                                name='products_write'
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={gridCheckBoxStyle}
                                        >
                                            <FormControlLabel
                                                control={<Checkbox />}
                                                label={t('label.permissions.materials_read')}
                                                checked={checkboxState.materials_read || false}
                                                onChange={handlePermissionsClick}
                                                name='materials_read'
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={gridCheckBoxStyle}
                                        >
                                            <FormControlLabel
                                                control={<Checkbox />}
                                                label={t('label.permissions.materials_write')}
                                                checked={checkboxState.materials_write || false}
                                                onChange={handlePermissionsClick}
                                                name='materials_write'
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={gridCheckBoxStyle}
                                        >
                                            <FormControlLabel
                                                control={<Checkbox />}
                                                label={t('label.permissions.technology_read')}
                                                checked={checkboxState.technology_read || false}
                                                onChange={handlePermissionsClick}
                                                name='technology_read'
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={gridCheckBoxStyle}
                                        >
                                            <FormControlLabel
                                                control={<Checkbox />}
                                                label={t('label.permissions.technology_write')}
                                                checked={checkboxState.technology_write || false}
                                                onChange={handlePermissionsClick}
                                                name='technology_write'
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={gridCheckBoxStyle}
                                        >
                                            <FormControlLabel
                                                control={<Checkbox />}
                                                label={t('label.permissions.impersonate_read')}
                                                checked={checkboxState.impersonate_read || false}
                                                onChange={handlePermissionsClick}
                                                name='impersonate_read'
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={gridCheckBoxStyle}
                                        >
                                            <FormControlLabel
                                                control={<Checkbox />}
                                                label={t('label.permissions.impersonate_write')}
                                                checked={checkboxState.impersonate_write || false}
                                                onChange={handlePermissionsClick}
                                                name='impersonate_write'
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={gridCheckBoxStyle}
                                        >
                                            <FormControlLabel
                                                control={<Checkbox />}
                                                label={t('label.permissions.users_read')}
                                                checked={checkboxState.users_read || false}
                                                onChange={handlePermissionsClick}
                                                name='users_read'
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={gridCheckBoxStyle}
                                        >
                                            <FormControlLabel
                                                control={<Checkbox />}
                                                label={t('label.permissions.users_write')}
                                                checked={checkboxState.users_write || false}
                                                onChange={handlePermissionsClick}
                                                name='users_write'
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={gridCheckBoxStyle}
                                        >
                                            <FormControlLabel
                                                control={<Checkbox />}
                                                label={t('label.permissions.archive_read')}
                                                checked={checkboxState.archive_read || false}
                                                onChange={handlePermissionsClick}
                                                name='archive_read'
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sx={gridCheckBoxStyle}
                                        >
                                            <FormControlLabel
                                                control={<Checkbox />}
                                                label={t('label.permissions.archive_write')}
                                                checked={checkboxState.archive_write || false}
                                                onChange={handlePermissionsClick}
                                                name='archive_write'
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                            ) : null}

                            <SubmitResetButtonGroup
                                onSubmit={handleSubmit}
                                onReset={handleResetClick}
                                labelSubmit={t('button.edit')}
                                labelReset={t('button.reset')}
                                loading={uploadState.status === 'loading'}
                            />
                        </Box>

                    </Form>
                )}
            </Formik>

            <ErrorLoadingDataDialog
                open={uploadState.status === 'failed'}
                error={uploadState.error}
                onClose={() => dispatch(closeLoadingErrorDialog())}
            />
        </>
    )
}

export default PermissionsTab