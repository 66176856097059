import usePermission from '../../hooks/usePermission';
import { Box, Paper, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import TilePlace from '../device/tiles/TilePlace';
import TileStatus from '../device/tiles/TileStatus';
import TileUser from '../device/tiles/TileUser';
import TileNavigation from '../device/tiles/TileNavigation';
import TileMain from '../device/tiles/TileMain';
import TileActions from './tiles/TileActions';
import TileInfo from '../device/tiles/TileInfo';
import NoPermissionsOverlay from '../../assets/overlays/NoPermissionsOverlay';


const gridTemplate =
    `
    "p p p s s s u u u"
    "n m m m m m m m a"
    "i i i i i i i i i"
    `


function DeviceScreen() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const readAccess = usePermission(['impersonate_read'])

    return (
        <Paper
            elevation={5}
            sx={{
                width: '1024px',
                height: '600px',
                backgroundColor: colors.primary[500],
            }}
        >

            {readAccess === true ? (
                <Box
                    display='grid'
                    height='100%'
                    sx={{
                        gridTemplateColumns: "100px repeat(7, 1fr) 100px",
                        gridTemplateRows: "50px 1fr 50px",
                        gridTemplateAreas: gridTemplate,
                    }}
                    gap='10px'
                    p='10px'
                >
                    <Box gridArea='p'>
                        <TilePlace />
                    </Box>
                    <Box gridArea='s'>
                        <TileStatus />
                    </Box>
                    <Box gridArea='u'>
                        <TileUser />
                    </Box>

                    <Box gridArea='n'>
                        <TileNavigation />
                    </Box>
                    <Box gridArea='m'>
                        <TileMain />
                    </Box>
                    <Box gridArea='a'>
                        <TileActions />
                    </Box>

                    <Box gridArea='i'>
                        <TileInfo />
                    </Box>
                </Box>
            ) : (null)}

            {readAccess === false ? (
                <NoPermissionsOverlay />
            ) : (null)}

        </Paper>
    );
};

export default DeviceScreen;