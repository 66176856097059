export const getCurrentStep = (order) => {
  const stepsArray = order?.steps;
  const index = stepsArray?.findIndex((step) => step.status === "in_progress");
  return index;
};

export const getConformanceParts = (order, step) => {
  let index;
  if (step === undefined) {
    index = getCurrentStep(order); // dynamic index for step during production (device)
  } else {
    index = step;
  }
  const conformance = order?.steps[index].quantities.conformance;
  return conformance;
};

export const getNonconformanceParts = (order, step) => {
  let index;
  if (step === undefined) {
    index = getCurrentStep(order); // dynamic index for step during production (device)
  } else {
    index = step;
  }
  const nonconformance = order?.steps[index].quantities.nonconformance;
  return nonconformance;
};

export const getQuantity = (order) => {
  const quantity = order?.quantity;
  return quantity;
};

export const calculateProgress = (order) => {
  const progress = (
    (getConformanceParts(order) / getQuantity(order)) *
    100
  ).toFixed(0);
  return progress;
};

export const calculateScrapRate = (order, step) => {
  if (!order.steps) {
    return;
  }
  const ok = getConformanceParts(order, step);
  const nok = getNonconformanceParts(order, step);
  const finished = ok + nok;
  const scrapRate = ((nok / finished) * 100).toFixed(2);
  return finished > 0 ? scrapRate : 0;
};
