import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setActiveOrder,
    startEditingOrder,
    startArchivingOrder,
    startDeletingOrder,
    openOrderDetailDialog,
    getOrderDNDState
} from '../../../store/slices/ordersSlice';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { tokens } from '../../../theme';
import usePermission from '../../../hooks/usePermission';
import TableDueDate from '../../../components/table/TableDueDate';
import BarProgressPercentage from '../../../components/progress/BarProgressPercentage';
import MenuStatus from '../MenuStatus';
import TableActionsMenu from '../../../components/table/TableActionsMenu';
import TableActionArchive from '../../../components/table/TableActionArchive';
import TableActionDelete from '../../../components/table/TableActionDelete';
import TableActionDetail from '../../../components/table/TableActionDetails';
import TableActionEdit from '../../../components/table/TableActionEdit';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import { progressPercentage } from '../../../utils/orderStepProgress';


function ListOfOrdersElement(props) {

    const { order, index, dragIcon } = props;

    const dispatch = useDispatch();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const writeAccess = usePermission(['orders_write']);
    const progress = progressPercentage(order)

    const dndUpload = useSelector(getOrderDNDState);

    const [anchorEl, setAnchorEl] = useState(null);


    const handleDetailClick = () => {
        dispatch(setActiveOrder(order));
        dispatch(openOrderDetailDialog());
    };

    const handleMenuClick = (e) => {
        dispatch(setActiveOrder(order));
        setAnchorEl(e.currentTarget);
    };

    const handleArchiveClick = () => {
        dispatch(startArchivingOrder());
        setAnchorEl(null);
    };

    const handleEditClick = () => {
        dispatch(startEditingOrder());
        setAnchorEl(null);
    };

    const handleDeleteClick = () => {
        dispatch(startDeletingOrder());
        setAnchorEl(null);
    };


    return (
        <Box
            minWidth='1130px' // for mobile
            sx={{
                borderTop: '1px solid',
                borderBottom: '1px solid',
                borderColor: colors.assigned.tableGrey,
                "&:hover": { backgroundColor: colors.primary[400] }
            }}
            mt='-1px'
        >
            <Box
                display='flex'
            >
                <Box
                    minWidth='30px'
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                >
                    {dragIcon
                        ? <DragIndicatorOutlinedIcon sx={{ color: colors.assigned.tableGrey }} />
                        : null
                    }
                </Box>
                <Box
                    width='100%'
                    height='48px'
                    display='grid'
                    gridTemplateColumns='1fr 2fr 3fr 1fr 2fr 2fr 150px 140px'
                    gap='20px'
                    px='10px'
                    py='6px'
                >
                    {dndUpload.status === 'loading'
                        && ((dndUpload.index.source <= index && index <= dndUpload.index.destination)
                            || (dndUpload.index.destination <= index && index <= dndUpload.index.source))
                        ? (<CircularProgress color='secondary' size={20} />)
                        : (
                            <>
                                <Box display='flex' alignItems='center' minWidth='30px'>
                                    <Typography variant='subtitle1'>
                                        {index + 1}
                                    </Typography>
                                </Box>
                                <Box display='flex' alignItems='center' minWidth='100px'>
                                    <Typography variant='subtitle1'>
                                        {order.order_number}
                                    </Typography>
                                </Box>
                                <Box display='flex' alignItems='center' minWidth='250px'>
                                    <Typography variant='subtitle1'>
                                        {order.product.product_name} | {order.product.product_number}
                                    </Typography>
                                </Box>
                                <Box display='flex' alignItems='center' minWidth='30px'>
                                    <Typography variant='subtitle1'>
                                        {order.quantity}
                                    </Typography>
                                </Box>
                                <Box display='flex' alignItems='center' justifyContent='center' minWidth='120px'>
                                    <TableDueDate due_date={order.due_date} />
                                </Box>
                                <Box display='flex' alignItems='center' justifyContent='center' minWidth='120px'>
                                    <BarProgressPercentage progress={progress} />
                                </Box>
                                <Box display='flex' alignItems='center' justifyContent='center'>
                                    <MenuStatus order={order} />
                                </Box>
                                <Box display='flex' alignItems='center' justifyContent='center' gap='10px'>
                                    <TableActionDetail onClick={handleDetailClick} />
                                    <TableActionsMenu
                                        onClick={handleMenuClick}
                                        permission={writeAccess}
                                        anchorEl={anchorEl}
                                        setAnchorEl={setAnchorEl}
                                    >
                                        <TableActionEdit onClick={handleEditClick} />
                                        <TableActionArchive onClick={handleArchiveClick} />
                                        <TableActionDelete onClick={handleDeleteClick} />
                                    </TableActionsMenu>
                                </Box>
                            </>
                        )
                    }
                </Box>
            </Box>
            {dndUpload.status === 'loading'
                && ((dndUpload.index.source <= index && index <= dndUpload.index.destination)
                    || (dndUpload.index.destination <= index && index <= dndUpload.index.source))
                ? (<Box height={order.order_comment ? '19px' : null} my='-1px' />)
                : (
                    <Box
                        height={order.order_comment ? '19px' : null}
                        sx={{
                            backgroundColor: colors.assigned.orderComment,
                            color: colors.assigned.textContrast,
                            borderRadius: '10px',
                        }}
                        px='40px'
                        mx='20px'
                        my='-1px'
                    >
                        <Typography variant='subtitle2'>
                            {order.order_comment}
                        </Typography>
                    </Box>
                )
            }
        </Box>
    );
};

export default ListOfOrdersElement;