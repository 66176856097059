import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { tokens } from '../../theme';


function TileOrder(props) {

    const { title, data, icon, loading } = props;

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const color = data.length ? colors.redAccent[500] : colors.primary[400]
    const color2 = colors.primary[400];

    return (
        <Box
            height='100%'
            display='flex'
            flexDirection='column'
            justifyContent='space-between'
            alignItems='center'
            sx={{ background: `linear-gradient(to bottom, ${color}, ${color2}10)` }}
            overflow='hidden'
            p='20px'
        >
            <Box>
                <Box
                    display='flex'
                    alignItems='center'
                    gap='10px'
                >
                    {icon}
                    <Typography
                        variant='subtitle1'
                        align='center'
                    >
                        {title}
                    </Typography>
                </Box>

                <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                >
                    {loading ? (
                        <CircularProgress color='secondary' />
                    ) : (
                        <Typography
                            variant='h6'
                            color={colors.grey[100]}
                            sx={{ fontSize: '60px' }}
                        >
                            {data.length}
                        </Typography>
                    )}
                </Box>
            </Box>

            <Box
                width='100%'
                display='flex'
                flexDirection='column'
                gap='6px'
                overflow='auto'
            >
                {data.map((place, index) =>
                    <Box
                        key={index}
                        //border='1px solid'
                        borderRadius='3px'
                        //borderColor={colors.primary[400]}
                        backgroundColor={colors.redAccent[500]}
                    >
                        <Typography
                            variant='subtitle1'
                            whiteSpace='nowrap'
                            overflow='hidden'
                            px='10px'
                        >
                            {place.place_number} - {place.place_name}
                        </Typography>
                    </Box>
                )}
            </Box>

        </Box>
    );
};

export default TileOrder;