import { useTranslation } from 'react-i18next';
import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from '../../../theme';


function ListOfProductionOrders(props) {

    const { title } = props

    const { t } = useTranslation();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    return (
        <>
            <Typography variant='subtitle1' mx='30px' color={colors.primary[300]}>
                {title}
            </Typography>
            <Box
                minWidth='1130px' // for mobile
                display='flex'
                sx={{ backgroundColor: colors.primary[400], borderRadius: '4px' }}
            >
                <Box minWidth='30px' />
                <Box
                    width='100%'
                    display='grid'
                    gridTemplateColumns='1fr 2fr 3fr 1fr 2fr 2fr 150px 140px'
                    gap='20px'
                    px='10px'
                    py='6px'
                >
                    <Box display='flex' alignItems='center' minWidth='30px'>
                        <Typography variant='subtitle1'>
                            #
                        </Typography>
                    </Box>
                    <Box display='flex' alignItems='center' minWidth='100px'>
                        <Typography variant='subtitle1'>
                            {t('label.order_number')}
                        </Typography>
                    </Box>
                    <Box display='flex' alignItems='center' minWidth='250px'>
                        <Typography variant='subtitle1'>
                            {t('label.product')}
                        </Typography>
                    </Box>
                    <Box display='flex' alignItems='center' minWidth='30px'>
                        <Typography variant='subtitle1'>
                            {t('label.quantity')}
                        </Typography>
                    </Box>
                    <Box display='flex' alignItems='center' justifyContent='center' minWidth='120px'>
                        <Typography variant='subtitle1'>
                            {t('label.due_date')}
                        </Typography>
                    </Box>
                    <Box display='flex' alignItems='center' justifyContent='center' minWidth='120px'>
                        <Typography variant='subtitle1'>
                            {t('label.progress')}
                        </Typography>
                    </Box>
                    <Box display='flex' alignItems='center' justifyContent='center'>
                        <Typography variant='subtitle1'>
                            {t('label.status')}
                        </Typography>
                    </Box>
                    <Box display='flex' alignItems='center' justifyContent='center'>
                        <Typography variant='subtitle1'>
                            {t('label.actions')}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default ListOfProductionOrders;