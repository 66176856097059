import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    updateTechnology,
    getActiveTechnology,
    closeLoadingErrorDialog,
    getTechnologyUploadState
} from '../../store/slices/technologiesSlice';
import {
    fetchAllPlaces,
    selectAllPlaces,
    getPlacesDownloadState
} from '../../store/slices/placesSlice';
import { Autocomplete, Box, Button, CircularProgress, Grid, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import settings from '../../config.json';
import useMediaQuery from '../../hooks/useMediaQuery';
import { Formik, Form, FieldArray, getIn } from 'formik';
import * as yup from 'yup';
import SubmitResetButtonGroup from '../../components/buttons/SubmitResetButtonGroup';
import ErrorLoadingDataDialog from '../../components/dialogs/ErrorLoadingDataDialog';


const technologySchema = yup.object().shape({
    steps: yup.array()
        .of(
            yup.object().shape({
                process: yup
                    .string()
                    .required('form.error.field_required'),
                place: yup
                    .object()
                    .required('form.error.field_required')
            })
        )
});

const emptyStep = {
    process: "",
    place: "",
    status: "backlog" //  'backlog' | 'in_progress' | 'finished'
};


function TechnologyTab() {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const isMounted = useRef(false);

    const isAboveMobileSize = useMediaQuery(settings.NON_MOBILE_MEDIA_QUERY);

    const activeTechnology = useSelector(getActiveTechnology);
    const uploadState = useSelector(getTechnologyUploadState);

    const places = useSelector(selectAllPlaces)
    const placesDownloadState = useSelector(getPlacesDownloadState)

    const gridTextFieldStyle = { px: '4px', py: '4px' };

    /* --------- GET ---------------------------------------------------------------------*/

    useEffect(() => {
        if (isMounted.current) return;
        /* eslint-disable */
        dispatch(fetchAllPlaces());

        isMounted.current = true;
    }, []);

    /* ------ SUBMIT ---------------------------------------------------------------------*/

    const handleFormSubmit = async (values) => {

        const steps = [...values.steps];
        steps.forEach((step, index) => {
            steps[index] = {
                ...step,
                index: index,                       //  add index - used to keep order when querying DB (joining places) - (needed?)
                place: {
                    place_id: step.place.place_id   //  submit only 'place_id' ('place_name' and 'place_number' needed only for Autocomplete component)
                },
            };
        });

        dispatch(updateTechnology(
            {
                technology_id: activeTechnology.technology_id,
                steps: steps,
            },
        ));
    };

    /* -----------------------------------------------------------------------------------*/

    return (
        <>
            <Box
                mx='30px'
                mb='10px'
            >
                {t('label.production_steps')}
            </Box>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={activeTechnology}
                validationSchema={technologySchema}
                enableReinitialize
            >
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                    <Form>
                        <Box
                            width='100%'
                            sx={{
                                maxHeight: isAboveMobileSize ? '320px' : '320px', // set height order: --- 3/3 ---
                                overflow: 'auto'
                            }}
                        >
                            <FieldArray
                                name='steps'
                                render={(arrayHelpers) => (
                                    <>
                                        {values.steps?.map((step, index) => {
                                            return (
                                                <Grid
                                                    key={index}
                                                    container
                                                >
                                                    <Grid
                                                        item
                                                        xs={12} sm={0.5}
                                                        sx={gridTextFieldStyle}
                                                    >
                                                        <Box
                                                            height='100%'
                                                            display='flex'
                                                            alignItems='center'
                                                        >
                                                            {index + 1}.
                                                        </Box>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12} sm={5}
                                                        sx={gridTextFieldStyle}
                                                    >
                                                        <TextField
                                                            fullWidth
                                                            variant='outlined'
                                                            name={`steps[${index}].process`}
                                                            value={step.process || ''}
                                                            size='small'
                                                            label={t('label.process')}
                                                            type='text'
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            error={
                                                                Boolean(getIn(touched, `steps[${index}].process`))
                                                                && Boolean(getIn(errors, `steps[${index}].process`))
                                                            }
                                                            helperText={
                                                                getIn(touched, `steps[${index}].process`)
                                                                && t(getIn(errors, `steps[${index}].process`))
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12} sm={5}
                                                        sx={gridTextFieldStyle}
                                                    >
                                                        <Autocomplete
                                                            options={places}
                                                            onChange={(e, value) => {
                                                                if (value) {
                                                                    handleChange({
                                                                        ...e, target: {
                                                                            ...e.target,
                                                                            name: `steps[${index}].place`,
                                                                            value: {
                                                                                place_id: value.place_id,
                                                                                place_name: value.place_name,
                                                                                place_number: value.place_number
                                                                            },
                                                                        },
                                                                    });
                                                                } else {
                                                                    handleChange({
                                                                        ...e, target: {
                                                                            ...e.target,
                                                                            name: `steps[${index}].place`,
                                                                            value: ""
                                                                        },
                                                                    });
                                                                }
                                                            }}
                                                            value={getIn(values, `steps[${index}].place.place_id`) !== undefined ? getIn(values, `steps[${index}].place`) : null}
                                                            renderInput={(params) =>
                                                                <TextField
                                                                    {...params}
                                                                    fullWidth
                                                                    variant='outlined'
                                                                    type='text'
                                                                    size='small'
                                                                    label={t('label.place')}
                                                                    onBlur={handleBlur}
                                                                    error={
                                                                        Boolean(getIn(touched, `steps[${index}].place`))
                                                                        && Boolean(getIn(errors, `steps[${index}].place`))
                                                                    }
                                                                    helperText={
                                                                        getIn(touched, `steps[${index}].place`)
                                                                        && t(getIn(errors, `steps[${index}].place`))
                                                                    }
                                                                    InputProps={{
                                                                        ...params.InputProps,
                                                                        endAdornment: (
                                                                            <>
                                                                                {placesDownloadState.status === 'loading'
                                                                                    ? <CircularProgress color='secondary' size={20} />
                                                                                    : null}
                                                                                {params.InputProps.endAdornment}
                                                                            </>
                                                                        ),
                                                                    }}
                                                                />
                                                            }
                                                            getOptionLabel={(option) => option.place_number + " --- " + option.place_name}
                                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12} sm={1.5}
                                                        sx={gridTextFieldStyle}
                                                    >
                                                        <Button
                                                            variant='outlined'
                                                            color='error'
                                                            sx={{ width: '100%', height: '100%' }}
                                                            onClick={() => arrayHelpers.remove(index)}
                                                        >
                                                            {t('button.delete')}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            )
                                        })}
                                        <Box
                                            display='flex'
                                            justifyContent='center'
                                        >
                                            <Button
                                                variant='outlined'
                                                color='secondary'
                                                sx={{
                                                    width: 'calc(100% - 8px)',
                                                    border: '1px dashed',
                                                    py: '7px',
                                                    my: '4px',
                                                }}
                                                onClick={() => arrayHelpers.push(emptyStep)}
                                            >
                                                {t('button.add_production_step')}
                                            </Button>
                                        </Box>
                                    </>
                                )}
                            />
                        </Box>

                        <SubmitResetButtonGroup
                            onSubmit={handleSubmit}
                            //onReset={handleResetClick}
                            labelSubmit={t('button.edit')}
                            labelReset={t('button.reset')}
                            loading={uploadState.status === 'loading'}
                        />

                    </Form>
                )}
            </Formik>

            <ErrorLoadingDataDialog
                open={uploadState.status === 'failed'}
                error={uploadState.error}
                onClose={() => dispatch(closeLoadingErrorDialog())}
            />
        </>
    );
};

export default TechnologyTab;