import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";
import { formatNoResponseError } from "../storeUtils";

const initialState = {
  productionOutput: {
    data: [],
    downloading: {
      status: "idle", //  'idle' | 'loading' | 'succeeded' | 'failed'
      error: { status: null, status_text: null, detail: null },
    },
  },
  scrapRate: {
    data: [],
    downloading: {
      status: "idle", //  'idle' | 'loading' | 'succeeded' | 'failed'
      error: { status: null, status_text: null, detail: null },
    },
  },
};

export const getProductsProductionOutput = createAsyncThunk(
  "productAnalytics/productionOutput",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/analytics/products/production-output`,
        data
      );
      return response.data;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const getProductsScrapRate = createAsyncThunk(
  "productAnalytics/scrapRate",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `/analytics/products/scrap-rate`,
        data
      );
      return response.data;
    } catch (err) {
      if (!err.response) {
        /* eslint-disable */
        err = formatNoResponseError(err);
      }
      return rejectWithValue(err);
    }
  }
);

export const productsAnalyticsSlice = createSlice({
  name: "productAnalytics",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /* --- PRODUCTION OUTPUT ---------------------------------- */
      .addCase(getProductsProductionOutput.pending, (state) => {
        state.productionOutput.downloading.status = "loading";
      })
      .addCase(getProductsProductionOutput.fulfilled, (state, action) => {
        state.productionOutput.downloading.status = "succeeded";
        state.productionOutput.data = action.payload;
      })
      .addCase(getProductsProductionOutput.rejected, (state, action) => {
        const d = state.productionOutput.downloading;
        const r = action.payload.response;
        d.status = "failed";
        d.error.status = r.status;
        d.error.status_text = r.statusText;
        d.error.detail = r.data.detail || r.data;
      })
      /* --- SCRAP RATE ----------------------------------------- */
      .addCase(getProductsScrapRate.pending, (state) => {
        state.scrapRate.downloading.status = "loading";
      })
      .addCase(getProductsScrapRate.fulfilled, (state, action) => {
        state.scrapRate.downloading.status = "succeeded";
        state.scrapRate.data = action.payload;
      })
      .addCase(getProductsScrapRate.rejected, (state, action) => {
        const d = state.scrapRate.downloading;
        const r = action.payload.response;
        d.status = "failed";
        d.error.status = r.status;
        d.error.status_text = r.statusText;
        d.error.detail = r.data.detail || r.data;
      });
  },
});

/* --- production output ------------------------------------------------------- */
export const selectProductionOutputData = (state) =>
  state.productsAnalytics.productionOutput.data;
export const getProductionOutputDownloadState = (state) =>
  state.productsAnalytics.productionOutput.downloading;
/* --- scrap rate -------------------------------------------------------------- */
export const selectScrapRateData = (state) =>
  state.productsAnalytics.scrapRate.data;
export const getScrapRateDownloadState = (state) =>
  state.productsAnalytics.scrapRate.downloading;

/* --- REDUCERS --------------------------------------- */
//export const {} = analyticsSlice.actions;

export default productsAnalyticsSlice.reducer;
