import { useTranslation } from 'react-i18next';
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from '../../theme';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';


function EmptyListNotification() {

    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            display='flex' justifyContent='center' alignItems='center'
            gap='20px'
            m='30px'
        >
            <AddCircleRoundedIcon sx={{ color: colors.primary[400], fontSize: '50px' }} />
            <Typography variant='h3' color={colors.primary[400]}>
                {t('notification.empty_list')}
            </Typography>
        </Box>
    );
};

export default EmptyListNotification;