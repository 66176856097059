import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../theme';


function StatusOrder() {

    const { t } = useTranslation();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            width='100%'
            height='100%'
            display='flex'
            justifyContent='center'
            alignItems='center'
        // border='1px solid'
        // borderColor={colors.primary[400]}
        // borderRadius='4px'
        >
            <Typography variant='h3' sx={{ color: colors.greenAccent[500] }}>
                {t('status.order')}
            </Typography>
        </Box>
    );
};

export default StatusOrder;