import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    getAllOrders,
    deleteOrder,
    updateOrder,
    startAddingOrder,
    closeOrderDialog,
    setOrderActiveTab,
    closeOrderDetailDialog,
    closeArchivingDialog,
    closeDeletingDialog,
    getActiveOrder,
    getOrderDialogState,
    getOrderDetailDialogState,
    getOrderArchiveState,
    getOrderDeleteState,
} from '../../store/slices/ordersSlice';
import { useTranslation } from 'react-i18next';
import settings from '../../config.json';
import { tokens } from '../../theme';
import { Box, Dialog, Tab, useTheme } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import useMediaQuery from '../../hooks/useMediaQuery';
import usePermission from '../../hooks/usePermission';
import TableHeader from '../../components/table/TableHeader';
import FormOrder from './FormOrder';
import ConfirmActionDialog from '../../components/dialogs/ConfirmActionDialog';
import DragablePaperComponent from '../../components/dialogs/DragablePaperComponent';
import AddToListButton from '../../components/buttons/AddToListButton';
import TabsDialogContainer from '../../components/dialogs/TabsDialogContainer';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import AddNextButtonTabHeader from '../../components/buttons/AddNextButtonTabHeader';
import SnackbarBasic from '../../components/notifications/SnackbarBasic';
import ListOfProductionOrders from './lists/ListOfProductionOrders';
import ListOfFinishedOrders from './lists/ListOfFinishedOrders';
import OrderDetails from './OrderDetails';


function PageOrders() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const dispatch = useDispatch();
    const isMounted = useRef(false);
    const { t } = useTranslation();

    const isAboveMobileSize = useMediaQuery(settings.NON_MOBILE_MEDIA_QUERY);
    const writeAccess = usePermission(['orders_write'])

    const activeOrder = useSelector(getActiveOrder);
    const orderDialogState = useSelector(getOrderDialogState);
    const orderDetailDialogState = useSelector(getOrderDetailDialogState);
    const archiveState = useSelector(getOrderArchiveState);
    const deleteState = useSelector(getOrderDeleteState);

    /* --------- GET ---------------------------------------------------------------------*/

    useEffect(() => {
        if (isMounted.current) return;
        /* eslint-disable */
        dispatch(getAllOrders());

        isMounted.current = true;
    }, []);

    /* -----------------------------------------------------------------------------------*/


    const changeTab = (e, clickedTab) => {
        dispatch(setOrderActiveTab(clickedTab));
    };

    const handleAddNewClick = () => {
        dispatch(startAddingOrder());
    };

    const handleCloseOrderDialog = () => {
        dispatch(closeOrderDialog());
    };

    const handleCloseOrderDetailDialog = () => {
        dispatch(closeOrderDetailDialog());
    };

    const handleArchive = () => {
        dispatch(updateOrder(
            {
                order_id: activeOrder.order_id,
                status: 'archived'
            },
        ));
    };

    const handleCloseArchivingDialog = () => {
        dispatch(closeArchivingDialog());
    };

    const handleDelete = () => {
        dispatch(deleteOrder(activeOrder));
    };

    const handleCloseDeletingDialog = () => {
        dispatch(closeDeletingDialog());
    };


    return (
        <>

            {/* PAGE */}
            <Box
                height='100%'
                display='flex'
                flexDirection='column'
                sx={{
                    overflowY: 'auto',
                }}
            >
                <TableHeader
                    title={t('page.orders')}
                    icon={<FormatListNumberedOutlinedIcon sx={{ color: colors.assigned.tableHeader, fontSize: '32px' }} />}
                >
                    <AddToListButton
                        text={t('button.add_new_order')}
                        onClick={handleAddNewClick}
                        permission={writeAccess}
                    />
                </TableHeader>
                <ListOfProductionOrders />
                <ListOfFinishedOrders />
            </Box>
            <SnackbarBasic />

            {/* ORDER DIALOG */}
            <Dialog
                open={orderDialogState.isOpen}
                PaperComponent={DragablePaperComponent}
                PaperProps={{
                    sx: {
                        backgroundColor: colors.primary[500],
                        width: isAboveMobileSize ? { sm: settings.MIN_WIDTH_OF_SM_WIDTH_TAB, md: '600px' } : '95vw',
                        height: isAboveMobileSize ? '527px' : '527px', // set height order: --- 2/3 ---
                        m: "0px",
                        overflow: 'hidden'
                    }
                }}
            >
                <Box
                    display='flex'
                    flexDirection='column'
                    mx={isAboveMobileSize ? '20px' : '5px'}
                >
                    <TabContext value={orderDialogState.activeTab} >
                        <TabsDialogContainer
                            onClose={handleCloseOrderDialog}
                        >
                            <Box
                                display='flex'
                                width='100%'
                                justifyContent='space-between'
                                alignItems='center'
                            >
                                <TabList
                                    onChange={changeTab}
                                    textColor='secondary'
                                    indicatorColor='secondary'
                                >
                                    <Tab
                                        label={isAboveMobileSize ? t('tab.general') : null}
                                        icon={<DescriptionOutlinedIcon />}
                                        iconPosition="start"
                                        value="general"
                                        wrapped
                                    />
                                    <Tab
                                        label={isAboveMobileSize ? t('tab.attachments') : null}
                                        icon={<AttachFileIcon />}
                                        iconPosition="start"
                                        value="attachments"
                                        wrapped
                                        disabled={true}//{orderDialogState.mode === 'add'}
                                    />
                                </TabList>
                                <AddNextButtonTabHeader
                                    text={'Add Next'}
                                    onClick={handleAddNewClick}
                                />
                            </Box>
                        </TabsDialogContainer>
                        <Box
                            height={isAboveMobileSize ? '460px' : '460px'} // set height order: --- 1/3 ---
                            position='relative' // absolute position of the button group in tabs
                            sx={{
                                '& .MuiTabPanel-root': {
                                    px: '5px',
                                },
                            }}
                        >
                            <TabPanel value="general">
                                <FormOrder />
                            </TabPanel>
                            <TabPanel value="attachments">
                                {/* <AttachmentsTab /> */}
                            </TabPanel>
                        </Box>
                    </TabContext>
                </Box>
            </Dialog>

            {/* DETAIL DIALOG */}
            <Dialog
                open={orderDetailDialogState.isOpen}
                PaperComponent={DragablePaperComponent}
                PaperProps={{
                    sx: {
                        backgroundColor: colors.primary[500],
                        minWidth: { sm: settings.MIN_WIDTH_OF_SM_WIDTH_TAB, md: '900px' },
                        width: '95vw', // works only for mobile when minWidth is defined
                        height: isAboveMobileSize ? '527px' : '527px', // set height order: --- 2/3 ---
                        m: "0px",
                        overflow: 'hidden'
                    }
                }}
            >
                <Box
                    display='flex'
                    flexDirection='column'
                    mx={isAboveMobileSize ? '20px' : '5px'}
                >
                    <TabContext value={''} >
                        <TabsDialogContainer
                            onClose={handleCloseOrderDetailDialog}
                        >
                            <Box
                                display='flex'
                                width='100%'
                                justifyContent='space-between'
                                alignItems='center'
                            >
                                {/* TABS HERE */}
                            </Box>
                        </TabsDialogContainer>
                        <Box
                            height={isAboveMobileSize ? '460px' : '460px'} // set height order: --- 1/3 ---
                            position='relative' // absolute position of the button group in tabs
                            sx={{
                                '& .MuiTabPanel-root': {
                                    px: '5px',
                                },
                            }}
                        >
                            <OrderDetails
                                order={activeOrder}
                            />
                        </Box>
                    </TabContext>
                </Box>
            </Dialog>

            {/* DELETE DIALOG */}
            <ConfirmActionDialog
                open={deleteState.dialog}
                onConfirm={handleDelete}
                onCancel={handleCloseDeletingDialog}
                confirmButtonLabel={t('button.delete')}
                message={t('notification.confirm_delete')}
                icon={<DeleteForeverOutlinedIcon sx={{ color: colors.redAccent[500], fontSize: '140px' }} />}
                status={deleteState.status}
                error={deleteState.error}
            />

            {/* ARCHIVE DIALOG */}
            <ConfirmActionDialog
                open={archiveState.dialog}
                onConfirm={handleArchive}
                onCancel={handleCloseArchivingDialog}
                confirmButtonLabel={t('button.archive')}
                message={t('notification.confirm_archive')}
                icon={<Inventory2OutlinedIcon sx={{ color: colors.grey[400], fontSize: '140px' }} />}
                status={archiveState.status}
                error={archiveState.error}
            />
        </>
    );
};

export default PageOrders;