import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getPlaceOrders,
    fetchAllPlaces,
    getPlace,
    selectAllPlaces,
    getPlaceTileDownloadState
} from "../../../store/slices/impersonateSlice";

import { Box, Button, Menu, useTheme } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { tokens } from '../../../theme';

function TilePlace() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const dispatch = useDispatch();

    const [anchor, setAnchor] = useState(null);
    const open = Boolean(anchor);

    const places = useSelector(selectAllPlaces);
    const downloadState = useSelector(getPlaceTileDownloadState);

    const storedPlaceId = JSON.parse(window.localStorage.getItem('IMPERSONATE_SELECTED_OPTION'))
    const isElementPresent = places.some((place) => place.place_id === storedPlaceId); // needed when using two accounts in the same browser
    const activePlaceId = isElementPresent ? storedPlaceId : places[0]?.place_id;

    /* --------- GET ---------------------------------------------------------------------*/

    useEffect(() => {
        /* eslint-disable */
        dispatch(fetchAllPlaces());
    }, []);

    useEffect(() => {
        /* eslint-disable */
        if (activePlaceId) {
            dispatch(getPlace(activePlaceId));
            dispatch(getPlaceOrders(activePlaceId));
        }
    }, [activePlaceId]);

    /* -----------------------------------------------------------------------------------*/

    const handlePlaceChange = (e, value) => {
        if (value) {
            setAnchor(null);
            window.localStorage.setItem('IMPERSONATE_SELECTED_OPTION', JSON.stringify(value.place_id));
        };
    };

    /* -----------------------------------------------------------------------------------*/

    return (
        <>
            {places.length ?
                (places.filter(place => place.place_id === activePlaceId).map((place, id) =>
                    <LoadingButton
                        onClick={(e) => setAnchor(e.currentTarget)}
                        key={id}
                        variant='outlined'
                        loading={downloadState.status === 'loading'}
                        sx={{
                            height: '100%',
                            width: '100%',
                            color: `neutral.light`,
                            borderColor: `neutral.light`,
                        }}
                    >
                        {place.place_number + " - " + place.place_name}
                    </LoadingButton>
                )) : (
                    <LoadingButton
                        variant='outlined'
                        loading={true}
                        sx={{
                            height: '100%',
                            width: '100%'
                        }}
                    />
                )
            }

            <Menu
                anchorEl={anchor}
                open={open}
                onClose={() => setAnchor(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                sx={{
                    '& .MuiList-root': {
                        backgroundColor: colors.primary[500],
                        padding: '0px'
                    },
                }}
                slotProps={{
                    paper: {
                        style: {
                            maxHeight: 'calc(10px + (56px + 10px) * 7)',
                            width: '348px',
                        }
                    },
                }}
            >
                <Box display='flex' flexDirection='column' gap='10px' p='10px'>
                    {places.filter(place => place.place_id !== activePlaceId).map((place, id) =>
                        <Button
                            onClick={(e) => handlePlaceChange(e, place)}
                            key={id}
                            variant='outlined'
                            sx={{
                                height: '56px',
                                color: `neutral.light`,
                                borderColor: `neutral.light`,
                            }}
                        >
                            {place.place_number + " - " + place.place_name}
                        </Button>
                    )}
                </Box>
            </Menu>
        </>
    );
};

export default TilePlace;