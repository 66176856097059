import { useSelector, useDispatch } from 'react-redux';
import {
    deleteOrder,
    closeOrderDetailDialog,
    closeDeletingDialog,
    getActiveOrder,
    getOrderDetailDialogState,
    getOrderDeleteState,
} from '../../store/slices/archiveSlice';
import { useTranslation } from 'react-i18next';
import settings from '../../config.json';
import { tokens } from '../../theme';
import { Box, Dialog, useTheme } from '@mui/material';
import { TabContext } from '@mui/lab';
import useMediaQuery from '../../hooks/useMediaQuery';
import TableHeader from '../../components/table/TableHeader';
import ConfirmActionDialog from '../../components/dialogs/ConfirmActionDialog';
import DragablePaperComponent from '../../components/dialogs/DragablePaperComponent';
import TabsDialogContainer from '../../components/dialogs/TabsDialogContainer';
import GridArchive from '../archive/GridArchive';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import OrderDetails from '../orders/OrderDetails';


function PageArchive() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const isAboveMobileSize = useMediaQuery(settings.NON_MOBILE_MEDIA_QUERY);

    const activeOrder = useSelector(getActiveOrder);
    const orderDetailDialogState = useSelector(getOrderDetailDialogState);
    const deleteState = useSelector(getOrderDeleteState);


    const handleCloseOrderDetailDialog = () => {
        dispatch(closeOrderDetailDialog());
    };

    const handleDelete = () => {
        dispatch(deleteOrder(activeOrder));
    };

    const handleCloseDeletingDialog = () => {
        dispatch(closeDeletingDialog());
    };


    return (
        <>

            {/* PAGE */}
            <Box display='flex' flexDirection='column'>
                <TableHeader
                    title={t('page.archive')}
                    icon={<Inventory2OutlinedIcon sx={{ color: colors.assigned.tableHeader, fontSize: '32px' }} />}
                >
                    {/* children here */}
                </TableHeader>
                <GridArchive />
            </Box>

            {/* DETAIL DIALOG */}
            <Dialog
                open={orderDetailDialogState.isOpen}
                PaperComponent={DragablePaperComponent}
                PaperProps={{
                    sx: {
                        backgroundColor: colors.primary[500],
                        minWidth: { sm: settings.MIN_WIDTH_OF_SM_WIDTH_TAB, md: '900px' },
                        width: '95vw', // works only for mobile when minWidth is defined
                        height: isAboveMobileSize ? '527px' : '527px', // set height order: --- 2/3 ---
                        m: "0px",
                        overflow: 'hidden'
                    }
                }}
            >
                <Box
                    display='flex'
                    flexDirection='column'
                    mx={isAboveMobileSize ? '20px' : '5px'}
                >
                    <TabContext value={''} >
                        <TabsDialogContainer
                            onClose={handleCloseOrderDetailDialog}
                        >
                            <Box
                                display='flex'
                                width='100%'
                                justifyContent='space-between'
                                alignItems='center'
                            >
                                {/* TABS HERE */}
                            </Box>
                        </TabsDialogContainer>
                        <Box
                            height={isAboveMobileSize ? '460px' : '460px'} // set height order: --- 1/3 ---
                            position='relative' // absolute position of the button group in tabs
                            sx={{
                                '& .MuiTabPanel-root': {
                                    px: '5px',
                                },
                            }}
                        >
                            <OrderDetails
                                order={activeOrder}
                            />
                        </Box>
                    </TabContext>
                </Box>
            </Dialog>

            {/* DELETE DIALOG */}
            <ConfirmActionDialog
                open={deleteState.dialog}
                onConfirm={handleDelete}
                onCancel={handleCloseDeletingDialog}
                confirmButtonLabel={t('button.delete')}
                message={t('notification.confirm_delete')}
                icon={<DeleteForeverOutlinedIcon sx={{ color: colors.redAccent[500], fontSize: '140px' }} />}
                status={deleteState.status}
                error={deleteState.error}
            />

        </>
    )
};

export default PageArchive;