import { useSelector, useDispatch } from 'react-redux';
import {
    deleteMaterial,
    startAddingMaterial,
    closeMaterialDialog,
    setMaterialActiveTab,
    closeDeletingDialog,
    getActiveMaterial,
    getMaterialDialogState,
    getMaterialDeleteState,
} from '../../store/slices/materialsSlice';
import { useTranslation } from 'react-i18next';
import settings from '../../config.json';
import { tokens } from '../../theme';
import { Box, Dialog, Tab, useTheme } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import useMediaQuery from '../../hooks/useMediaQuery';
import usePermission from '../../hooks/usePermission';
import TableHeader from '../../components/table/TableHeader';
import GridMaterials from './GridMaterials';
import FormMaterial from './FormMaterial';
import ConfirmActionDialog from '../../components/dialogs/ConfirmActionDialog';
import DragablePaperComponent from '../../components/dialogs/DragablePaperComponent';
import AddToListButton from '../../components/buttons/AddToListButton';
import TabsDialogContainer from '../../components/dialogs/TabsDialogContainer';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AddNextButtonTabHeader from '../../components/buttons/AddNextButtonTabHeader';
import SnackbarBasic from '../../components/notifications/SnackbarBasic';


function PageMaterials() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const isAboveMobileSize = useMediaQuery(settings.NON_MOBILE_MEDIA_QUERY);
    const writeAccess = usePermission(['materials_write'])

    const activeMaterial = useSelector(getActiveMaterial);
    const materialDialogState = useSelector(getMaterialDialogState);
    const deleteState = useSelector(getMaterialDeleteState);


    const changeTab = (e, clickedTab) => {
        dispatch(setMaterialActiveTab(clickedTab));
    };

    const handleAddNewClick = () => {
        dispatch(startAddingMaterial());
    };

    const handleCloseMaterialDialog = () => {
        dispatch(closeMaterialDialog());
    };

    const handleDelete = () => {
        dispatch(deleteMaterial(activeMaterial));
    };

    const handleCloseDeletingDialog = () => {
        dispatch(closeDeletingDialog());
    };


    return (
        <>

            {/* PAGE */}
            <Box display='flex' flexDirection='column'>
                <TableHeader
                    title={t('page.materials')}
                    icon={<LibraryAddOutlinedIcon sx={{ color: colors.assigned.tableHeader, fontSize: '32px' }} />}
                >
                    <AddToListButton
                        text={t('button.add_new_material')}
                        onClick={handleAddNewClick}
                        permission={writeAccess}
                    />
                </TableHeader>
                <GridMaterials />
            </Box>
            <SnackbarBasic />

            {/* DIALOG */}
            <Dialog
                open={materialDialogState.isOpen}
                PaperComponent={DragablePaperComponent}
                PaperProps={{
                    sx: {
                        backgroundColor: colors.primary[500],
                        width: isAboveMobileSize ? { sm: settings.MIN_WIDTH_OF_SM_WIDTH_TAB, md: '600px' } : '95vw',
                        height: isAboveMobileSize ? '527px' : '527px', // set height order: --- 2/3 ---
                        m: "0px",
                        overflow: 'hidden'
                    }
                }}
            >
                <Box
                    display='flex'
                    flexDirection='column'
                    mx={isAboveMobileSize ? '20px' : '5px'}
                >
                    <TabContext value={materialDialogState.activeTab} >
                        <TabsDialogContainer
                            onClose={handleCloseMaterialDialog}
                        >
                            <Box
                                display='flex'
                                width='100%'
                                justifyContent='space-between'
                                alignItems='center'
                            >
                                <TabList
                                    onChange={changeTab}
                                    textColor='secondary'
                                    indicatorColor='secondary'
                                >
                                    <Tab
                                        label={isAboveMobileSize ? t('tab.general') : null}
                                        icon={<DescriptionOutlinedIcon />}
                                        iconPosition="start"
                                        value="general"
                                        wrapped
                                    />
                                    <Tab
                                        label={isAboveMobileSize ? t('tab.attachments') : null}
                                        icon={<AttachFileIcon />}
                                        iconPosition="start"
                                        value="attachments"
                                        wrapped
                                        disabled={true}//{materialDialogState.mode === 'add'}
                                    />
                                </TabList>
                                <AddNextButtonTabHeader
                                    text={'Add Next'}
                                    onClick={handleAddNewClick}
                                />
                            </Box>
                        </TabsDialogContainer>
                        <Box
                            height={isAboveMobileSize ? '460px' : '460px'} // set height order: --- 1/3 ---
                            position='relative' // absolute position of the button group in tabs
                            sx={{
                                '& .MuiTabPanel-root': {
                                    px: '5px',
                                },
                            }}
                        >
                            <TabPanel value="general">
                                <FormMaterial />
                            </TabPanel>
                            <TabPanel value="attachments">
                                {/* <AttachmentsTab /> */}
                            </TabPanel>
                        </Box>
                    </TabContext>
                </Box>
            </Dialog>

            {/* DELETE DIALOG */}
            <ConfirmActionDialog
                open={deleteState.dialog}
                onConfirm={handleDelete}
                onCancel={handleCloseDeletingDialog}
                confirmButtonLabel={t('button.delete')}
                message={t('notification.confirm_delete')}
                icon={<DeleteForeverOutlinedIcon sx={{ color: colors.redAccent[500], fontSize: '140px' }} />}
                status={deleteState.status}
                error={deleteState.error}
            />
        </>
    );
};

export default PageMaterials;