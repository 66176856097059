import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
    getPlace,
    updatePlaceOrder,
    updateOrder,
    getActivePlace,
    getPlaceTileDownloadState,
    getOrderUploadState
} from '../../../store/slices/impersonateSlice';
import {
    getCurrentStep,
    getConformanceParts,
    getNonconformanceParts
} from "../../../utils/orderStepAnalytics";
import {
    isReadyToSave
} from "../../../utils/orderPartCounter";
import { Box, Button, useTheme } from '@mui/material'
import { LoadingButton } from '@mui/lab';
import { tokens } from '../../../theme';
import { useTranslation } from 'react-i18next';
import OrderComment from '../order/OrderComment';
import InformationTiles from '../order/InformationTiles';
import Counter from "../order/Counter";
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';


function ViewOrder() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const activePlace = useSelector(getActivePlace)
    const activeOrder = activePlace.order
    const downloadState = useSelector(getPlaceTileDownloadState);
    const downloadStatus = downloadState.status;
    const uploadState = useSelector(getOrderUploadState);

    const [conformancePartsCount, setConformancePartsCount] = useState(getConformanceParts(activeOrder))
    const [nonconformancePartsCount, setNonconformancePartsCount] = useState(getNonconformanceParts(activeOrder))

    const saveReady = isReadyToSave(activeOrder, conformancePartsCount, nonconformancePartsCount)


    /* --------- GET ---------------------------------------------------------------------*/

    useEffect(() => {
        /* eslint-disable */
        if (activePlace) {
            dispatch(getPlace(activePlace.place_id));
        }
    }, []);

    /* -----------------------------------------------------------------------------------*/

    const handleSaveClick = () => {
        dispatch(updateOrder(
            {
                order_id: activeOrder.order_id,
                steps: {
                    index: getCurrentStep(activeOrder),
                    quantities: {
                        conformance: conformancePartsCount,
                        nonconformance: nonconformancePartsCount
                    },
                },
            },
        ));
    };

    const handleResetClick = () => {
        setConformancePartsCount(getConformanceParts(activeOrder));
        setNonconformancePartsCount(getNonconformanceParts(activeOrder));
    };

    const handleQuitOrderClick = () => {

        const data = {
            request_type: "quitOrder",
            place_id: activePlace.place_id,
            order_id: activeOrder.order_id
        };

        dispatch(updatePlaceOrder(data));
    };

    const handleFinishOrderClick = () => {

        const data = {
            request_type: "finishOrder",
            place_id: activePlace.place_id,
            order_id: activeOrder.order_id
        };

        dispatch(updatePlaceOrder(data));
    };


    return (
        <Box
            width='100%'
            height='100%'
            display='flex'
            alignItems='center'
            justifyContent='center'
        // border='1px solid'
        // borderColor={colors.primary[400]}
        // borderRadius='4px'
        >
            {activeOrder ? (
                <Box
                    width='100%'
                    height='100%'
                    display='grid'
                    gridTemplateRows='1fr 2fr 6fr 3fr'
                    gap='10px'
                >
                    < OrderComment
                        text={activeOrder.order_comment}
                    />
                    <InformationTiles
                        order={activeOrder}
                        loading={downloadStatus === 'loading'}
                    />
                    <Box
                        display='grid'
                        gridTemplateColumns='1fr 6fr 1fr'
                        gap='10px'
                    >
                        <Button
                            variant={saveReady ? 'contained' : 'outlined'}
                            color='warning'
                            size='large'
                            disabled={!saveReady || uploadState.status === 'loading'}
                            onClick={handleResetClick}
                        >
                            {t('button.device.reset')}
                        </Button>
                        <Box
                            display='grid'
                            gridTemplateRows='1fr 1fr'
                            gap='10px'
                        >
                            <Counter
                                label={t('label.device.conformance')}
                                value={conformancePartsCount}
                                setValue={setConformancePartsCount}
                                loading={downloadStatus === 'loading'}
                                color={colors.greenAccent[500]}
                            />
                            <Counter
                                label={t('label.device.nonconformance')}
                                value={nonconformancePartsCount}
                                setValue={setNonconformancePartsCount}
                                loading={downloadStatus === 'loading'}
                                color={colors.redAccent[500]}
                            />
                        </Box>
                        <LoadingButton
                            variant={saveReady ? 'contained' : 'outlined'}
                            color='success'
                            size='large'
                            disabled={!saveReady}
                            loading={uploadState.status === 'loading'}
                            onClick={handleSaveClick}
                        >
                            {t('button.device.save')}
                        </LoadingButton>
                    </Box>
                    <Box
                        display='grid'
                        gridTemplateRows='1fr 1fr'
                        gap='10px'
                    >
                        <Button
                            variant='outlined'
                            color='warning'
                            size='large'
                            onClick={handleQuitOrderClick}
                        >
                            {t('button.device.quit')}
                        </Button>
                        <Button
                            variant='outlined'
                            color='error'
                            size='large'
                            onClick={handleFinishOrderClick}
                        >
                            {t('button.device.finish')}
                        </Button>
                    </Box>
                </Box>
            ) : (
                <PauseCircleOutlineOutlinedIcon sx={{ fontSize: '200px', color: colors.primary[400] }} />
            )}

        </Box>
    );
};

export default ViewOrder;