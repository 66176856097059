import { useSelector } from 'react-redux';
import { getActiveView } from '../../../store/slices/impersonateSlice';
import ViewBacklog from '../views/ViewBacklog';
import ViewOrder from '../views/ViewOrder';
import ViewPlace from '../views/ViewPlace';
import ViewTeam from '../views/ViewTeam';

function TileMain() {

    const activeView = useSelector(getActiveView)

    return (
        <>
            {activeView === 'backlog' ? <ViewBacklog /> : null}
            {activeView === 'order' ? <ViewOrder /> : null}
            {activeView === 'place' ? <ViewPlace /> : null}
            {activeView === 'team' ? <ViewTeam /> : null}
        </>
    );
};

export default TileMain;