import { useSelector, useDispatch } from 'react-redux';
import {
    createOrder,
    updateOrder,
    resetActiveOrder,
    closeLoadingErrorDialog,
    getActiveOrder,
    getOrderDialogState,
    getOrderUploadState,
} from '../../store/slices/ordersSlice';
import {
    fetchAllProducts,
    selectAllProducts,
    getProductsDownloadState
} from '../../store/slices/productsSlice';
import { getTimezone } from '../../store/slices/loginSlice';
import { useTranslation } from 'react-i18next';
import settings from '../../config.json';
import useMediaQuery from '../../hooks/useMediaQuery';
import { Autocomplete, CircularProgress, Grid, TextField, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import moment from 'moment-timezone';
import SubmitResetButtonGroup from '../../components/buttons/SubmitResetButtonGroup';
import CreatedUpdatedField from '../../components/form/CreatedUpdatedField';
import ErrorLoadingDataDialog from '../../components/dialogs/ErrorLoadingDataDialog';


const orderSchema = yup.object().shape({
    order_number: yup
        .string()
        .required('form.error.field_required'),
    product: yup
        .object()
        .required('form.error.field_required'),
    order_comment: yup
        .string(),
    quantity: yup
        .number()
        .positive()
        .integer()
        .required('form.error.field_required'),
    due_date: yup
        .date()
});


function FormOrder() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const isAboveMobileSize = useMediaQuery(settings.NON_MOBILE_MEDIA_QUERY);

    const activeOrder = useSelector(getActiveOrder);
    const orderDialogState = useSelector(getOrderDialogState);
    const uploadState = useSelector(getOrderUploadState);

    const products = useSelector(selectAllProducts)
    const productsDownloadState = useSelector(getProductsDownloadState)

    const timezone = useSelector(getTimezone)

    const gridTextFieldStyle = { height: '66px', px: '15px' };
    const gridCreatedUpdatedStyle = { px: '15px' };

    /* --------- GET ---------------------------------------------------------------------*/

    const fetchProducts = () => {
        dispatch(fetchAllProducts());
    }

    /* ----- CREATE / UPDATE -------------------------------------------------------------*/

    const handleFormSubmit = async (values) => {

        const order = {
            order_id: activeOrder.order_id,
            order_number: values.order_number,
            order_comment: values.order_comment,
            product: values.product.product_id,
            quantity: values.quantity,
            due_date: (values.due_date === ""
                ? "1970-01-01T00:00:00"
                : moment(values.due_date).format('YYYY-MM-DD') + 'T00:00:00'),
        };

        if (orderDialogState.mode === 'add') {
            dispatch(createOrder(order));
        };
        if (orderDialogState.mode === 'edit') {
            dispatch(updateOrder(order));
        };
    };

    /* ----- RESET -----------------------------------------------------------------------*/

    // Pressing "ADD NEXT" button is not clearing inputs - expected behavior
    // Manual Reset added for clearing inputs in 'add' mode
    // 'edit' mode is using 'Formik' builded-in functionality

    const handleResetClick = () => {
        if (orderDialogState.mode === 'add') {
            dispatch(resetActiveOrder());
        };
    };

    /* -----------------------------------------------------------------------------------*/


    return (
        <>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={activeOrder}
                validationSchema={orderSchema}
                enableReinitialize
            >
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                    <Form>

                        <Grid
                            container
                            height={isAboveMobileSize ? 'auto' : '360px'} // set height order: --- 3/3 ---
                            p={isAboveMobileSize ? '6px 0 0 0' : '6px 0 12px 0'}
                            overflow='auto'
                        >
                            <Grid
                                item
                                xs={8}
                                sx={gridTextFieldStyle}
                            >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    type='text'
                                    size="small"
                                    label={t('label.order_number')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.order_number || ''}
                                    name='order_number'
                                    error={!!touched.order_number && !!errors.order_number}
                                    helperText={touched.order_number && <>{t(errors.order_number)}</>}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                sx={gridTextFieldStyle}
                            >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    type='text'
                                    size="small"
                                    label={t('label.quantity')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.quantity || ''}
                                    name='quantity'
                                    error={!!touched.quantity && !!errors.quantity}
                                    helperText={touched.quantity && <>{t(errors.quantity)}</>}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={gridTextFieldStyle}
                            >

                                <Autocomplete
                                    onOpen={fetchProducts}
                                    options={products}
                                    onChange={(e, value) => {
                                        if (value) {
                                            handleChange({
                                                ...e, target: {
                                                    ...e.target,
                                                    name: "product",
                                                    value: {
                                                        product_id: value.product_id,
                                                        product_name: value.product_name,
                                                        product_number: value.product_number,
                                                    },
                                                },
                                            });
                                        } else {
                                            handleChange({
                                                ...e, target: {
                                                    ...e.target,
                                                    name: "product",
                                                    value: ""
                                                },
                                            });
                                        }
                                    }}
                                    value={values.product.product_id !== undefined ? values.product : null}
                                    disabled={orderDialogState.mode === 'edit'}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            fullWidth
                                            variant='outlined'
                                            type='text'
                                            size="small"
                                            label={t('label.product')}
                                            name='product'
                                            onBlur={handleBlur}
                                            error={!!touched.product && !!errors.product}
                                            helperText={touched.product && <>{t(errors.product)}</>}
                                            InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <>
                                                        {productsDownloadState.status === 'loading'
                                                            ? <CircularProgress color='secondary' size={20} />
                                                            : null}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                ),
                                            }}
                                        />
                                    }
                                    getOptionLabel={(option) => option.product_name + " | " + option.product_number}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={gridTextFieldStyle}
                            >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    type='text'
                                    size="small"
                                    label={t('label.order_comment')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.order_comment || ''}
                                    name='order_comment'
                                    error={!!touched.order_comment && !!errors.order_comment}
                                    helperText={touched.order_comment && <>{t(errors.order_comment)}</>}
                                    inputProps={{ style: { color: colors.assigned.orderComment } }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={gridTextFieldStyle}
                            >
                                <TextField
                                    fullWidth
                                    variant='outlined'
                                    type='date'
                                    size="small"
                                    label={t('label.due_date')}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={
                                        values.due_date !== "1970-01-01T00:00:00"
                                            ? moment.utc(values.due_date).tz(timezone).format('YYYY-MM-DD')
                                            : '' || ''
                                    }
                                    name='due_date'
                                    error={!!touched.due_date && !!errors.due_date}
                                    helperText={touched.due_date && <>{t(errors.due_date)}</>}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            {orderDialogState.mode === 'edit' ?
                                <Grid
                                    item
                                    xs={12}
                                //mt='-4px'
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sx={gridCreatedUpdatedStyle}
                                    >
                                        <CreatedUpdatedField
                                            label={t('label.created')}
                                            date={activeOrder.created}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={gridCreatedUpdatedStyle}
                                    >
                                        <CreatedUpdatedField
                                            label={t('label.updated')}
                                            date={activeOrder.updated}
                                        />
                                    </Grid>
                                </Grid>
                                :
                                null
                            }
                        </Grid>

                        <SubmitResetButtonGroup
                            onSubmit={handleSubmit}
                            onReset={handleResetClick}
                            labelSubmit={orderDialogState.mode === 'add' ? t('button.submit') : t('button.edit')}
                            labelReset={orderDialogState.mode === 'add' ? t('button.clear') : t('button.reset')}
                            loading={uploadState.status === 'loading'}
                        />

                    </Form>
                )}
            </Formik>

            <ErrorLoadingDataDialog
                open={uploadState.status === 'failed'}
                error={uploadState.error}
                onClose={() => dispatch(closeLoadingErrorDialog())}
            />
        </>
    );
};

export default FormOrder;