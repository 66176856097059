import { Typography, useTheme } from "@mui/material";
import { tokens } from '../../theme';
import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer } from 'recharts';


function BarProgressPercentage(props) {

    const { progress } = props

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const data = [{
        progress: progress
    }]

    return (
        progress !== null ? (
            <>
                <ResponsiveContainer width="100%" height="70%">
                    <BarChart data={data} layout="vertical">
                        <XAxis type="number" domain={[0, 100]} hide={true} />
                        <YAxis type="category" hide={true} />
                        <Bar
                            dataKey="progress"
                            fill={colors.greenAccent[500]}
                            background={{ fill: colors.greenAccent[800], radius: 10 }}
                            radius={10}
                        />
                    </BarChart>
                </ResponsiveContainer>
                <Typography
                    variant="subtitle1"
                    width='40px'
                    display='flex'
                    justifyContent='center'
                >
                    {data[0].progress}%
                </Typography>
            </>
        ) : null
    )
}

export default BarProgressPercentage