import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from '../../theme';
import CloudOffIcon from '@mui/icons-material/CloudOff';


function NoMatchesNotification(props) {

    const { error } = props

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            height='100%'
            width='100%'
            display='flex'
            flexDirection='column'
            overflow='auto'
            justifyContent='center'
            alignItems='center'
        >
            <CloudOffIcon sx={{ color: colors.primary[300], fontSize: '70px' }} />
            <Typography
                variant='h6'
                color={colors.primary[300]}
                px='20px'
            >
                {`Error ${error.status}: ${error.status_text} - ${error.detail}`}
            </Typography>
        </Box>
    );
};

export default NoMatchesNotification;