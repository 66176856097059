import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Divider, IconButton, Popover, Typography, useTheme } from '@mui/material';
import { tokens } from "../../theme";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';


function PopoverRegister() {

    const { t } = useTranslation()
    const theme = useTheme()
    const colors = tokens(theme.palette.mode)

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);


    return (
        <>
            <IconButton
                variant="outlined"
                color="primary"
                size="small"
                onClick={handleClick}
                sx={{ border: '1px solid' }}
            >
                <QuestionMarkIcon />
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 250, left: 1200 }}
                PaperProps={{ sx: { backgroundColor: colors.primary[300] } }}
            >
                <Box width='300px' m='10px'>
                    <Typography paragraph sx={{ m: '6px' }}>{t('text.register_popover_1')}</Typography>
                    <Divider />
                    <Typography sx={{ m: '6px' }}>{t('text.register_popover_2')}</Typography>
                    <Divider />
                    <Typography sx={{ m: '6px' }}>{t('text.register_popover_3')}</Typography>
                </Box>
            </Popover>
        </>
    )
}

export default PopoverRegister