import { Alert } from "@mui/material";

function LoadingError(props) {

    const { error } = props;

    return (
        <Alert
            variant="outlined"
            severity="error"
        >
            {error}
        </Alert>
    );
};

export default LoadingError;