import { useSelector } from 'react-redux'
import { selectAllOrders, getOrdersDownloadState } from '../../../store/slices/ordersSlice';
import { Box } from '@mui/material';
import ListOfOrdersHeader from './ListOfOrdersHeader';
import ListOfOrdersElement from './ListOfOrdersElement';
import CircularProgressBasic from '../../../components/progress/CircularProgressBasic'


function ListOfFinishedOrders() {

    const orders = useSelector(selectAllOrders).filter(order => order.status === "finished");
    const downloadState = useSelector(getOrdersDownloadState);


    return (
        orders.length ? (
            <Box
                pt='50px'
            //overflow='auto'
            >

                {/* HEADER */}
                <ListOfOrdersHeader
                    title={'Finished Orders'}
                />

                {/* LIST */}
                {downloadState.status === 'loading' ? (<CircularProgressBasic />) : null}
                {downloadState.status === 'succeeded' ? (
                    orders?.map((order, index) => (
                        <ListOfOrdersElement
                            key={order.order_id}
                            order={order}
                            index={index}
                            dragIcon={false}
                        />
                    ))
                ) : null}
            </Box>
        ) : (
            null
        )
    );
};

export default ListOfFinishedOrders;